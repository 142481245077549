import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        tenantID: String,
        clientID: String,
        redirectURI: String,
    }

    connect() {
        this.redirectURIValue = window.location.origin + this.redirectURIValue;
    }

    resync() {
        const m0365Link = `https://login.microsoftonline.com/${this.tenantIDValue}/adminConsent?client_id=${this.clientIDValue}&redirect_uri=${this.redirectURIValue}`

        const width = 1280;
        const height = 720;
        const left = (screen.width - width) / 2;
        const top = (screen.height - height) / 2;
        let params = 'width=' + width + ', height=' + height;
        params += ', top=' + top + ', left=' + left;
        params += ', directories=no';
        params += ', location=no';
        params += ', menubar=no';
        params += ', resizable=no';
        params += ', scrollbars=yes';
        params += ', status=no';
        params += ', toolbar=no';

        window.open(m0365Link, 'admin_consent_popup', params);
    }
}
