import { Controller } from "@hotwired/stimulus";

export default class Highlight extends Controller {
	static targets = [];

	connect() {
		this.element[this.identifier] = this;
	}

	initialize() {
		this.highlight();
	}

	// highlight all the words that are
	// contained between the <em> tag.
	highlight() {
		const regex            = /&lt;em&gt;(.*?)&lt;\/em&gt;/g;
		const innerHTML        = this.element.innerHTML;
		this.element.innerHTML = innerHTML.replaceAll(regex, `<mark class='bg-orange-100'>$1</mark>`);
	}
}
