import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = []
    static values = {
        method: String,
        url: String,
    }

    connect() {
        this.element[this.identifier] = this;
    }

    confirm() {
        const el = this;

        fetch(this.urlValue, {
            method: this.methodValue,
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        })
            .then(data => {
                if (data.ok) {
                    el.element.remove();
                }
            });
    }
}