import { Controller } from "@hotwired/stimulus"

const SYMBOL_TRAINING_PROVIDER = "SYMBOL";
const BOTH_TRAINING_PROVIDER = "BOTH";

export default class extends Controller {
    static targets = ["module", "moduleProvider", "provider"]

    /**
     * initialize the controller for the current object
    */
    initialize() {
        this.updateModules();
    }

    /**
     * updateModules the list of training modules based
     * on the training provider selected.
    */
    updateModules() {
        const providerSelected = this.providerTargets.find(provider => provider.checked)?.value;

        this.moduleProviderTargets.forEach(box => {
            if (box.dataset.provider == SYMBOL_TRAINING_PROVIDER) {
                return
            }

            const equal = (providerSelected == box.dataset.provider || providerSelected == BOTH_TRAINING_PROVIDER);
            equal ? box.classList.remove("hidden") : box.classList.add("hidden");
        })
    }

    /**
     * updateSelectedModules updates an input based on
     * the modules selected.
    */
    updateSelectedModules() {
        const moduleSelectionInput = document.getElementsByName("TrainingModule")[0];
        const selectedModules = Array.from(this.moduleTargets.filter(trainingModule => trainingModule.checked)).map(selection => selection.id);

        moduleSelectionInput.value = selectedModules.join(",");
    }
}