import upload from "../external/trix-image_upload.js";
import Trix from "trix";

// Setting custom settings for Trix Editor
Trix.config.attachments.preview.caption     = {name: false, size: false};
Trix.config.blockAttributes.default.tagName = "p";

addEventListener("trix-file-accept", event => {
	// Prevent attaching non-image files
	const acceptedTypes = ["image/jpeg", "image/png"];
	if (!acceptedTypes.includes(event.file.type)) {
		event.preventDefault();
		alert("Only support attachment of jpeg or png files");
	}

	// Prevent attaching files > 1MB
	const maxFileSize = 1024 * 1024; // 1MB
	if (event.file.size > maxFileSize) {
		event.preventDefault();
		alert("Only support attachment files upto size 1MB!");
	}
});

addEventListener("trix-attachment-add", event => {
	if (event.attachment.file) {
		upload.fileAttachment(event.attachment);
	}
});
