import { Controller } from "@hotwired/stimulus";

const SPECIFIC_COMPANY_SELECTION = "SPECIFIC_COMPANY";
const ALL_COMPANY_SELECTION = "ALL_COMPANIES";

export default class extends Controller {
    static targets = [
        "companies", "companySelector", "companyBtn", "companyContainer",
        "fromDate", "untilDate", "submitBtn"
    ]

    initialize() {
        this.element[this.identifier] = this;
    }

    connect() {
        // Updating UI
        this.updateContinueBtn();
        this.updateSubmitBtn();
    }

    /**
     * Appends a list of input tags with the values of the selected companies.
    */
    addCompanies(evt) {
        const choices = evt.target["choices"];
        const companies = choices.instance.getValue(true);

        // Cleaning container
        this.companyContainerTarget.innerHTML = '';

        companies.forEach((company, index) => {
            const input = `<input type="hidden" name="Companies[${index}]" value="${company}">`;
            this.companyContainerTarget.insertAdjacentHTML('beforeend', input);
        })
    }

    /**
     * Update the continue button status when selecting the companies.
    */
    updateContinueBtn() {
        const selection = this.companySelectorTargets.filter(input => input.checked)[0].value;
        const withCompanies = this.companiesTarget.value != "";

        const allSelection = selection == ALL_COMPANY_SELECTION;
        const specificSelection = selection == SPECIFIC_COMPANY_SELECTION && withCompanies

        const hasData = allSelection || specificSelection;
        hasData ? this.companyBtnTarget.disabled = false : this.companyBtnTarget.disabled = true;
    }

    /**
     * Update the submit button status when selecting the date period.
    */
    updateSubmitBtn() {
        const withDates = this.fromDateTarget.value != "" && this.untilDateTarget.value != "";

        withDates ? this.submitBtnTarget.disabled = false : this.submitBtnTarget.disabled = true;
    }
}