import { Controller } from "@hotwired/stimulus";

let eventScores = [];

class Score {
	/**
	 * Create a score object.
	 * @param {string} username - The user first name value.
	 * @param {string} timestamp - The timestamp value.
	 * @param {object} events - The event value.
	 */
	constructor(username, timestamp, events = []) {
		this.timestamp = timestamp;
		this.events    = Array.from(events).map(event => new ScoreEvent(event.datestamp, event.event, event.points, username));
	}
}

class ScoreEvent {
	/**
	 * Create an event object.
	 * @param {timestamp} timestamp - The timestamp value.
	 * @param {string} event - The event value.
	 * @param {number} points - The point value.
	 * @param {string} username - The user first name value.
	 */
	constructor(timestamp, event, points, username) {
		this.timestamp = timestamp;
		this.event     = event;
		this.points    = points;
		this.username  = username;
	}

	/**
	 * the description returns a readable form for the score event.
	 * @return {string}
	 */
	get description() {
		let description = "";
		let noun        = "You";
		let pNoun       = "Your";

		if (this.username !== "") {
			noun  = this.username;
			pNoun = this.username + `'s`;
		}

		switch (this.event) {
			case "campaign_clicked":
				description = `${noun} clicked on a phishing template.`;
				break;
			case "campaign_phished":
				description = `${noun} attempted to share data on a phishing landing page.`;
				break;
			case "reported_email":
				description = `${noun} reported an alert using the Phish Alert Button.`;
				break;
			case "reported_training_email":
				description = `${noun} reported a Symbol Phishing Simulation email using the Phish Alert Button.`;
				break;
			case "email_credential_breached":
				description = `${noun} appeared on a breach report.`;
				break;
			case "new_email_credential_breached":
				description = `${noun} appeared on a new breach report.`;
				break;
			case "user_breach_resolved_overdue":
				description = `${noun} marked a Email THreat Credential finding as remediated after 7 days (Overdue).`;
				break;
			case "user_breach_resolved_on_time":
				description = `${noun} marked a Email THreat Credential finding as remediated within 7 days.`;
				break;
			case "assignment_completed":
				description = `${noun} completed a training course during this month.`;
				break;
			case "assignment_completed_on_time":
				description = `${noun} completed a training course on time.`;
				break;
			case "assignment_completed_on_time_with_no_due_date":
				description = `${noun} completed a training course on time.`;
				break;
			case "assignment_completed_on_overdue":
				description = `One of ${pNoun} assignments went to overdue.`;
				break;
			case "assignment_completed_within_first_2_days":
				description = `${noun} completed a training course within first 2 days of assignment.`;
				break;
			case "assignment_completed_within_first_5_days":
				description = `${noun} completed a training course within first 5 days of assignment.`;
				break;
			case "assignment_reward_100":
				description = `${noun} got 100% score in a training assignment.`;
				break;
			case "assignment_reward_above_80":
				description = `${noun} got more than 80% score in a training assignment.`;
				break;
			case "assignment_reward_below_50":
				description = `${noun} got less than 50% score in a training assignment.`;
				break;
			case "PHISH_ALERT_MALICIOUS_CATEGORY":
				description = `${pNoun} phish alert was categorized as 'Malicious' by the administrator.`;
				break;
			case "PHISH_ALERT_NORMAL_CATEGORY":
				description = `${pNoun} phish alert was categorized as 'Normal' by the administrator.`;
				break;
			case "PHISH_ALERT_REVERT_CATEGORY":
				description = `${pNoun} phish alert was reverted to a different category by the administrator.`;
				break;
			case "FALSE_POSITIVE_CLICK":
				description = "A phish click event was marked as False Positive by the administrator.";
				break;
			case "FALSE_POSITIVE_PHISHED":
				description = "A phish data entered event was marked as False Positive by the administrator.";
				break;
			case "RETURN_FALSE_POSITIVE_CLICK":
				description = "A phish click event was unmarked as False Positive by the administrator.";
				break;
			case "RETURN_FALSE_POSITIVE_PHISHED":
				description = "A phish data entered event was unmarked as False Positive by the administrator.";
				break;
			default:
				break;
		}

		return description;
	}
}

export default class extends Controller {
	static targets = ["loadBtn", "eventList"];

	static values = {
		name: String,
		url: String,
		page: {type: Number, default: 1},
	};

	get scores() {
		return eventScores;
	}

	set scores(scores) {
		eventScores.push(...scores);
	}

	get lastEventTimestamp() {
		if (this.scores.length === 0) {
			return "none";
		}

		return this.scores[this.scores.length - 1].timestamp;
	}

	initialize() {
		this.hasMoreData = true;
		this.load();
	}

	/**
	 * Adds the received events to the current events list,
	 * if the score timestamp already exists on the list, it will append it.
	 * @param {Score[]} scores - The scores retrieved from the lms.
	 */
	add(scores) {
		const firstScoreTimestamp = scores[0].timestamp;
		let scoresToAppend        = scores;

		if (this.lastEventTimestamp === firstScoreTimestamp) {
			const timelineList = this.eventListTarget.querySelector(`[data-timestamp="${firstScoreTimestamp}"] ul`);
			const list         = createList(scores[0], this.nameValue);
			timelineList.append(list);
			scoresToAppend = scores.slice(1);
		}

		scoresToAppend.forEach(score => {
			const box = createBox(score);
			this.eventListTarget.append(box);
		});
	}

	load() {
		if (this.pageValue !== 1 && !this.hasMoreData) {
			return;
		}

		this.loadBtnTarget.disabled    = true;
		this.loadBtnTarget.textContent = "Fetching new events...";

		fetch(`${this.urlValue}?page=${this.pageValue}`)
			.then(response => {
				if (!response.ok) {
					throw new Error("HTTP status " + response.status);
				}

				return response.json();
			})
			.then(data => {
				if (data.length === 0) {
					this.hasMoreData = false;

					// Updating fetch button
					this.loadBtnTarget.classList.add("disabled");
					this.loadBtnTarget.disabled    = true;
					this.loadBtnTarget.textContent = "No more events";

					return;
				}

				this.pageValue++;
				const scores = [];

				data.forEach(d => scores.push(new Score(this.nameValue, d.date, d.events)));
				this.add(scores);

				this.scores                    = scores;
				this.loadBtnTarget.textContent = "Load more";
				this.loadBtnTarget.disabled    = false;
			})
			.catch(error => {
				console.error(error);

				this.loadBtnTarget.classList.add("font-bold", "!text-[#E6294B]");
				this.loadBtnTarget.textContent = "Failed fetching new events";
			});
	}
}

/**
 * Create a new box for the received score.
 * @param {Score} score - The scores retrieved from the lms.
 * @return {HTMLDivElement} The 'div' DOM element with all the data included
 */
function createBox(score) {
	const box             = document.createElement("div");
	box.dataset.timestamp = score.timestamp;

	// Creating & appending Time Header
	const header          = document.createElement("h4");
	const headerTimestamp = new Date(score.timestamp).toLocaleDateString("en-US", {
		year: "numeric",
		month: "short",
		day: "2-digit",
	});

	const isToday = new Date().toLocaleDateString("en-US", {
		year: "numeric",
		month: "short",
		day: "2-digit",
	}) === headerTimestamp;

	header.classList.add("font-normal", "text-base", "mb-4");
	header.textContent = isToday ? "Today" : headerTimestamp;

	const list = createList(score);

	// Appending box items
	box.append(header, list);

	// Appending box to the list
	return box;
}

/**
 * Creates a single li DOM element filled with the score data
 * @param {Score} score - The score retrieved from the lms.
 * @return {HTMLUListElement} The DOM <li> with all the data included
 */
function createList(score) {
	const list = document.createElement("ul");
	list.classList.add("flex", "flex-col", "pl-0");

	score.events.forEach(scoreEvent => {
		const isPenalty = scoreEvent.points > 0;

		const listItem = document.createElement("li");
		listItem.classList.add("tw-card", "flex", "items-center", "bg-white", "rounded", "mb-3", "!px-5", "!py-3", "relative", "list-none");

		const div1 = document.createElement("div")
		const div2 = document.createElement("div")

		// Creating score items
		const pointSpan = document.createElement("span");
		pointSpan.classList.add("text-base", "font-bold", "w-4");
		pointSpan.classList.add(isPenalty ? "text-[#56CE63]" : "text-[#EF5350]");
		pointSpan.textContent = isPenalty ? `+${scoreEvent.points}` : scoreEvent.points;

		const separatorSpan = document.createElement("span");
		separatorSpan.classList.add("w-[1px]", "my-0", "mx-3", "border", "border-gray-200");

		div1.append(pointSpan, separatorSpan)

		const descriptionSpan = document.createElement("p");
		descriptionSpan.classList.add("font-normal", "text-sm", "mb-0");
		descriptionSpan.textContent = scoreEvent.description;

		const timeSpan = document.createElement("span");
		timeSpan.classList.add("font-normal", "text-sm", "ml-auto", "text-left", "w-full", "md:w-48", "whitespace-nowrap", "text-gray-400");
		timeSpan.textContent = new Date(scoreEvent.timestamp).toLocaleString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		});

		div2.append(descriptionSpan, timeSpan)

		div2.classList.add("flex-col", "md:flex", "md:flex-row", "justify-between", "md:items-center", "w-full");

		timeSpan.insertAdjacentHTML("afterbegin", "<i class=\"far fa-clock mr-2\"></i>");

		listItem.append(div1, div2);
		list.append(listItem);
	});

	return list;
}
