import { Controller } from "@hotwired/stimulus";

let timer;


const pdfjsLib                         = require("pdfjs-dist");
pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

class Policy {
	constructor(policy) {
		let courses = new Map();
		policy.Courses.forEach(course => courses.set(course.id, course.name));

		this.id      = policy.id;
		this.content = policy.content;
		this.courses = courses;
		this.source = policy.source;
	}
}

class Policies {
	constructor() {
		this.policies = [];
	}

	/**
	 * Returns the policy from the param.
	 * @param {String} id - The id of the policy template.
	 * @return {Policy} - The policy object.
	 */
	retrievePolicy(id) {
		return this.policies.find(policy => policy.id === id);
	}

	/**
	 * Returns the policy from the param.
	 * @param {JSON} policy - The PolicyTemplate JSON parsed from the DB.
	 * @return {Policy} - The new created Policy object.
	 */
	newPolicy(policy) {
		let p = new Policy(policy);
		this.policies.push(p);
		return p;
	}
}

export default class AssignForm extends Controller {
	static targets = ["policy", "submitBtn", "content", "courses", "user", "banner", "skippedUsers"];
	static values  = {
		trainingUrl: String,
	};

	connect() {
		// Parsing all policies templates from select tag.
		this.policies = new Policies();

		const values = JSON.parse(this.policyTarget.dataset.originalOptions);
		values.forEach(value => this.policies.newPolicy(value));

		// Deleting original options from target.
		delete (this.policyTarget.dataset.originalOptions);

		this.preview();
		this.updateUserSelection();

		const observer = new MutationObserver(() => {
			validateForm(this);
			validateUserAssignment(this);
		});

		observer.observe(document.getElementById("CompanySelectedUsers"), {childList: true});
	}

	/**
	 * listen to the policy selection and updates the preview box
	 * with the actual policy template.
	 * It also updates the box that contains all the courses related
	 * to the policy.
	 */
	preview() {
		if (this.policyTarget.value === "") {
			this.coursesTarget.classList.add("hidden");
			return;
		}

		const p = this.policies.retrievePolicy(this.policyTarget.value);

		// Updating Policy Template Preview and Policy Courses boxes
		if (p.source === "EDITOR") {
			this.contentTarget.innerHTML = p.content;
		}

		if (p.source === "UPLOAD") {
			const path = window.location.pathname.replace("assignments/new/", `${p.id}/signed_url/`)

			fetch(path)
			.then(response => response.text())
			.then(url => {
				this.loadPDF(url);
			})
			.catch(error => console.log(error))
		}

		p.courses.size === 0 ? this.coursesTarget.classList.add("hidden") : this.coursesTarget.classList.remove("hidden");
		this.coursesTarget.querySelector("ul").innerHTML = "";
		this.coursesTarget.querySelector("ul").append(...buildTrainingBoxFor(p.courses, this.trainingUrlValue));

		validateForm(this);
		validateUserAssignment(this);
	}

	loadPDF(url) {
		const loadingTask = pdfjsLib.getDocument(url);

		loadingTask.promise.then((pdf) => {
			pdf.getPage(1).then((page) => {
				const viewport = page.getViewport({scale: 1});

				const canvas = document.createElement("canvas");
				this.contentTarget.innerHTML = "";
				this.contentTarget.append(canvas);

				const context = canvas.getContext("2d");
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				canvas.style.width = "100%";
				canvas.style.height = "100%";

				const renderContext = {
					canvasContext: context,
					viewport: viewport
				};

				page.render(renderContext);
			});
		})
		.catch(error => console.log(error));
	}

	/**
	 * listens to the target selection and updates the UI based
	 * on the selection.
	 */
	updateUserSelection() {
		const select     = this.userTargets.filter(input => input.checked)[0];
		const userList   = document.getElementById("UsersList");
		const userTable  = document.getElementById("userList");
		const userBanner = document.getElementById("AllUsersSelected");

		if (select.value === "ALL") {
			userList.classList.add("hidden");
			userTable.classList.add("hidden");
			userBanner.classList.remove("hidden");
		}

		if (select.value === "CUSTOM") {
			userList.classList.remove("hidden");
			userTable.classList.remove("hidden");
			userBanner.classList.add("hidden");
		}

		validateForm(this);
		validateUserAssignment(this);
	}
}

/**
 * Validates the form and prevents the "submit" button to be pressed
 * if the form is not valid.
 * @param {Object} controller - The form controller.
 * @return {boolean} form validation.
 */
function validateForm(controller) {
	const select = controller.userTargets.filter(input => input.checked)[0].value;
	const policy = controller.policyTarget.value;
	const users  = parseInt(document.getElementById("CompanySelectedUsers").innerText);
	const validPolicy        = policy !== "";
	const validUserSelection = select === "ALL" || users > 0;
	const validForm          = validPolicy && validUserSelection;

	controller.submitBtnTarget.disabled = !validForm;

	return validForm;
}

/**
 * Validates the user and policy selection in order to show and alert for possible duplications.
 */
function validateUserAssignment(controller) {
	controller.bannerTarget.classList.add("hidden");
	const validForm = validateForm(controller);
	if (!validForm) {
		return;
	}

	const tableUsers = document.getElementById("userList").querySelectorAll("td span");

	timer = setTimeout(() => {
		const select   = controller.userTargets.filter(input => input.checked)[0].value;
		const users    = document.getElementById("userList").querySelectorAll("input");
		const formData = new URLSearchParams();
		const token    = document.querySelector("meta[name=csrf-token]").attributes.content.textContent;
		const param    = document.querySelector("meta[name=csrf-param]").attributes.content.textContent;

		formData.append(param, token);
		if (select === "CUSTOM") {
			users.forEach(user => {
				if (user.value != "") {
					formData.append("users", user.value);
				}
			});
		}

		fetch(`${window.location.pathname}${controller.policyTarget.value}/validate/`,
			{
				method: "POST",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				referrerPolicy: "no-referrer",
				body: formData,
			})
			.then(resp => {
				if (!resp.ok) {
					throw resp;
				}

				return resp.json();
			})
			.then(data => {
				tableUsers.forEach(selectedUser => selectedUser.parentElement.classList.remove("line-through"));

				if (data === null) {
					return;
				}

				// Updating banner
				controller.skippedUsersTarget.innerText = data.length;
				controller.bannerTarget.classList.remove("hidden");

				tableUsers.forEach(selectedUser => data.forEach(skipped => {
						if (`(${skipped.email})` === selectedUser.innerText) {
							selectedUser.parentElement.classList.add("line-through");
						}
					},
				));
			})
			.catch(function (res) {
				console.error(res);
			});
	}, 100);
}

function buildTrainingBoxFor(courses, url) {
	const list = [];

	courses.forEach((name, id) => {
		const li = document.createElement("li");
		li.classList.add("flex", "flex-row", "items-center", "space-x-3", "!py-3");

		const icon = document.createElement("i");
		icon.classList.add("far", "fa-play-circle");

		const link = document.createElement("a");
		link.classList.add("font-normal");
		link.href = url + id + "/";
		link.setAttribute("target", "_blank");
		link.textContent = name;

		const input = document.createElement("input");
		input.name  = "Courses";
		input.value = id;
		input.setAttribute("type", "hidden");

		li.append(icon, link, input);
		list.push(li);
	});

	return list;
}
