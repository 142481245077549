import { Controller } from "@hotwired/stimulus";
import reorderIndexFor from "../../reorder_input_index";

const DEFAULT_PLACEHOLDER_VALUE = "Add your value here";
const DOMAIN_PLACEHOLDER_VALUE  = "Add your domain without https:// or www.";

export default class KeywordsForm extends Controller {
	static targets = ["addRowBtn", "keyword", "list", "note", "parameter", "submitBtn", "template"];
	static values  = {
		index: Number,
		keywords: String,
		maxKeywords: Number,
		maxVipEmails: Number,
	};

	connect() {
		this.element[this.identifier] = this;
	}

	initialize() {
		if (this.listTarget.childElementCount === 0) {
			this.addKeywordRow();
		}

		this.toggleUI();
		this.availableKeywords    = getAvailableKeywords(this.keywordsValue, this.maxVipEmailsValue);
		this.availableVipEmails   = getAvailableVIPEmails(this.keywordsValue, this.maxVipEmailsValue);
		this.noteTarget.innerHTML = noteSummary(this.availableKeywords, this.maxKeywordsValue, this.availableVipEmails, this.maxVipEmailsValue);
	}

	/*
	 * adds a new keyword row to the form.
	 */
	addKeywordRow() {
		const tmpl = this.templateTarget.content.cloneNode(true);
		this.listTarget.append(tmpl);
		this.indexValue++;
	}

	/**
	 * changes the inputs attributes to match the required validation depending on the type chose.
	 * @param evt {Event} - The event received by the function.
	 */
	changeInput(evt) {
		const row   = evt.target.closest("div[id^=row-]");
		const input = this.keywordTargets[row.dataset.index];
		input.value = ""; // Resetting input value

		switch (evt.target.value) {
			case "domain":
				restoreInput(input);
				input.placeholder = DOMAIN_PLACEHOLDER_VALUE;
				break;
			case "email":
				restoreInput(input);
				input.setAttribute("type", "email");
				break;
			case "ipAddress":
				input.setAttribute("type", "text");
				input.setAttribute("minlength", "7");
				input.setAttribute("maxlength", "15");
				input.setAttribute("size", "15");
				input.setAttribute("placeholder", DEFAULT_PLACEHOLDER_VALUE);
				input.setAttribute("pattern", "^(?!0)(?!.*\\.$)((1?\\d?\\d|25[0-5]|2[0-4]\\d)(\\.|$)){4}$");
				break;
			default:
				restoreInput(input);
				break;
		}
	}

	/**
	 * Respond to any change to the data-index-value attribute and changes the
	 * list UI based on the number of rows.
	 * It also validates the number of allowed keywords and toggles the buttons state.
	 */
	indexValueChanged(value, previousValue) {
		if (previousValue === undefined) {
			return;
		}

		reorderIndexFor(this.listTarget);
		this.toggleUI();
	}

	/**
	 * removes a keyword row to the form.
	 * @param event {Event} the event received.
	 */
	removeKeywordRow(event) {
		const row = event.target.parentElement;
		row.remove();

		this.indexValue--;
	}

	toggleUI() {
		// Validate Inputs
		const valid = this.validate();
		if (valid) {
			this.submitBtnTarget.removeAttribute("disabled");
			this.addRowBtnTarget.removeAttribute("disabled");
		} else {
			this.submitBtnTarget.setAttribute("disabled", "");
			this.addRowBtnTarget.setAttribute("disabled", "");
		}

		// Checks number of rows and toggles visibility for remove row button and labels.
		const count = this.listTarget.childElementCount;
		if (count > 1) {
			this.listTarget.classList.add("has-multiple");
		} else {
			this.listTarget.classList.remove("has-multiple");
		}
	}

	/**
	 * validates that form inputs are not empty.
	 * @return {Boolean} if the form is able to be submited or for adding new keyword.
	 */
	validate() {
		// Checking all values
		const keywords = [];
		Array.from(this.listTarget.children).forEach(item => {
			const select = item.querySelector("[data-cyberthreats--keywords-form-target=\"parameter\"]");
			const input  = item.querySelector("[data-cyberthreats--keywords-form-target=\"keyword\"]");
			keywords.push(select.value + ":" + input.value);
		});

		const areNotEmpty      = this.keywordTargets.every(keyword => keyword.value !== "");
		const areNotDuplicated = checkIfDuplicateExists(keywords);

		return areNotEmpty && areNotDuplicated;
	}
}

/**
 * sets the correct index for each of the inputs.
 * @param {Number} availableKeywords - The keywords currently used.
 * @param {Number} maxKeywords - The total of keywords available on the plan.
 * @param {Number} availableMaxVIP - The VIP Emails keywords currently used.
 * @param {Number} maxVIP - The total of VIP Emails available on the plan.
 * @return {String} the summary text along with the number of available keywords or VIP Emails.
 */
function noteSummary(availableKeywords, maxKeywords, availableMaxVIP, maxVIP) {
	if (maxVIP === 0) {
		return `Your company has added <strong class='font-strong'>${availableKeywords}/${maxKeywords}</strong> Keywords.`;
	}

	return `Your company has added <strong class='font-strong'>${availableKeywords}/${maxKeywords}</strong> Keywords and
	<strong class='font-strong'>${availableMaxVIP}/${maxVIP}</strong> VIP emails.`;
}

/**
 * returns the number of base keywords that can be added.
 * @param {String} keywords - The list of keywords currently used.
 * @param {Number} maxVipAvailable - The maximum number of VIP Email available from the plan.
 * @returns {Number} - The number of available base keywords.
 */
function getAvailableKeywords(keywords, maxVipAvailable) {
	if (keywords === "") {
		return 0;
	}

	const k = keywords.split(",");
	if (maxVipAvailable === 0) {
		return k.length;
	}

	const base = k.filter(keyword => !keyword.includes("email:"));

	return base.length;
}

/**
 * returns the number of VIP Emails that can be added.
 * @param {String} keywords - The list of keywords currently used.
 * @param {Number} maxVipAvailable - The maximum number of VIP Email available from the plan.
 * @returns {Number} - The number of available VIP Emails.
 */
function getAvailableVIPEmails(keywords, maxVipAvailable) {
	if (maxVipAvailable === 0) {
		return maxVipAvailable;
	}

	const k    = keywords.split(",");
	const base = k.filter(keyword => keyword.includes("email:"));

	return base.length;
}

/**
 * returns the input to its default form.
 * @param {HTMLElement} input - the input where the value is set.
 */
function restoreInput(input) {
	input.removeAttribute("minlength");
	input.removeAttribute("maxlength");
	input.removeAttribute("size");
	input.removeAttribute("pattern");
	input.setAttribute("type", "text");
	input.setAttribute("placeholder", DEFAULT_PLACEHOLDER_VALUE);
}

function checkIfDuplicateExists(arr) {
	return new Set(arr).size === arr.length;
}
