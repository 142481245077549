import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr/dist/flatpickr";


export default class FlatpickrController extends Controller {
    static values = {
        minDate: String,
        maxDate: String,
    }

    initialize() {
        this.element[this.identifier] = this;
    }

    connect() {
        this.instance = flatpickr(this.element, {
            minDate: this.minDate,
            maxDate: this.maxDate,
            enableTime: false,
            dateFormat: "m/d/Y",
        })
    }

    /**
     * Returns the max date value
    */
    get maxDate() {
        let max = this.maxDateValue;
        if (max == "now()") {
            max = new Date()
        }

        return max
    }

    /**
     * Returns the min date value
    */
    get minDate() {
        let min = this.minDateValue;
        if (min == "now()") {
            min = new Date()
        }

        return min
    }
}