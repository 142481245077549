import {Controller} from "@hotwired/stimulus";
import Choices from "choices.js";
import reorderIndexFor from "../reorder_input_index";

const ALL_COMPANIES_SELECTED_BOX_ELEMENT     = document.getElementById("all-companies-selected-box");
const CUSTOM_COMPANIES_SELECTION_BOX_ELEMENT = document.getElementById("custom-companies-box");
const GROUPS_BOX_ELEMENT                     = document.getElementById("groups-companies-box");
const ALL_TARGET                             = "ALL";
const COMPANIES_TARGET                       = "CUSTOM";
const GROUPS_TARGET                          = "GROUPS";

let companyChoicesValues = [];
let groupChoicesValues   = [];

export default class TargetCompaniesController extends Controller {
    static targets = [
        "selector", "companies", "companiesButton", "groups", "allCompanies", "allUsers", "companiesInputs",
        "groupsButton", "companyTable", "groupTable", "companiesUsers", "groupsUsers", "groupsInputs",
    ];

    static values = {
        selected: {type: Array, default: []},
        target: String,
    };

    /*
     Returns the radio button selected.
     */
    get selectedTarget() {
        return this.selectorTargets.find(target => target.checked).value;
    }

    /*
     Changes the radio button selected.
     */
    set selectedTarget(value) {
        const rb   = this.selectorTargets.find(target => target.value === value);
        rb.checked = true;
    }

    /*
     Returns the total of companies from all the available selection.
     */
    get allCompanies() {
        return this.companiesChoices._currentState.choices.length;
    }

    /*
     Returns the total of users from all the companies available selection.
     */
    get allUsers() {
        return this.companiesChoices._currentState.choices.reduce((accumulator, currentValue) => accumulator + currentValue.customProperties.userscount, 0);
    }

    /*
     Returns the total of users from the company selection target.
     */
    get companiesUsers() {
        const active = companyChoicesValues.filter(choice => choice.selected);
        return active.reduce((accumulator, currentValue) => accumulator + currentValue.customProperties.userscount, 0);
    }

    /*
     Returns the total of users from the group selection target.
     */
    get groupsUsers() {
        const active = groupChoicesValues.filter(choice => choice.selected);
        return active.reduce((accumulator, currentValue) => accumulator + currentValue.customProperties.userscount, 0);
    }

    /*
     Returns the number of companies the admin can select.
     */
    get companiesLeft() {
        const active = companyChoicesValues.filter(choice => choice.selected).length;
        return 50 - active;
    }

    /*
     Returns the number of groups the admin can select.
     */
    get groupsLeft() {
        const active = groupChoicesValues.filter(choice => choice.selected).length;
        return 50 - active;
    }

    /*
     Returns the total companies count based on the target selection.
     */
    get totalCompaniesCount() {
        let count = 0;

        if (this.selectedTarget === ALL_TARGET) {
            count = this.allCompanies;
        }

        if (this.selectedTarget === COMPANIES_TARGET) {
            const active = this.companiesChoices._currentState.choices.filter(choice => choice.selected);
            count        = active.length;
        }

        if (this.selectedTarget === GROUPS_TARGET) {
            const active = this.groupsChoices._currentState.choices.filter(choice => choice.selected);
            count        = active.reduce((accumulator, currentValue) => accumulator + currentValue.customProperties.companiescount, 0);
        }

        return count;
    }

    /*
     Returns the total users count based on the target selection.
     */
    get totalUsersCount() {
        let count = 0;

        if (this.selectedTarget === ALL_TARGET) {
            count = this.allUsers;
        }

        if (this.selectedTarget === COMPANIES_TARGET) {
            count = this.companiesUsers;
        }

        if (this.selectedTarget === GROUPS_TARGET) {
            count = this.groupsUsers;
        }

        return count;
    }

    connect() {
        this.element[this.identifier] = this;
        this.customEvent              = new CustomEvent("targetcompanies.change", {
            detail: {},
            bubbles: false,
            cancelable: true,
            composed: false,
        });

        this.updateUI();
    }

    initialize() {
        this.selectedTarget = this.targetValue;

        // Initializing 'choices' targets
        this.companiesChoices = initializeCompanyChoices(this.companiesTarget, this.selectedValue);
        this.groupsChoices    = initializeGroupsChoices(this.groupsTarget, this.selectedValue);

        // Initializing 'choices' events
        this.companiesTarget.addEventListener("change", evt => {
                evt.target.length > 0 ? this.companiesButtonTarget.disabled = false : this.companiesButtonTarget.disabled = true;
            }, false,
        );

        this.groupsTarget.addEventListener("change", evt => {
                evt.target.length > 0 ? this.groupsButtonTarget.disabled = false : this.groupsButtonTarget.disabled = true;
            }, false,
        );

        // Capturing Datatable events
        Promise.resolve().then(() => {
            this.companiesController = this.companyTableTarget["datatable"];
            this.groupsController    = this.groupTableTarget["datatable"];

            this.companiesController.instance.on("datatable.update", () => {
                toggleDatatable(this.companiesController.rows, this.companiesController.element);
                updateUsersCount(this.companiesUsers, this.companiesUsersTarget);
            });

            this.groupsController.instance.on("datatable.update", () => {
                toggleDatatable(this.groupsController.rows, this.groupsController.element);
                updateUsersCount(this.groupsUsers, this.groupsUsersTarget);
            });

            // Adds selection to table if any.
            this.add();
        });

        // Initialize All Companies/Users count
        this.allCompaniesTarget.innerText = this.allCompanies;
        this.allUsersTarget.innerText     = this.allUsers;
    }

    /*
     Adds the selected items to the correspondent table.
     */
    add() {
        const selectionTarget = this.selectedTarget;

        if (selectionTarget === COMPANIES_TARGET) {
            const values = this.companiesChoices.getValue();
            insertInputs(values, "Companies", this.companiesInputsTarget);
            addCompanies(values, this.companiesController);
            this.companiesButtonTarget.disabled = true;

            // Updating company choices
            companyChoicesValues                      = this.companiesChoices._currentState.choices;
            this.companiesChoices.config.maxItemCount = this.companiesLeft;

            // Validating if admin can select more companies
            if (this.companiesLeft <= 0) {
                this.companiesChoices.clearChoices();
            }

            Promise.resolve().then(() => {
                this.companiesChoices.unhighlightAll();
            });
        }

        if (selectionTarget === GROUPS_TARGET) {
            const values = this.groupsChoices.getValue();
            insertInputs(values, "Groups", this.groupsInputsTarget);
            addGroups(values, this.groupsController);
            this.groupsButtonTarget.disabled = true;

            // Updating company choices
            groupChoicesValues                     = this.groupsChoices._currentState.choices;
            this.groupsChoices.config.maxItemCount = this.groupsLeft;

            // Validating if admin can select more groups
            if (this.groupsLeft <= 0) {
                this.groupsChoices.clearChoices();
            }

            Promise.resolve().then(() => {
                this.groupsChoices.unhighlightAll();
            });
        }

        this.element.dispatchEvent(this.customEvent);
    }

    /*
     Remove from the choice instance the current selection.
     */
    deselect({target}) {
        const selectionTarget = this.selectedTarget;

        if (selectionTarget === COMPANIES_TARGET) {
            this.companiesChoices.setChoices(companyChoicesValues, "value", "label", true);

            deselectChoice(this.companiesChoices, parseInt(target.dataset.choiceid));
            removeInputs(this.companiesChoices, parseInt(target.dataset.choiceid), this.companiesInputsTarget);

            // Updating Companies Choices
            this.companiesChoices.getValue().forEach(choice => choice.active = false);
            companyChoicesValues                      = this.companiesChoices._currentState.choices;
            this.companiesChoices.config.maxItemCount = this.companiesLeft;
            this.companiesChoices.unhighlightItem(target.dataset.choiceid);
        }

        if (selectionTarget === GROUPS_TARGET) {
            this.groupsChoices.setChoices(groupChoicesValues, "value", "label", true);

            deselectChoice(this.groupsChoices, parseInt(target.dataset.choiceid));
            removeInputs(this.groupsChoices, parseInt(target.dataset.choiceid), this.groupsInputsTarget);

            // Updating Group Choices
            this.groupsChoices.getValue().forEach(choice => choice.active = false);
            groupChoicesValues                     = this.groupsChoices._currentState.choices;
            this.groupsChoices.config.maxItemCount = this.groupsLeft;
            this.groupsChoices.unhighlightItem(target.dataset.choiceid);
        }

        this.element.dispatchEvent(this.customEvent);
    }

    /*
     Remove all from the choice instance the current target selection.
     */
    deselectAll({target}) {
        const selectionTarget = this.selectedTarget;

        if (selectionTarget === COMPANIES_TARGET) {
            this.companiesChoices.setChoices(companyChoicesValues, "value", "label", true);

            this.companiesChoices._currentState.items.forEach(choice => {
                deselectChoice(this.companiesChoices, choice.choiceId);
                removeInputs(this.companiesChoices, choice.choiceId, this.companiesInputsTarget);
            });

            // Updating Companies Choices
            this.companiesChoices.getValue().forEach(choice => choice.active = false);
            companyChoicesValues                      = this.companiesChoices._currentState.choices;
            this.companiesChoices.config.maxItemCount = this.companiesLeft;

            Promise.resolve().then(() => {
                this.companiesChoices.unhighlightAll();
            });
        }

        if (selectionTarget === GROUPS_TARGET) {
            this.groupsChoices.setChoices(groupChoicesValues, "value", "label", true);

            this.groupsChoices._currentState.items.forEach(choice => {
                deselectChoice(this.groupsChoices, choice.choiceId);
                removeInputs(this.groupsChoices, choice.choiceId, this.groupsInputsTarget);
            });

            // Updating Group Choices
            this.groupsChoices.getValue().forEach(choice => choice.active = false);
            groupChoicesValues                     = this.groupsChoices._currentState.choices;
            this.groupsChoices.config.maxItemCount = this.groupsLeft;

            Promise.resolve().then(() => {
                this.groupsChoices.unhighlightAll();
            });
        }

        this.element.dispatchEvent(this.customEvent);
    }

    /*
     Changes the visibility of the containers based on the selection.
     */
    updateUI() {
        this.element.dispatchEvent(this.customEvent);
        switch (this.selectedTarget) {
            case "ALL":
                showAllCompaniesContainer();
                return;
            case "CUSTOM":
                showCustomCompaniesContainer();
                return;
            case "GROUPS":
                showGroupsCompaniesContainer();
                return;
        }
    }
}

function initializeCompanyChoices(target, selected) {
    const choices = new Choices(target, {
        maxItemCount: 50,
        removeItemButton: true,
        resetScrollPosition: false,
        noChoicesText: "No more companies to select.",
        maxItemText: (maxItemCount) => {
            return `Only 50 companies can be added`;
        },
        callbackOnCreateTemplates: function (template) {
            return {
                choice: (classNames, data) => {
                    return template(`
                        <div class='${classNames.item} ${classNames.itemChoice} 
                        ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}' data-select-text='${this.config.itemSelectText}' 
                        data-choice ${data.disabled ? "data-choice-disabled aria-disabled=\"true\"" : "data-choice-selectable"} 
                        data-id='${data.id}' data-value='${data.value}' ${data.groupId > 0 ? "role=\"treeitem\"" : "role=\"option\""}>
                        <i class='fas fa-building mr-1'></i> ${data.label} (${data.customProperties.userscount} Users)
                        </div>`);
                },
            };
        },
    });

    const list    = document.querySelector("#companies-list > ul");
    const options = Array.from(list.children).map(company => {
        return {
            selected: selected.includes(company.dataset.companyId),
            value: company.dataset.companyId,
            label: company.dataset.companyName,
            customProperties: {
                userscount: parseInt(company.dataset.companyUsers),
            },
        };
    });

    const choicesOptions = [{
        label: "Companies",
        id: 1,
        disabled: false,
        choices: options,
    }];

    list.remove();
    choices.setChoices(choicesOptions, "value", "label", false);

    return choices;
}

function initializeGroupsChoices(target, selected) {
    const choices = new Choices(target, {
        maxItemCount: 50,
        removeItemButton: true,
        resetScrollPosition: false,
        noChoicesText: "No more groups to select.",
        maxItemText: (_) => {
            return `Only 50 groups can be added`;
        },
        callbackOnCreateTemplates: function (template) {
            return {
                item: (classNames, data) => {
                    return template(`
			          <div class='${classNames.item} ${
                        data.highlighted
                            ? classNames.highlightedState
                            : classNames.itemSelectable
                    } ${
                        data.placeholder ? classNames.placeholder : ""
                    }' data-item data-id='${data.id}' data-value='${data.value}' ${
                        data.active ? "aria-selected=\"true\"" : ""
                    } ${data.disabled ? "aria-disabled=\"true\"" : ""}>
			            ${data.label} 
			            <button type='button' class='choices__button' data-button='' aria-label="Remove item: '${data.value}'">
				            Remove item
			          	</button>
			          </div>
			        `);
                },
                choice: (classNames, data) => {
                    return template(`
                        <div class='${classNames.item} ${classNames.itemChoice} 
							${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}' data-select-text='${this.config.itemSelectText}' 
							data-choice ${data.disabled ? "data-choice-disabled aria-disabled=\"true\"" : "data-choice-selectable"} 
							data-id='${data.id}' data-value='${data.value}' ${data.groupId > 0 ? "role=\"treeitem\"" : "role=\"option\""}>
                        <i class='fas fa-cubes mr-1'></i> ${data.customProperties.label}
                        </div>`);
                },
            };
        },
    });

    const list    = document.querySelector("#groups-list > ul");
    const options = Array.from(list.children).map(group => {
        return {
            selected: selected.includes(group.dataset.groupId),
            value: group.dataset.groupId,
            label: group.dataset.groupName,
            customProperties: {
                label: group.dataset.label,
                companiescount: parseInt(group.dataset.companies),
                userscount: parseInt(group.dataset.users),
            },
        };
    });

    const choicesOptions = [{
        label: "Groups",
        id: 1,
        disabled: false,
        choices: options,
    }];

    list.remove();
    choices.setChoices(choicesOptions, "value", "label", false);

    return choices;
}

function showAllCompaniesContainer() {
    ALL_COMPANIES_SELECTED_BOX_ELEMENT.classList.remove("hidden");
    CUSTOM_COMPANIES_SELECTION_BOX_ELEMENT.classList.add("hidden");
    GROUPS_BOX_ELEMENT.classList.add("hidden");
}

function showCustomCompaniesContainer() {
    ALL_COMPANIES_SELECTED_BOX_ELEMENT.classList.add("hidden");
    CUSTOM_COMPANIES_SELECTION_BOX_ELEMENT.classList.remove("hidden");
    GROUPS_BOX_ELEMENT.classList.add("hidden");
}

function showGroupsCompaniesContainer() {
    ALL_COMPANIES_SELECTED_BOX_ELEMENT.classList.add("hidden");
    CUSTOM_COMPANIES_SELECTION_BOX_ELEMENT.classList.add("hidden");
    GROUPS_BOX_ELEMENT.classList.remove("hidden");
}

function addCompanies(choices, table) {
    Promise.resolve().then(() => {
        let companies = {data: []};

        choices.forEach(choice => {
            const close    = `<i class='fas fa-times text-danger pointer-cursor' data-target='companies' data-action='click->target-companies#deselect click->datatable#remove' data-choiceid='${choice.choiceId}'></i>`;
            let usersCount = choice.customProperties.userscount;
            if (usersCount === 0) {
                usersCount = `${usersCount} <span class='text-danger font-14 ml-3'><i class='fas fa-exclamation-triangle'></i> This company doesn't have users</span>`;
            }

            companies.data.push([choice.label, usersCount, close]);
            choice.active = false;
        });

        // add the rows
        table.rows = companies;
    });
}

function addGroups(choices, table) {
    Promise.resolve().then(() => {
        let groups = {data: []};

        choices.forEach(choice => {
            const close        = `<i class='fas fa-times text-danger pointer-cursor' data-target='groups' data-action='click->target-companies#deselect click->datatable#remove' data-choiceid='${choice.choiceId}'></i>`;
            let companiescount = choice.customProperties.companiescount;
            if (companiescount === 0) {
                companiescount = `${companiescount} <span class='text-danger font-14 ml-3'><i class='fas fa-exclamation-triangle'></i> This group doesn't have companies</span>`;
            }

            groups.data.push([choice.label, companiescount, close]);
            choice.active = false;
        });

        // add the rows
        table.rows = groups;
    });
}

function deselectChoice(choices, id) {
    const choice  = choices._currentState.items.find(choice => choice.choiceId === id);
    choice.active = true;
    choices.removeActiveItemsByValue(choice.value);
}

function insertInputs(choices, group, container) {
    const inputs = document.createDocumentFragment();
    for (const choice of choices) {
        const input = document.createElement("input");
        input.type  = "hidden";
        input.name  = (group.charAt(0).toUpperCase() + group.slice(1)) + "[0]";
        input.value = choice.value;

        inputs.appendChild(input);
    }

    container.appendChild(inputs);
    reorderIndexFor(container);
}

function removeInputs(choices, id, container) {
    const choice = choices._currentState.items.find(choice => choice.choiceId === id);
    const input  = Array.from(container.children).find(input => input.value === choice.value);

    if (input === undefined) {
        return;
    }

    input.remove();
    reorderIndexFor(container);
}

function toggleDatatable(count, container) {
    count > 0 ? (container.classList.remove("hidden"), container.previousElementSibling.classList.add("hidden")) :
        (container.classList.add("hidden"), container.previousElementSibling.classList.remove("hidden"));
}

function updateUsersCount(count, container) {
    container.innerText = count;
}
