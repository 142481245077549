import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["resCont", "AdminName"]

    initialize() { }

    resend(event) {
        event.preventDefault()

        const linkTag = event.target;
        const rowIndex = linkTag.dataset.invitationIndex;
        const messageCont = this.resContTargets[rowIndex];
        const resendIcon = messageCont.childNodes[0];


        if (messageCont.classList.contains("invisible")) {
            messageCont.classList.remove("invisible");
        }

        resendIcon.classList.add("sending")

        fetch(linkTag.href, {
            headers: {
                'Content-type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            },
            method: 'PUT',
        })
            .then(data => {
                if (data.ok) {
                    this.setFlashMessage(this.AdminNameTargets[rowIndex].textContent)

                    messageCont.childNodes[0].classList.remove("sending");
                    messageCont.classList.add("invisible");
                }
            })
    }

    setFlashMessage(name) {
        const flashContainer = document.getElementById("alert-container")
        flashContainer.innerHTML = `<div class="alert alert-fade alert-info m-t-20" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>The invitation has been resent to <strong>${name}</strong></div>`
    }
}