import {Controller} from "@hotwired/stimulus";
import {parseCategories} from "./categories";
import Choices from "choices.js";

export default class extends Controller {
    static targets = ["template", "select"];
    static outlets = ["targets--modal"];
    static values = {
        data: {type: Array, default: []},
    };

    connect() {
        const values = this.dataValue.map(template => template.id);
        // Initializing choices instance
        this.templateChoices = initChoices(this.selectTarget, values);

        // Selecting templates with its categories if any
        this.dataValue.forEach(template => {
            this.select({detail: {value: template.id}});

            const target = this.templateTargets.find(target => template.id === target.dataset.value);
            const categories = template.categories;
            this.updateCategories({target: target, detail: {categories: categories}});
        });

        // Removing dataset from HTML
        this.element.removeAttribute("data-targets--templates-data-value");
    }

    /**
     * Deselects a simulation template from the associated list.
     * @param {HTMLElement} target - The event target that triggered the removal of the list item.
     * @method deselect
     * @description Removes the active template items with the same value as the removed list item and then removes the item itself from the template list.
     */
    deselect({target: target}) {
        const item = target.closest("[data-targets--templates-target]");
        const choice = this.templateChoices._currentState.items.find(choice => choice.value === item.dataset.value);
        choice.active = true;
        this.templateChoices.removeActiveItemsByValue(item.dataset.value);

        item.remove();
        // Hidding selections
        Array.from(this.templateChoices.itemList.element.children).forEach(child => child.style.display = "none");
    }

    /**
     * Selects the given target and adds it to the current selection.
     * @param {String} value - The value from the selected item.
     * @method select
     * @description Triggers the selection of the given target.
     * Extract the data from choicesJS and then call 'addTemplateToList()' to add it to the current selection.
     */
    select({detail: {value: value}}) {
        // Closing choices dropdown.
        this.templateChoices.hideDropdown();

        const selected = this.templateChoices.getValue().find(choice => choice.value === value);
        if (selected === undefined) {
            const target = this.element.querySelector(`#templates-list [data-value="${value}"]`);
            this.deselect({target: target});
            return;
        }

        const cp = JSON.parse(selected.customProperties.replace(/(\r\n|\n|\r)/gm, ""));
        const previewPath = cp.previewPath + "/" + value + "/show";
        
        addTemplateToList(selected.label, selected.value, cp.thumbnail, previewPath);
        // Hidding selections
        Array.from(this.templateChoices.itemList.element.children).forEach(child => child.style.display = "none");
    }

    /**
     * Opens the category selection modal.
     * @param {HTMLElement} opener - The target element that triggered the opening of the modal.
     * @method openModal
     * @description Opens the categories modal outlet with the provided opener,
     allowing it to be displayed and interacted with by the user.
     */
    openModal({target: opener}) {
        const target = opener.closest("[data-targets--templates-target]");
        this.targetsModalOutlet.open(target);
    }

    /**
     * Updates the template assignment categories based on the data submited by the categories modal.
     * @param {Object} - The event object containing the target and category data.
     * @method updateCategories
     * @description Parses the provided category values and updates the template assignment categories list with the parsed results.
     */
    updateCategories({target: target, detail: {categories: values}}) {
        let inputName = `Categories[${target.dataset.value}]`;
        const categories = parseCategories(inputName, values, "Everybody");
        const list = target.querySelector("[data-name=\"simulation-template-categories\"]");
        list.innerHTML = categories;
    }
}

/**
 * Adds a new simulation template to the templates list.
 * @param {string} name - The name of the template.
 * @param {string} value - The value associated with the template.
 * @param {string} thumbnail - The thumbnail for the template.
 * @param {string} previewPath - The path for the template preview.
 * @method addTemplateToList
 * @description Creates a new template item based on the given name and value, and appends it to the template list.
 * Populates the template with the provided values.
 */
function addTemplateToList(name, value, thumbnail, previewPath) {
    const list = document.getElementById("templates-list");
    const tmpl = document.getElementById("template-item-template");
    const item = tmpl.content.cloneNode(true);

    const img = document.createElement("img");
    img.classList.add("rounded-md", "w-20");
    img.alt = "thumbnail";
    img.src = thumbnail;
    img.onerror = function () {
        this.src = "/assets/images/email_default@2x.png";
    };

    const imgContainer = item.querySelector("[data-name=\"thumbnail\"]");
    imgContainer.appendChild(img);

    item.firstElementChild.dataset.value = value;
    item.querySelector("[data-name=\"title\"]").href = previewPath;
    item.querySelector("[data-name=\"title\"]").innerText = name.trim();
    list.append(item);
}

/**
 * Initializes the Choices component with the given target and values.
 * This function sets up the Choices component with a default configuration
 * and populates it with the provided values.
 * The resulting "Choices" instance is then assigned to the target object.
 *
 * @param {HTMLElement} target - The target object that will receive the initialized Choices instance.
 * @param {Array<String>} values - The array of values to be used to populate the Choices component.
 * @returns {Choices} The initialized Choices instance.
 */
function initChoices(target, values) {
    if (target === null) return;

    const config = {
        noChoicesText: `No more templates to filter by`,
        placeholder: true,
        placeholderValue: "Search Templates",
        removeItems: false,
        removeItemButton: false,
        resetScrollPosition: false,
        maxItemCount: 20,
        maxItemText: (maxItemCount) => {
            return `Max ${maxItemCount} templates can be selected.`;
        },
        callbackOnCreateTemplates: template => {
            return {
                choice: (classNames, data) => {
                    const cp = JSON.parse(data.customProperties.replace(/(\r\n|\n|\r)/gm, ""));
                    const categories = cp.categories || [];
                    const pills = categories.map(cat => `<span class='text-xxs new-pill'>${cat}</span>`);

                    return template(`
<div class='${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}' data-select-text='Press to select' data-choice ${data.disabled ? "data-choice-disabled aria-disabled=\"true\"" : "data-choice-selectable"} data-id='${data.id}' data-value='${data.value}' ${data.groupId > 0 ? "role=\"treeitem\"" : "role=\"option\""}>
    <div class='flex items-start space-x-3'>
        <img class='min-w-[80px] max-w-[80px] w-[80px] rounded-md' src='${cp.thumbnail}' onerror="this.src='/assets/images/email_default@2x.png'" alt='template_thumbnail'>
        <div class='flex flex-col space-y-1'>
            <p class='mb-0 text-sm font-semibold'>${data.label}</p>
            <p class='mb-0 text-xs'>${cp.description || "No description"}</p>
            <div class='flex items-center space-x-1'>${pills.join("")}</div>
        </div>
    </div>
</div>`);
                },
            };
        },
    };

    const choices = new Choices(target, config);
    choices.setChoiceByValue(values);
    target["choices"] = choices;

    return choices;
}
