import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["logoContainer", "pabButton"];

	changeText() {
		this.pabButtonTarget.innerHTML = this.pabButtonTarget.innerHTML.trim() === "Show More" ? "Show Less" : "Show More";
	}

	cleanLogo() {
		this.logoContainerTarget.remove();
	}
}
