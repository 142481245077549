import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [];

    get selectedRows() {
        let rows = [];
        this._bulkController.itemCheckboxTargets.forEach(item => item.checked && rows.push(item.parentElement.parentElement));

        return rows;
    }

    initialize() {
        this._bulkController = this._getBulkList();
        this.toggleBulkDelete();
        this.toggleBulkUnsynchronize();
        this.togglePauseActions();
    }

    toggleBulkDelete() {
        const hasExternalUser = this.selectedRows.some(row => row.dataset.external === "true");
        const dropdownButton  = this._bulkController.actionsListTarget;
        const deleteButton    = dropdownButton.querySelector("[data-title=\"Delete Users\"]");

        hasExternalUser ? deleteButton.classList.add("!hidden") : deleteButton.classList.remove("!hidden");
    }

    toggleBulkUnsynchronize() {
        const hasExternalUser = this.selectedRows.some(row => row.dataset.external === "false");
        const dropdownButton  = this._bulkController.actionsListTarget;
        const unsyncButton    = dropdownButton.querySelector("[data-title=\"Send to Removal Queue\"]");

        hasExternalUser ? unsyncButton.classList.add("!hidden") : unsyncButton.classList.remove("!hidden");
    }

    togglePauseActions() {
        const hasExternalUser = this.selectedRows.some(row => row.dataset.external === "true");
        const hasPausedUsers  = this.selectedRows.some(row => (row.dataset.status === "PAUSED" || row.dataset.status === "DISABLED"));
        const hasActiveUsers  = this.selectedRows.some(row => row.dataset.status === "Active");
        const pauseAction     = this._bulkController.actionsListTarget.querySelector("[data-title=\"Pause Users\"]");
        const unpauseAction   = this._bulkController.actionsListTarget.querySelector("[data-title=\"Unpause Users\"]");

        hasPausedUsers && !hasExternalUser ? unpauseAction.classList.remove("!hidden") : unpauseAction.classList.add("!hidden");
        hasActiveUsers && !hasExternalUser ? pauseAction.classList.remove("!hidden") : pauseAction.classList.add("!hidden");
    }

    updateRiskScore() {
        const elements = this.element.querySelectorAll("[data-controller=\"users--risk-scoring\"]");
        caches.delete("user-risk-status");

        Array.from(elements).forEach(element => {
            const riskScoreController = element["users--risk-scoring"];
            element.innerHTML         = `<span class='group-label' style='background-color: #F7F7F9; color: #455A64 !important;'>Calculating...</span>`;

            riskScoreController.update();
        });
    }

    _getBulkList() {
        const list = document.querySelector("[data-controller=\"bulk-selection\"]");
        return this.application.getControllerForElementAndIdentifier(list, "bulk-selection");
    }
}
