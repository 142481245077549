import { Controller } from "@hotwired/stimulus";

export default class SourceForm extends Controller {
	static targets = ["source", "button"];

	get selectedCount() {
		return this.sourceTargets.filter(target => target.checked).length;
	}

	validate() {
		this.selectedCount === 0 ? this.buttonTarget.disabled = true : this.buttonTarget.disabled = false;
	}
}
