import { Controller } from "@hotwired/stimulus"

export default class SlideController extends Controller {
    static targets = ["collapsible"]
    static values = { open: Boolean }

    initialize() {
        this.element[this.identifier] = this;
    }

    up() {
        if (!this.openValue) {
            return
        }

        this.openValue = false;

        const target = this.element;
        const duration = 500;

        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;

        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    down() {
        if (this.openValue) {
            return
        }

        this.openValue = true;

        const target = this.element;
        const duration = 500;

        target.style.removeProperty('display');
        let height = target.offsetHeight;
        let display = window.getComputedStyle(target).display;

        if (display === 'none') {
            display = 'block';
        }

        target.style.display = display;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');

        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    toggle() {
        // if (this.collapsibleTarget.classList.contains('collapsing')) {
        //     return
        // }

        // $(this.collapsibleTarget).collapse('toggle');
        // this.iconTarget.classList.toggle(this.collapsedClass);
    }
}