import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
      this.validatePassword();
    }

    validatePassword() {
		const password = document.getElementById("newPasswordInput").value;

        let number = document.getElementById('number');
        let letter = document.getElementById('letter');
        let blank = document.getElementById('blank');
        let special = document.getElementById('special');
        let character = document.getElementById('characters');


        const hasNumber = /\d/.test(password);
        const hasNoSpaces = /^\S+$/.test(password);
        const hasLowerUppercase =  /[a-z]/.test(password) && /[A-Z]/.test(password);
        const hasCorrectLenght = password.length >= 10 && password.length <= 50;
        const hasSpecialChar = /[^a-zA-Z0-9\s]/g.test(password);

    
        hasNumber ? number.classList.add('group') : number.classList.remove('group');
        hasLowerUppercase ? letter.classList.add('group') : letter.classList.remove('group');
        hasNoSpaces ? blank.classList.add('group') : blank.classList.remove('group');
        hasCorrectLenght ? character.classList.add('group') : character.classList.remove('group');
        hasSpecialChar ? special.classList.add('group') : special.classList.remove('group');
      }
}