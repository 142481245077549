import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		// Toggling submenu if any item has an active class.
		const activeLink = this.element.querySelector("a.active");
		if (activeLink !== null) {
			const tab = activeLink.closest(".tab");
			if (tab !== null) {
				tab.classList.add("submenu-active");
			}
		}

	}

	toggle(event) {
		event.preventDefault();

		const target  = event.currentTarget.parentElement;
		const current = this.element.querySelector(".submenu-active");

		if (current !== target && current !== null) {
			current.classList.remove("submenu-active");
		}

		target.classList.toggle("submenu-active");
	}
}
