import { Controller } from "@hotwired/stimulus";
import c3 from "c3";

export default class extends Controller {
	static values = {
		data: Object,
		url: String,
	};

	connect() {
		this.element[this.identifier] = this;
		this.load();
	}

	load() {
		const element = this.element;
		const dates   = this._parsedDates();
		const values  = this._parsedValues();

		let options = {
			bindto: element,
			size: {
				height: 402,
			},
			data: {
				columns: values,
				type: "bar",
				order: "null",
				onclick: (d, i) => {
					if (d["value"] === 0) return;

					let dateInterval = this._dateInterval(dates[d["index"]]);
					let eventName    = d["id"];

					location.href = `${this.urlValue}?From=${dateInterval[0]}&Until=${dateInterval[1]}&Events=${eventName}`;
				},
				selection: {
					enabled: true,
				},
			},
			bar: {
				space: 0.5,
				width: 15,
			},
			grid: {
				x: {
					show: true,
				},
				y: {
					show: true,
				},
			},
			axis: {
				x: {
					type: "category",
					categories: dates,
					tick: {
						centered: false,
					},
				},
				y: {
					min: 0,
					padding: {bottom: 0},
					tick: {
						format: function (x) {
							return x % 1 === 0 ? x : "";
						},
					},
				},
			},
			legend: {
				show: false,
			},
			color: {
				pattern: ["#153B6A", "#1185E5", "#FCBA4D", "#56CE63"],
			},
		};

		this.chart  = c3.generate(options);
		const chart = this.chart;

		d3.select(".events-chart-flags").selectAll(".event-chart-item")
			.data(["Bounced", "Opened", "Phish Click", "Potential Shared Data"])
			.on("mouseover", id => chart.focus(id))
			.on("mouseout", () => chart.revert())
			.on("click", function (id) {
				const activeLegends = chart.data().length - this.parentNode.querySelectorAll(".hide").length;
				if (activeLegends <= 1 && !this.classList.contains("hide")) return;

				this.classList.toggle("hide");
				chart.toggle(id);
			});
	}

	_dateInterval(date) {
		let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		let fromDate  = new Date((new Date()).getFullYear().toString(), 0, 1);
		let untilDate = new Date((new Date()).getFullYear().toString(), 12, 0);

		months.forEach(element => {
			const chartInterval = new URL(window.location.href).searchParams.get("chart_interval");
			let currentYear     = (new Date()).getFullYear();

			if (chartInterval === "last") {
				currentYear -= 1;
			}

			if (date === element) {
				fromDate  = new Date(`${date} 1, ${currentYear}`);
				untilDate = new Date(currentYear, fromDate.getMonth() + 1, 0);

			}
		});

		const last12Pattern = /(\w{3})\s\((\d{4})\)/;
		if (last12Pattern.test(date)) {
			const val = date.match(last12Pattern);
			fromDate  = new Date(`${val[1]} 1, ${val[2]}`);
			untilDate = new Date(val[2], fromDate.getMonth() + 1, 0);
		}

		if (date[0] === "(") {
			let strDates = date.substring(1, date.length - 1);
			let dates    = strDates.split(" - ");
			fromDate     = new Date(`${dates[0]}, ${(new Date()).getFullYear()}`);
			untilDate    = new Date(`${dates[1]}, ${(new Date()).getFullYear()}`);
		}

		if (/[\w]+\s-\s[\w]+\s\d+,\s\d+/.test(date)) {
			let bothDates = date.split(/[-,]/)[1];
			fromDate      = untilDate = new Date(`${bothDates}, ${(new Date()).getFullYear()}`);
		}

		if (/\w{3} \d{1,2}, \d{4}/.test(date)) {
			fromDate = untilDate = new Date(date);
		}

		let today = new Date();
		if (fromDate > today) {
			fromDate = today;
		}

		if (untilDate > today) {
			untilDate = today;
		}

		let from = [((fromDate.getMonth() + 1) > 9 ? "" : "0") + (fromDate.getMonth() + 1),
			(fromDate.getDate() > 9 ? "" : "0") + fromDate.getDate(),
			fromDate.getFullYear(),
		].join("/");

		let until = [((untilDate.getMonth() + 1) > 9 ? "" : "0") + (untilDate.getMonth() + 1),
			(untilDate.getDate() > 9 ? "" : "0") + untilDate.getDate(),
			untilDate.getFullYear(),
		].join("/");

		return [from, until];
	}

	_defaultValues(name) {
		return [name, 0, 0, 0, 0, 0, 0];
	}

	_parsedValues() {
		let emailInfo = [];

		emailInfo[0] = this.dataValue.emails_bounced ? ["Bounced", ...this.dataValue.emails_bounced] : this._defaultValues("Bounces");
		emailInfo[1] = this.dataValue.emails_opened ? ["Opened", ...this.dataValue.emails_opened] : this._defaultValues("Opened");
		emailInfo[2] = this.dataValue.emails_clicked ? ["Phish Click", ...this.dataValue.emails_clicked] : this._defaultValues("Phish Click");
		emailInfo[3] = this.dataValue.data_entered ? ["Potential Shared Data", ...this.dataValue.data_entered] : this._defaultValues("Potential Shared Data");

		return emailInfo;
	}

	_parsedDates() {
		let dates             = [];
		let valuesDates       = this.dataValue.categories;
		const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		if (this.dataValue.categories != null) {
			valuesDates = this.dataValue.categories;
		}

		for (var i = 0; i < valuesDates.length; i++) {
			const date      = valuesDates[i];
			const dateSplit = date.split(/-|T/);
			const time      = new Date([dateSplit[0], dateSplit[1], dateSplit[2]].join("/"));
			const isValid   = isFinite(time);

			if (isValid && date.split(" ").length === 1) {
				dates.push(`${monthNamesShort[time.getMonth()]} ${time.getDate()}, ${time.getFullYear()}`);
			} else {
				dates.push(date);
			}

		}

		return dates;
	}

}
