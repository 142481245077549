import { Controller } from "@hotwired/stimulus";
import dropify from "dropify";

const DEFAULT_MESSAGE = `<strong style="font-size: 16px;">Drag and drop your file here or click.</strong><br><p style="font-size: 16px;">Or <u>browse</u> your file from your desktop</p>`

export default class extends Controller {
	static targets = ["dropzone"];

	dropzoneTargetConnected() {
		const dropzones = this.dropzoneTargets;

		dropzones.forEach((dropzone) => {
			const message = dropzone.dataset.message || DEFAULT_MESSAGE;

			dropzone["dropify"] = $(dropzone).dropify({messages: {"default": message}}).data("dropify");
		});		
	}
}
