import { confirm as _confirm, dialog } from "bootbox";

$(() => {
	// Override windows.confirm dialog box
	$(document).on("confirm", "[data-confirm]", function () {
		const $el    = $(this);
		const method = $el.data("method");
		let btnLabel = $el.data("button") || "Continue";
		let message  = $el.data("confirm");
		let title    = $el.data("title");
		let btnClass = $el.data("button-class") || "tw-btn tw-btn-primary";

		if (method == "DELETE") btnClass = "tw-btn tw-btn-danger";

		_confirm({
			size: "small",
			title: title,
			message: message,
			buttons: {
				confirm: {
					label: btnLabel,
					className: btnClass,
				},
				cancel: {
					label: "Cancel",
					className: "tw-btn tw-btn-secondary",
				},
			},
			callback: function (result) {
				if (result) {
					$el.data("confirm", null);
					$el.trigger("click.rails");
				}
			},
		});

		// prevent the default behaviour of showing the standard window.confirm dialog
		return false;
	});

	// Bootbox modal for Templates Page Only
	$(".template-bootbox").on("click", function (e) {
		const $el         = $(this);
		const buttonLabel = $el.data("name");
		const message     = $el.data("confirm");

		_confirm({
			size: "small",
			title: `${buttonLabel} Template`,
			message: message,
			buttons: {
				confirm: {
					label: buttonLabel,
					className: "tw-btn tw-btn-primary",
				},
				cancel: {
					label: "Cancel",
				},
			},
			callback: function (result) {
				if (result) {
					$el.data("confirm", null);
					$el.trigger("click.rails");
				}
			},
		});

		return false;
	});

	// Bootbox modal for unsaved changes
	$(".confirm-cancel").on("click", function () {
		const buttonLabel = "Discard Changes";
		const message     = "You have not saved changes. If you go back without saving changes, all information you entered will be removed. <br><br> Do you want to go back without saving changes?";
		const location    = $(this).prop("href");

		_confirm({
			size: "small",
			title: buttonLabel,
			message: message,
			buttons: {
				confirm: {
					label: buttonLabel,
					className: "tw-btn tw-btn-primary",
				},
				cancel: {
					label: "Cancel",
					className: "tw-btn tw-btn-secondary",
				},
			},
			callback: function (result) {
				if (result) {
					window.location.href = location;
				}
			},
		});

		return false;
	});

	//Deny Request button
	$(".deny-request").on("click", function (e) {
		const $el         = $(this);
		const buttonLabel = $(this).data("name");
		const message     = $(this).data("confirm");
		const location    = $(this).data("location");

		_confirm({
			size: "small",
			title: `${buttonLabel}`,
			message: message,
			buttons: {
				confirm: {
					label: buttonLabel,
					className: "tw-btn tw-btn-danger",
				},
				cancel: {
					label: "Cancel",
				},
			},
			callback: function (result) {
				if (result) {
					window.location.href = location;
					$(this).data("confirm", true);
					$(this).trigger("click.rails");
				}
			},
		});

		return false;
	});

	// Bootbox modal for Company Leave & Delete Page Only
	$("#company-leave").on("confirm", "[data-confirm]", function (e) {
		const $el         = $(this);
		const buttonLabel = $el.data("name");
		const message     = $el.data("confirm");

		_confirm({
			size: "small",
			title: `${buttonLabel}`,
			message: message,
			buttons: {
				confirm: {
					label: buttonLabel,
					className: "tw-btn tw-btn-danger",
				},
				cancel: {
					label: "Cancel",
				},
			},
			callback: function (result) {
				if (result) {
					$el.data("confirm", true);
					$el.trigger("click.rails");
				}
			},
		});

		return false;
	});

	// Bootbox modal for Bulking Actions
	$("a[data-name=\"bulk-confirm\"]").on("click", function (e) {
		const form        = getFormData($(this));
		const title       = $(this).data("title");
		const method      = $(this).data("method");
		const message     = $(this).data("message");
		const buttonLabel = $(this).data("button");

		let className = "tw-btn tw-btn-primary";

		if (method == "DELETE") {
			className = "tw-btn-danger";
		}

		form.prop("target", $(this).prop("target"));

		_confirm({
			size: "small",
			title: title,
			message: message,
			buttons: {
				confirm: {
					label: buttonLabel,
					className: className,
				},
				cancel: {
					label: "Cancel",
					className: "tw-btn tw-btn-secondary",
				},
			},
			callback: function (result) {
				if (result) {
					form.appendTo("body");
					form.submit();
				}
			},
		});

		return false;
	});

	$("a[data-name=\"bulk-prompt\"]").on("click", function (e) {
		const form         = getFormData($(this));
		const target       = $(this).data("modal-target");
		const modalTitle   = $(this).data("title");
		const $targetModal = $(`#${target}-modal`).removeClass("d-none");
		const buttonLabel  = $(this).data("button");

		dialog({
			message: $targetModal,
			title: modalTitle,
			closeButton: false,
			buttons: [
				{
					label: "Cancel",
					className: "tw-btn tw-btn-secondary",
					callback: function () {
						$targetModal.addClass("d-none").appendTo(".container-fluid");
					},
				},
				{
					label: buttonLabel,
					className: "tw-btn tw-btn-primary",
					callback: function () {
						const selectInput = $targetModal.find("select, input");

						form.append(selectInput).appendTo("body");
						form.submit();
					},
				},
			],
		});

		return false;
	});

	// Modal for a single user/course due date update
	$("#dueDate-modal").on("show.bs.modal", function (e) {
		const form   = e.target.querySelector("form");
		const action = e.relatedTarget.dataset.link;
		form.action  = action;
	});
});

function getFormData($targetElement) {
	const method = $targetElement.data("method");
	const href   = $targetElement.attr("href");

	const csrfToken      = $("meta[name=csrf-token]").attr("content");
	const csrfParam      = $("meta[name=csrf-param]").attr("content");
	const form           = $(`<form method='post' action='${href}'></form>`);
	let metadataInput    = `<input name='_method' value='${method}' type='hidden'/>`;
	const $inputsChecked = $(".table tbody input:checked");

	if (csrfParam !== undefined && csrfToken !== undefined) {
		metadataInput += `<input name='${csrfParam}' value='${csrfToken}' type='hidden' />`;

		if (!href.includes("allEntries")) {
			$inputsChecked.each((_, input) => {
				metadataInput += `<input name='${$(input).data("name")}' value='${$(input).data("value")}' />`;
			});
		}
	}

	form.hide().append(metadataInput);
	return form;
}
