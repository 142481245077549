import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['link'];

  handleClick(event) {
    event.preventDefault(); 

    const link = event.currentTarget;
    const paramToRemove = link.id;

    
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.delete(paramToRemove);
    window.location.href = currentUrl.toString();  }
}