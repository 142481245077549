import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const [AttachmentFileType, AttachmentIconPath] = ["FileType", "IconPath"];
const SENDERNAME_ALLOWED_CHARS_REGEXP          = /[a-zA-Z0-9]+/;
const ATTACHMENT_FILE_TYPES                    = new Map([
	["docx", "Word Document"],
	["xlsx", "Excel Document"],
	["pptx", "Powerpoint Document"],
	["pdf", "PDF Document"],
	["mp3", "MP3 Audio File"],
	["wav", "WAV Audio File"],
]);

export default class TargetTemplates extends Controller {
	static targets = [
		"list", "select", "addButton", "count", "emptyBox",
		"templateBox", "template", "templateName", "varList", "arrowVar",
		"landingPageSwitch",
	];

	connect() {
		this._customVariables       = window.customVariables;
		this.defaultCustomVariables = window.defaultCustomVariables;

		if (this.selectTarget.previousElementSibling.length !== 0) {
			this.selectTarget.previousElementSibling.remove();
		}

		this._choicesInstance = new Choices(this.selectTarget, {
			maxItemCount: 5,
			maxItemText: () => "Max 5 Templates can be used per campaign",
			removeItemButton: true,
			resetScrollPosition: false,
			shouldSort: false,
		});

		const groups  = document.getElementById("original-templates-choices");
		const choices = Array.from(groups.children).map((group, index) => {
			const templates = Array.from(group.children).map(template => {
				return {
					selected: template.selected,
					value: template.value,
					label: template.label,
					customProperties: {
						campaignid: template.dataset.campaignid,
						type: template.dataset.type,
						withAttachment: template.dataset.attachment,
					},
				};
			});

			return {
				label: group.label,
				id: index + 1,
				disabled: false,
				choices: templates,
			};
		});

		this._choicesInstance.setChoices(choices, "value", "label", false);
		groups.remove();

		this._initTemplateObserver();
		this._loadCustomVariables();

		if (this._choicesInstance.getValue().length > 0)
			this.addTemplates();
	}

	addTemplates() {
		this._choicesInstance.config.maxItemCount = this._choicesInstance.config.maxItemCount - this._choicesInstance.getValue().length;

		this._choicesInstance.getValue().forEach((choice, index) => {
			const template = this.templateTarget.content.cloneNode(true);
			const input    = this._createHiddenInput(choice.value, index);
			const cp       = choice.customProperties;

			let templateType          = template.getElementById("templateType");
			templateType.dataset.type = cp.type;

			if (cp.type === "Private") {
				templateType.src = "/assets/images/padlock-black.svg";
				templateType.alt = "padlock";
			} else {
				templateType.src = "/assets/images/earth-green.svg";
				templateType.alt = "earth";
			}

			template.getElementById("templateRemove").dataset.choiceid = choice.choiceId;
			template.getElementById("templateName").innerText          = choice.label;
			template.querySelector("button").dataset.choiceid          = choice.choiceId;

			const varList = template.getElementById("variablesList");
			varList.removeAttribute("id");
			varList.innerHTML = this._extractCustomVariables(cp.customVariables);

			template.querySelector(".row").append(input);
			const senderInput = template.querySelector("input[data-name=\"SenderName\"]");
			senderInput.addEventListener("keypress", e => {
				if (!SENDERNAME_ALLOWED_CHARS_REGEXP.test(e.key)) {
					e.preventDefault();
				}
			});

			this.templateBoxTarget.append(template);

			// Adds Select Tag if templates is an attachment type
			if (cp.withAttachment === "true") {
				const typeVariable = cp.customVariables.Variables.find(v => v.VarName === AttachmentFileType);

				this._hideAttachmentsRows(varList, cp.customVariables.TemplateID);
				this._insertAttachmentSelect(varList, typeVariable.DefaultValue, cp.customVariables.TemplateID);
			}
		});

		this._choicesInstance.getValue().forEach(element => element.active = false);
		this.addButtonTarget.disabled = true;

		// check if it has errors
		if (this._choicesInstance.containerOuter.element.parentElement.classList.contains("has-error")) {
			this._choicesInstance.containerOuter.element.parentElement.classList.remove("has-error");
		}

		this._reorderIndexes();
		this._choicesInstance.unhighlightAll();
	}

	loadPreview(event) {
		let target;
		if (event.target.nodeName === "I") {
			target = event.target.parentElement;
		} else {
			target = event.target;
		}

		const template          = this._choicesInstance._currentState.items.find(choice => choice.choiceId === parseInt(target.dataset.choiceid));
		const subjectInput      = document.querySelector(`input[name="subject.${template.value}"]`);
		const senderNameInput   = document.querySelector(`input[name="SenderName.${template.value}"]`);
		const box               = target.parentElement.parentElement.parentElement;
		const requestPreviewURL = new URL(`/templates/${template.value}/request_preview/`, window.location.origin);

		if (this.landingPageSwitchTarget.checked) {
			requestPreviewURL.searchParams.append("use_landing_page", true);
		}

		document.getElementById("TemplateModalName").innerText    = template.label;
		document.getElementById("TemplateModalSubject").innerText = subjectInput.value;
		document.getElementById("TemplateModalSender").innerText  = senderNameInput.value;

		fetch(requestPreviewURL)
			.then(response => response.json())
			.then(response => {
				const emailiFrame                                             = document.getElementById("EmailTemplatePreview").contentWindow.document.body;
				const landingiFrame                                           = document.getElementById("LandingTemplatePreview").contentWindow.document.body;
				document.getElementById("TemplateModalDescription").innerText = response.description;

				emailiFrame.innerHTML   = this._replaceCustomVariables(box, response.html);
				landingiFrame.innerHTML = this._replaceCustomVariables(box, response.landing_page);
			});
	}

	removeTemplate(evt) {
		const card = evt.target.parentElement.parentElement.parentElement.parentElement.parentElement;
		card.remove();

		const choice  = this._choicesInstance._currentState.items.find(choice => choice.choiceId === parseInt(evt.target.dataset.choiceid));
		choice.active = true;
		this._choicesInstance.removeActiveItemsByValue(choice.value);
		this._choicesInstance.config.maxItemCount = this._choicesInstance.config.maxItemCount + 1;

		this._reorderIndexes();
	}

	toggleButton(event) {
		event.target.length > 0 ? this.addButtonTarget.disabled = false : this.addButtonTarget.disabled = true;
	}

	toggleDynamicVariables(event) {
		const target = event.target;
		let parentNode, iconNode;

		if (target.nodeName === "I") {
			parentNode = target.parentElement.parentElement.parentElement;
			iconNode   = target;
		} else {
			parentNode = target.parentElement.parentElement;
			iconNode   = target.firstElementChild;
		}

		const variableList = parentNode.previousElementSibling.firstElementChild;
		const varList      = $(variableList);
		const arrow        = $(iconNode);

		if (arrow.hasClass("arrow-down")) {
			arrow.removeClass("arrow-down");
			varList.slideDown();
			target.lastChild.nodeValue = "Hide Custom Variables";
		} else if (!arrow.hasClass("arrow-down")) {
			arrow.addClass("arrow-down");
			varList.slideUp();
			target.lastChild.nodeValue = "Show Custom Variables";
		}
	}

	_checkTemplatesSelected() {
		if (this._customVariables) {
			this._customVariables.forEach(template => {
				let variables = template.Variables.map(variable => {
					return {
						"DefaultValue": variable.DefaultValue,
						"VarName": variable.VarName,
					};
				});

				this._choicesInstance._currentState.items.forEach(choice => {
					if (choice.value === template.TemplateID) {
						choice.customProperties.customVariables.Variables = variables;
						this.addTemplates();
					}
				});
			});
		} else {
			if (this._choicesInstance.getValue().length > 0) {
				this._choicesInstance.getValue().forEach(choice => {
					fetch(`/templates/${choice.value}/request_preview?campaign_id=${choice.customProperties.campaignid}`)
						.then(response => response.json())
						.then(response => {
							const variables = [];
							response.Variables.forEach(variable => variables.push({
								"DefaultValue": variable.value,
								"VarName": variable.name,
							}));
							choice.customProperties.customVariables.Variables = variables;
							this.addTemplates();
						});
				});
			}
		}
	}

	_createHiddenInput(ID, index) {
		const input = document.createElement("input");
		input.type  = "hidden";
		input.value = ID;
		input.name  = `Templates[${index}].ID`;

		return input;
	}

	_extractCustomVariables(customVariables) {
		let html = [];
		const ID = customVariables.TemplateID;

		customVariables.Variables.forEach(variable => {
			let label          = variable.VarName;
			const isSenderName = variable.VarName === "SenderName";

			if (variable.VarName === "subject") {
				label = "Email Subject";
			}

			if (isSenderName) {
				label = "Sender Name";
			}

			const row = `
            <div class='form-row flex align-items-end m-b-10'>
                <div class='col-4'>
                    <label>${label}:</label>
                </div>
                <div class='col-8'>
                    <input class='form-control' type='text' maxlength='${isSenderName ? 64 : 200}' data-name='${variable.VarName}' name='${variable.VarName}.${ID}' placeholder='Value for ${variable.VarName} variable' value='${variable.DefaultValue}'>
                </div>
            </div>
            `;
			html.push(row);
		});

		return html.join("");
	}

	_hideAttachmentsRows(container, templateID) {
		const attachmentFields = [AttachmentFileType, AttachmentIconPath];

		attachmentFields.forEach(field => {
			const fti     = container.querySelector(`input[name="${field}.${templateID}"]`);
			const row     = fti.parentElement.parentElement;
			row.classList = "";
			row.classList.add("d-none");
		});
	}

	_initTemplateObserver() {
		const mutationObserver = new MutationObserver(() => {
			const templatesCount = this.templateBoxTarget.childElementCount;
			const button         = document.getElementById("FinishTemplateStep");

			this.countTarget.innerText = templatesCount;
			templatesCount > 0 ? (this.emptyBoxTarget.classList.add("d-none"), button.classList.remove("d-none")) : (this.emptyBoxTarget.classList.remove("d-none"), button.classList.add("d-none"));
			templatesCount === 5 ? (
				this._choicesInstance.input.element.placeholder = this._choicesInstance.config.maxItemText(),
					this._choicesInstance.containerOuter.isDisabled = true,
					this._choicesInstance.input.element.disabled = true,
					this._choicesInstance.containerOuter.element.classList.add("choices--disabled")
			) : (
				this._choicesInstance.input.element.placeholder = "Add Templates",
					this._choicesInstance.containerOuter.isDisabled = false,
					this._choicesInstance.input.element.disabled = false,
					this._choicesInstance.containerOuter.element.classList.remove("choices--disabled")
			);
		});

		mutationObserver.observe(this.templateBoxTarget, {
			attributes: false,
			childList: true,
		});
	}

	_insertAttachmentSelect(container, fileType, templateID) {
		const sName   = `${AttachmentFileType}.${templateID}`;
		const sID     = `${AttachmentFileType}-${templateID}`;
		const options = Array.from(ATTACHMENT_FILE_TYPES).map(m => {
			const type = m[0];
			return `<option value='${type}' ${type === fileType ? "selected" : ""}>${ATTACHMENT_FILE_TYPES.get(type)}</option>`;
		});

		const sTag = `<div class='form-row flex align-items-end m-b-10'>
            <div class='col-4'>
                <label>File Type:</label>
            </div>
            <div class='col-8 blank-choices'>
                <select id='${sID}'>
                    ${options.join("")}
                </select>
            </div>
        </div>`;

		container.insertAdjacentHTML("beforeend", sTag); // inserts the new select

		const c = new Choices(`#${sID}`); // creates a new choices instance for the attachment type selection
		c.passedElement.element.addEventListener("addItem", e => {
			const fti = container.querySelector(`input[name="${sName}"]`); // retrieves the original filetype input
			const aip = container.querySelector(`input[name="${AttachmentIconPath}.${templateID}"]`); // retrieves the original iconPath input

			aip.value = aip.value.replace(/\w{3,4}\.png/g, `${e.detail.value}.png`); // update the attachment icon
			fti.value = e.detail.value; // Updates the hidden input with the selected value
		}, false);
	}

	_isAttachmentIcon(variableName) {
		return variableName.includes("IconPath");
	}

	_loadCustomVariables() {
		defaultCustomVariables.forEach(template => {
			this._choicesInstance._currentState.choices.forEach(choice => {
				if (template.TemplateID === choice.value) {
					choice.customProperties["customVariables"] = template;
				}
			});
		});

		delete this.element.dataset["helpers-TargetTemplatesUrl"];
		document.getElementById("CustomVariableScript").remove();
		this._checkTemplatesSelected();
	}

	_replaceCustomVariables(list, html) {
		const inputs   = list.querySelectorAll("input");
		const myRegexp = /\{{2}(.*?)\|.*?}{2}/g;
		const matches  = (html.match(myRegexp) || []);
		const groups   = matches.map(e => e.replace(myRegexp, "$1"));

		matches.forEach((match, index) => {
			const input = Array.from(inputs).filter(input => input.dataset.name === groups[index].trim());
			if (this._isAttachmentIcon(match)) {
				html = html.replace(`${match}`, `${input[0].value}`);
				return;
			}

			html = html.replace(`${match}`, `<mark>${input[0].value}</mark>`);
		});

		return html;
	}

	_reorderIndexes() {
		const inputs = this.templateBoxTarget.querySelectorAll("input[type=\"hidden\"]");
		Array.from(inputs).forEach((input, index) => {
			input.name = `Templates[${index}].ID`;
		});
	}
}
