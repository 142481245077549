import { Controller } from "@hotwired/stimulus";

const appCSS                  = [];
const DEFAULT_EDITOR_SETTINGS = {
    selector: ".tinymce-editor",
    height: 650,
    menubar: false,
    content_css: appCSS,
    extended_valid_elements: "*[*]",
    toolbar: [
        {
            name: "styles", items: ["styleselect"],
        },
        {
            name: "formatting", items: ["bold", "italic"],
        },
        {
            name: "alignment", items: ["alignleft", "aligncenter", "alignright", "alignjustify"],
        },
        {
            name: "indentation", items: ["outdent", "indent"],
        },
        {
            name: "plugins", items: ["image"],
        },
    ],
    plugins: "image",
    // Image Plugin Specific
    image_uploadtab: true,
    images_upload_url: "/communications_template/image_upload/",
    images_upload_handler: uploadImages,
};

export default class ReportEditorController extends Controller {
    static values = {
        settings: Object,
    };

    /**
     * returns the editor settings.
     * @return {Object} The editor settings.
     */
    get settings() {
        if (this.hasSettingsValue) {
            return this.settingsValue;
        }

        return DEFAULT_EDITOR_SETTINGS;
    }

    /**
     * sets the editor settings.
     * @param {Object} settings - The editor settings.
     */
    set settings(settings) {
        this.settingsValue = settings;
    }

    initialize() {
        this.element[this.identifier] = this;
    }

    connect() {
        this.mce = [];

        Promise.resolve().then(() => {
            const linkTags = document.querySelectorAll("link[rel=\"stylesheet\"]");

            Array.from(linkTags).forEach(tag => {
                const href = tag.getAttribute("href");
                appCSS.push(href);
            });

            tinymce.init(this.settings).then(editors => {
                editors.forEach(editor => {
                    this.mce.push(editor);
                    convertChartsToImage(editor.getDoc());
                });
            });
        });
    }

    disconnect() {
        tinymce.remove();
    }
}

function convertChartsToImage(editorContent) {
    const svgCharts = editorContent.querySelectorAll("svg");

    Array.from(svgCharts).forEach(chart => {
        const svgData = new XMLSerializer().serializeToString(chart);
        const canvas  = document.createElement("canvas");
        const ctx     = canvas.getContext("2d");
        const img     = document.createElement("img");

        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));

        img.onload = function () {
            canvas.width  = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);

            // Now is done
            chart.parentElement.insertAdjacentHTML("beforeend", `<p style='text-align: center;'><img src='${canvas.toDataURL()}'/></p>`);
            chart.remove();
        };
    });
}

function uploadImages(blobInfo, success, failure, progress) {
    var xhr, formData;

    xhr                 = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", "/communications_template/image_upload/", true);

    xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
    };

    xhr.onload = function () {
        if (xhr.status < 200 || xhr.status >= 300) {
            failure("HTTP Error: " + xhr.status);
            return;
        }

        const url = `${location.origin}/storage/tmp/${blobInfo.filename()}`;

        success(url);
    };

    xhr.onerror = function () {
        failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
    };

    formData    = new FormData();
    const token = document.querySelector("meta[name=csrf-token]").attributes.content.textContent;
    const param = document.querySelector("meta[name=csrf-param]").attributes.content.textContent;

    formData.append(param, token);
    formData.append("Content-Type", "multipart/form-data");
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
}
