import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["cardToken", "cardDefault", "cardForm", "cardFormToggler", "brandIcon", "submitForm", "errorMessage", "errorIcon"]

    initialize() {
        this._stripeInstance = window.customerID;
        this._stripeElement = this._stripeInstance.elements({
            locale: 'en'
        });

        this._mountStripeElements()
        this._registerElements()
    }

    showCardForm() {
        $(this.cardFormTarget).slideDown()
        $(this.cardFormTogglerTarget).slideUp()
    }

    hideCardForm() {
        $(this.cardFormTarget).slideUp()
        $(this.cardFormTogglerTarget).slideDown()
    }

    addNewCard(event) {
        event.preventDefault();
        event.target.disabled = true;

        const cardName = document.getElementById("card-name")
        const additionalData = {
            name: cardName ? cardName.value : undefined,
        }

        this._stripe.createToken(this._stripeElements[0], additionalData).then(result => {
            event.target.disabled = false;

            if (result.error) {
                this.errorIconTarget.classList.add('visible');
                this.errorMessageTarget.innerText = "Your card was declined";
                return;
            }

            this.cardTokenTarget.value = result.token.id;

            document.getElementById("billing-form").submit()
        });
    }

    _mountStripeElements() {
        const cardNumber = this._createElement('cardNumber');
        const cardExpiry = this._createElement('cardExpiry')
        const cardCvc = this._createElement('cardCvc')

        cardNumber.mount("#card-number");
        cardExpiry.mount('#card-expiry');
        cardCvc.mount('#card-cvc');

        this._stripeElements = [cardNumber, cardExpiry, cardCvc]
    }

    _createElement(elementType) {
        return this._stripeElement.create(elementType, {
            style: this._stripeStyles,
            classes: this._stripeClasses,
        });
    }

    _registerElements() {
        const controller = this;
        const submitButton = this.submitFormTarget;
        const error = this.errorIconTarget;
        const errorMessage = this.errorMessageTarget;
        let savedErrors = {};

        this._stripeElements.forEach(function (element, idx) {
            element.on('change', function (event) {
                if (element._componentName == 'cardNumber' && event.brand) {
                    controller._setBrandIcon(event.brand);
                }
                if (event.error) {
                    submitButton.disabled = true;
                    error.classList.add('visible');
                    savedErrors[idx] = event.error.message;
                    errorMessage.innerText = event.error.message;
                } else {
                    savedErrors[idx] = null;

                    let nextError = Object.keys(savedErrors).sort().reduce(function (maybeFoundError, key) {
                        return maybeFoundError || savedErrors[key];
                    }, null);

                    if (nextError) {
                        errorMessage.innerText = nextError;
                    } else {
                        submitButton.disabled = false;
                        error.classList.remove('visible');
                        errorMessage.innerText = '';
                    }
                }
            });
        });
    }

    _setBrandIcon(brand) {
        let brandIconElement = this.brandIconTarget;
        let faType = 'fas';
        let faClass = 'fa-credit-card';

        if (brand in this._cardBrandToFaClass) {
            faType = 'fab';
            faClass = this._cardBrandToFaClass[brand];
        }
        for (let i = brandIconElement.classList.length - 1; i >= 0; i--) {
            brandIconElement.classList.remove(brandIconElement.classList[i]);
        }

        brandIconElement.classList.add(faType, faClass);
    }

    _toggleInputStatus(status) {
        const inputs = this.cardFormTarget.querySelectorAll("input");

        for (const input of inputs) {
            input.setAttribute('disabled', status);
        }
    }

    get _stripeInstance() {
        return this._stripe
    }

    set _stripeInstance(apiKey) {
        this._stripe = Stripe(apiKey)
    }

    get _stripeElement() {
        return this._element
    }

    set _stripeElement(element) {
        this._element = element
    }

    get _stripeElements() {
        return this._elements
    }

    set _stripeElements(elements) {
        this._elements = elements
    }

    get _stripeStyles() {
        return {
            base: {
                fontSize: '14px',
                fontFamily: 'Montserrat, sans-serif',
                color: '#67757C',
                fontWeight: 400,
                fontSmoothing: 'antialiased',

                ':focus': {
                    color: '#67757C',
                },

                '::placeholder': {
                    color: '#99A0A6',
                },

                ':focus::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#DC3545',
                ':focus': {
                    color: '#FA755A',
                },
                '::placeholder': {
                    color: '#DC3545',
                },
            },
        }
    }

    get _stripeClasses() {
        return {
            focus: 'focus',
            empty: 'empty',
            invalid: 'invalid',
        }
    }

    get _cardBrandToFaClass() {
        return {
            visa: 'fa-cc-visa',
            mastercard: 'fa-cc-mastercard',
            amex: 'fa-cc-amex',
            discover: 'fa-cc-discover',
            diners: 'fa-cc-diners-club',
            jcb: 'fa-cc-jcb',
        }
    }
}