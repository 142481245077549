import * as htmx from "htmx.org";

import "bootstrap/dist/js/bootstrap.bundle";
import "./external/trix";
import "./bootbox";
import "./hyperscript";

import Choices from "choices.js";
import {Application} from "@hotwired/stimulus";
import {definitionsFromContext} from "@hotwired/stimulus-webpack-helpers";

import select2js from "./models/select2.js";
import {Tabs} from "tailwindcss-stimulus-components";

// Importing all Stimulus Controllers
const application = Application.start();

application.register("tabs", Tabs);

const context = require.context("./controllers", true, /\_controller.js$/);
application.load(definitionsFromContext(context));

$("[data-toggle=\"tooltip\"]").tooltip({
    html: true,
});

// Overwriting HTMX default behavior for 4xx error responses.
htmx.on("htmx:beforeSwap", function (evt) {
    switch (evt.detail.xhr.status) {
        case 422:
            // allow 422 responses to swap as we are using this as a signal that
            // a form was submitted with bad data and want to re-render with the
            // errors.
            evt.detail.shouldSwap = true;
        case 500:
            // Allowing 500 error to render flash banners.
            evt.detail.shouldSwap = true;
    }
});

// Adding CSRF Token for all non-GET requests
document.body.addEventListener("htmx:configRequest", function (evt) {
    if (evt.detail.verb !== "get") {
        evt.detail.headers["X-CSRF-Token"] = document.querySelector("meta[name=csrf-token]").attributes.content.textContent;
    }
});

// Backward compatibility for the data-actions used by 'jquery-ujs' library
// document.querySelectorAll("a[data-confirm], a[data-name=\"bulk-confirm\"],a[data-name=\"bulk-prompt\"]").forEach(link => {
// 	link.addEventListener("click", function (evt) {
// 		evt.preventDefault();
// 		// Retrieving modal StimulusJS controller
// 		const controller = document.body["components--modal"];
// 		controller.openConfirm(evt);
// 	});
// });
//
// // Backward compatibility for the data-confirm-cancel used by 'jquery-ujs' library
// document.querySelectorAll("a.confirm-cancel").forEach(link => {
// 	link.addEventListener("click", function (evt) {
// 		evt.preventDefault();
// 		// Retrieving modal StimulusJS controller
// 		const controller = document.body["components--modal"];
// 		controller.openCancel(evt);
// 	});
// });

$(() => {
    // Alerts dissmiss after 10 seconds
    $(".alert-fade").not(".alert-danger").delay(10000).fadeOut(500, function () {
        $(".alert-fade").fadeOut(500);
        $(this).remove();
    });

    // Initialize Clipboard Button
    clipboard();

    // Making radio buttons works as tab toggler
    $("label[data-toggle=\"tab\"]").on("shown.bs.tab", function (e) {
        $("input").removeAttr("checked").removeProp("checked");
        $(this).find("input").attr("checked", "checked").prop("checked", "checked");
    });

    // Categories Select2
    select2js.init($(".role-select"));

    Array.from(document.querySelectorAll("input.choices:not([data-controller='choices']), select.choices:not([data-controller='choices'])")).map(element => {
        if (element.classList.contains("drilldown-choices")) {
            let type = element.getAttribute("data-elements-name");
            let noChoicesText = element.getAttribute("data-no-choices-text");

            if (noChoicesText === undefined) {
                noChoicesText = `No more ${type} to filter by`;
            }

            new Choices(element, {
                removeItemButton: true,
                placeholder: true,
                placeholderValue: element.getAttribute("data-choices-placeholder-value"),
                maxItemCount: element.getAttribute("data-max-selection"),
                resetScrollPosition: false,
                shouldSortItems: true,
                maxItemText: (maxItemCount) => {
                    let maxItemPlaceholder = element.getAttribute("data-max-item-placeholder");
                    if (maxItemPlaceholder === undefined) {
                        maxItemPlaceholder = `Max ${maxItemCount} ${type} can be used for filtering`;
                    }

                    return maxItemPlaceholder;
                },
                noChoicesText: noChoicesText,
            });
        } else {
            new Choices(element, {
                shouldSort: false,
            });
        }
    });

});

function clipboard() {
    document.querySelectorAll("button.copy-link").forEach(btn => {
        const $btn = $(btn);
        const input = btn.parentElement.parentElement.querySelector("input");
        $btn.tooltip({
            action: "show",
            title: "Copied",
            placement: "top",
            trigger: "manual",
        });

        btn.addEventListener("click", () => {
            input.select();
            document.execCommand("copy");
            input.blur();

            $btn.tooltip("show");
            setTimeout(() => $btn.tooltip("hide"), 1500);
        });
    });
}

// Assets Loading State
const iframeEle = document.getElementById("iframe");
const loadingEle = document.getElementById("loading");

if (iframeEle) {
    iframeEle.addEventListener("load", function () {
        loadingEle.style.display = "none";
        iframeEle.style.opacity = 1;
    });
} else if (loadingEle) {
    loadingEle.style.display = "none";
}
