import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "button"];

    connect() {
    }

    /**
     * change the current input element selected after typing
     * @param evt {Event} the event received.
     */
    moveAndFocus(evt) {
        const target   = evt.target;
        const isNumber = /^[0-9]$/.test(evt.key);
        const isDelete = evt.key === "Backspace" || evt.key === "Delete";

        if (isDelete && target.previousElementSibling !== null) {
            target.previousElementSibling.focus();
        }

        if (isNumber && parseInt(target.value) > -1 && target.nextElementSibling !== null) {
            target.nextElementSibling.focus();
        }
    }

    /**
     Grab the code from the clipboard and put it into the inputs
     @param evt {Event} the event received.
     */
    pasteCode(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const text = evt.clipboardData.getData("text");

        if (!new RegExp(`^[0-9]{${this.inputTargets.length}}$`).test(text)) {
            return;
        }

        const digits = text.split("");
        this.inputTargets.forEach((input, index) => input.value = digits[index]);
        this.inputTargets[this.inputTargets.length - 1].focus(); // Focusing the last input after paste

        // Submit after paste
        this.submit();
    }

    /**
     * Select the input value when the input has been focused
     * @param target {HTMLInputElement}
     */
    select({target}) {
        target.select();
    }

    submit() {
        this.buttonTarget.disabled = true;
        this.buttonTarget.querySelector("img").classList.add("!inline");
        this.element.submit();
    }

    /**
     * Validate that the inputs are number of actions button like backspace or delete
     * and prevent the action from happening if not met.
     * If all inputs are filled, the submit button will activate.
     * @param evt {Event}
     */
    validate(evt) {
        if (
            !/^[0-9]$/.test(evt.key)
            && evt.key !== "Backspace"
            && evt.key !== "Delete"
            && evt.key !== "Tab"
            && !evt.metaKey
        ) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        const valid = Array.from(this.inputTargets).every(input => input.value !== "");
        valid ? this.buttonTarget.disabled = false : this.buttonTarget.disabled = true;
    }
}
