import {Controller} from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
    static values = {
        admins: Array,
    };

    connect() {
        const admins = this.adminsValue;

        // Removing dataset from HTML
        this.element.removeAttribute("data-targets--boardroom-recipients-admins-value");

        new Tagify(this.element, {
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            tagTextProp: "value", // very important since a custom template is used with this property as text
            whitelist: admins,
            dropdown: {
                closeOnSelect: false,
                enabled: 0, // always opens dropdown when input gets focus
                searchKeys: ["name", "email"],  // very important to set by which keys to search for suggesttions when typing
            },
            templates: {
                dropdownItem: suggestionItemTemplate,
            },
            editTags: false,
            maxTags: 10,
            skipInvalid: true,
            originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(","),
        });
    }
}

function suggestionItemTemplate(item) {
    return `<div ${this.getAttributes(item)} class='${this.settings.classNames.dropdownItem}' tabindex='0' role='option'>${item.name} (${item.value})</div>`;
}
