import { Controller } from "@hotwired/stimulus";

/** Class representing a custom variable. */
class CustomVariable {
	/**
	 * Create a custom variable.
	 * @param {string} name - The name of the custom variable.
	 * @param {string} value - The value of the custom variable.
	 */
	constructor(name, value) {
		this.name  = name;
		this.value = value;
	}
}

export default class extends Controller {
	static targets = ["name", "value", "iframe", "template", "sender", "subject"];
	static values  = {
		count: Number,
		campaign: String,
		showLandingPage: Boolean,
	};

	connect() {
	}

	loadPreview(event) {
		const requestPreviewURL = new URL(`/templates/${event.target.id}/request_preview/`, window.location.origin);
		requestPreviewURL.searchParams.append("campaign_id", this.campaignValue);

		if (this.showLandingPageValue) {
			requestPreviewURL.searchParams.append("use_landing_page", true);
		}

		fetch(requestPreviewURL)
			.then(response => response.json())
			.then(response => {
				const emailiFrame                                             = document.getElementById("EmailTemplatePreview").contentWindow.document.body;
				const landingiFrame                                           = document.getElementById("LandingTemplatePreview").contentWindow.document.body;
				document.getElementById("TemplateModalName").innerText        = response.Template.name;
				document.getElementById("TemplateModalDescription").innerText = response.Template.description;
				document.getElementById("TemplateModalSubject").innerText     = response.Variables[0].value;
				document.getElementById("TemplateModalSender").innerText      = response.Variables[1].value;

				emailiFrame.innerHTML   = this._replaceCustomVariables(response.Variables, response.Template.html);
				landingiFrame.innerHTML = this._replaceCustomVariables(response.Variables, response.Template.landing_page);
			});
	}

	showEmailPreview() {
		const variables = [];

		for (let index = 0; index < this.countValue; index++) {
			variables.push(new CustomVariable(this.nameTargets[index].value, this.valueTargets[index].value));
		}

		this.iframeTarget.contentWindow.document.body.innerHTML = this._replaceCustomVariables(variables, this.templateTarget.content.textContent);
	}

	_replaceCustomVariables(variables, html) {
		if (this.hasSubjectTarget) {
			this.subjectTarget.innerText = variables.find(v => v.name === "subject").value;
		}

		if (this.hasSenderTarget) {
			this.senderTarget.innerText = variables.find(v => v.name === "SenderName").value;
		}

		const matchs = html.match(/\{{2}(.*?)}{2}/g);
		if (!matchs) return html;

		matchs.forEach(m => {
			const varName = m.replace(/[{]/g, "").split("|")[0].trim();
			const cusvar  = variables.find(v => v.name === varName);

			if (cusvar !== undefined) {
				if (this._isAttachmentIcon(m)) {
					html = html.replace(m, `${cusvar.value}`);
					return;
				}

				html = html.replace(m, `<mark>${cusvar.value}</mark>`);
			}
		});

		return html;
	}

	_isAttachmentIcon(variableName) {
		return variableName.includes("IconPath");
	}
}
