import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "userCount",
        "form"
    ]

    setModalData(e) {
        this.userCountTarget.innerHTML = e.target.dataset.userCount;
        this.formTarget.action = e.target.dataset.formAction;
    }
}