import { Controller } from "@hotwired/stimulus"

const RISK_STATUS_CACHE = "user-risk-status";

export default class extends Controller {
    static targets = [];
    static values = {
        type: String,
        url: String
    }

    initialize() {
        this.element[this.identifier] = this;

        const func = this[this.typeValue];
        func(this)
    }

    update() {
        const func = this[this.typeValue];
        func(this)
    }

    riskStatus(controller) {
        caches.open(RISK_STATUS_CACHE)
            .then(function (cache) {
                const path = controller.urlValue + "risk-status/";
                const call = () => {
                    return fetch(path)
                        .then(response => {
                            if (!response.ok) {
                                throw response
                            }

                            if (response.redirected) {
                                throw Promise.reject("redirection not permitted")
                            }

                            cache.put(path, response.clone());
                            return response.text();
                        })
                        .catch(err => {
                            controller.element.innerHTML = "-";
                            return Promise.reject(err.statusText)
                        });
                }

                // Validating if exists cache exists.
                return cache.match(path)
                    .then(function (response) {
                        // If there is an entry in the cache we grab it.
                        if (response) {
                            // Validate cache time if is older than 1 hour
                            const date = new Date(response.headers.get('date'))
                            if (Date.now() < date.getTime() + 1000 * 60 * 60 * 1) {
                                // If still valid, we just return it.
                                return response.text();
                            }

                            // Otherwise, call the application to retrieve fresh information.
                            return call();
                        }

                        // Otherwise, call the application to retrieve the information.
                        return call();
                    })
                    .then(status => {
                        const statusHTML = `<span class="risk-score risk-score-${controller.dasherize(status)} ">${status}</span>`;
                        controller.element.innerHTML = statusHTML;
                    })

            })
    }

    dasherize(text) {
        return text.replace(/^([A-Z])|[\s\._](\w)/g, function (match, p1, p2, offset) {
            if (p2) return "-" + p2.toLowerCase();
            return p1.toLowerCase();
        });
    }
}