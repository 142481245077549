import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["provider", "providerInput"]

    connect() { 
        const providers = this.providerTargets

        providers.forEach((provider) => {
            this.initEvents(provider)
        })
    }

    initEvents(target) {
        target.addEventListener('click', (event) => {
            this.providerInputTarget.value = event.target.dataset.provider
        })
    }
}