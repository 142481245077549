import { Controller } from "@hotwired/stimulus";
import mflatpickr from "../models/flatpickr";
import flatpickr from "flatpickr/dist/flatpickr";
import Choices from "choices.js";

const custom = "custom";

// Strategy Values
const strategyAsap   = "immediately";
const strategyAtOnce = "SPECIFIC";

export default class extends Controller {
	static targets = [
		"longCampaign", "selectStrategy", "trainingDay",
		"selectDuration", "fromDuration", "untilDuration", "untilDurationBox",
		"selectPeriod", "timePeriodBox", "period",
		"weekdays", "selectWeekdays", "weekDaysBox",
		"selectCompanies", "selectUsers", "validateInput",
		"userCount",
	];

	static values = {
		users: Number,
		companies: Number,
	};

	get _timePeriod() {
		return this.selectPeriodTargets.filter(input => input.checked)[0];
	}

	get _userSelection() {
		return this.selectUsersTargets.filter(input => input.checked)[0];
	}

	get _selectedWeekdays() {
		let selectedWeekDays = this.weekdaysTargets.filter(input => input.checked);

		if (this._weekdays.value === "weekly") {
			selectedWeekDays = this.weekdaysTargets;
		}

		return selectedWeekDays;
	}

	get _scheduleResume() {
		if (this.hasLongCampaignTarget) {
			this._applyLongCampaignResume();
		} else {
			this._applyShortCampaignResume();
		}

		return this.data.get("_scheduleResume");
	}

	set _scheduleResume(content) {
		this.data.set("_scheduleResume", content);
	}

	get _weekdays() {
		return this.selectWeekdaysTargets.filter(input => input.checked)[0];
	}

	initialize() {
		this.element[this.identifier] = this;

		if (this.hasLongCampaignTarget) {
			this._initLongCampaignInputs();
			this.toggleTrainingAlert();
		} else {
			this._initShortCampaignInputs();
		}

		this._userCount = document.getElementById("CompanySelectedUsers");

		if (this.selectDurationTarget.value === custom) {
			this._toggleElement(this.untilDurationBoxTarget);
		}

		if (this._weekdays.value === custom) {
			this.toggleWeekdays();
		}

		if (this.hasSelectCompaniesTarget) {
			this._initCompanyTableObserver();
		}

		if (this.hasSelectUsersTarget) {
			this.toggleTargetUsers();
			this._initUserTableObserver();
		}

		if (this._timePeriod.value === custom) {
			this.togglePeriod();
		}

		this._checkFulfillment();
	}

	finishSchedule(event) {
		if (!this._valid()) {
			event.stopImmediatePropagation();
			return;
		}

		document.getElementById("schedule-result").querySelector(".col-md-9").innerHTML = this._scheduleResume;
	}

	finishCompany() {
		document.getElementById("CompanyResult").querySelector(".col-md-9").innerHTML = `<p><span class='font-strong'>${this.companiesValue}</span> companies selected, with a total of <span class='font-strong'>${this.usersValue}</span> users.</p>`;
	}

	finishCurriculum(event) {
		const targetCurriculumElement = this.element.querySelector("[data-controller~=longcampaigns--target-curriculum]");
		const curriculumController    = targetCurriculumElement["longcampaigns--target-curriculum"];

		if (!curriculumController.valid) {
			event.stopImmediatePropagation();
			return;
		}

		let tCount = 0;
		let cCount = 0;

		curriculumController.months.forEach(month => {
			cCount += month.assetsCount;
			tCount += month.templatesCount;
		});

		this.selectDurationTarget.value                                                  = "custom";
		document.getElementById("CurriculumResult").querySelector(".col-md-9").innerHTML = `
        <p>${tCount} Phishing Templates, ${cCount} Training Assets</p>
        `;
	}

	finishUser() {
		let usersCount;

		if (this._userSelection.value === "ALL") {
			usersCount = document.getElementById("AllUsersCount").innerText;
		}

		if (this._userSelection.value === "CUSTOM") {
			usersCount = document.querySelector("#CompanySelectedUsers").innerText;
		}

		document.getElementById("UserResult").querySelector(".col-md-9").innerHTML = `<p><span class='font-strong'>${usersCount}</span> users selected.</p>`;
	}

	finishTemplates() {
		const templateList = document.getElementById("TemplatesSelected");
		const templates    = Array.from(templateList.children).map(template => {
			const type = template.querySelector("#templateType").dataset.type;
			const name = template.querySelector("#templateName").innerText;
			let src, alt;
			if (type === "Private") {
				src = "/assets/images/padlock-black.svg";
				alt = "padlock";
			} else {
				src = "/assets/images/earth-green.svg";
				alt = "earth";
			}

			return `
            <div class='row templates-resume'>
                <div class='col-md-1 px-1 b-r'>
                    <div class='badge badge-pill badge-custom badge${type} m-r-10'>
                        <img class='test text-info' src='${src}' alt='${alt}'>
                        <span class='vm'>${type}</span>
                    </div>
                </div>
                <div class='col-md-4'>
                    <span class='m-b-0 font-16 text-secondary'>${name}</span>
                </div>
            </div>`;
		});

		const text      = `<p><span class='font-strong'>${templateList.childElementCount}</span> Templates selected</p>`;
		const resultBox = document.getElementById("TemplateResult");

		resultBox.querySelectorAll(".templates-resume").forEach(box => {
			const prev = box.previousElementSibling;
			if (prev.nodeName === "HR") {
				prev.remove();
			}

			box.remove();
		});

		resultBox.querySelector(".col-md-9").innerHTML = text;
		resultBox.insertAdjacentHTML("beforeend", templates.join(`<hr class='my-2'>`));

		// Edit Smart Training only if has active the training results
		const trainingResultBox       = document.getElementById("TrainingResult");
		const shouldEditSmartTraining = !trainingResultBox.classList.contains("d-none");

		if (shouldEditSmartTraining) {
			const editBtn = trainingResultBox.querySelector("button");
			editBtn.dispatchEvent(new Event("click"));
		}
	}

	finishTraining() {
		let text   = "";
		const rows = Array.from(document.getElementById("ConditionRows").children).filter(element => element.nodeName !== "HR");
		rows.forEach(row => {
			const [s1, s2, s3, _] = row.querySelectorAll("select");
			if (rows.length === 1 && s1.length === 0) {
				text = `<p><span class='font-strong'>0</span> Courses will be assigned.</p>`;
				return;
			}

			let course = "course";
			if (s1.length > 1) {
				course = "courses";
			}

			text += `<p><span class='font-strong'> - ${s1.length} ${course}</span> will be assigned after users <span class='font-strong'>${s2.textContent} ${s3.textContent}</span></p>`;
		});

		const resultBox                                = document.getElementById("TrainingResult");
		resultBox.querySelector(".col-md-9").innerHTML = text;
	}

	toggleDuration() {
		if (event.target.value === "custom") {
			this._toggleElement(this.untilDurationBoxTarget);
		} else {
			this.untilDurationBoxTarget.classList.add("d-none");
		}
	}

	togglePeriod() {
		this._toggleElement(this.timePeriodBoxTarget);
	}

	toggleWeekdays() {
		this._toggleElement(this.weekDaysBoxTarget);
	}

	toggleTargetUsers() {
		const userList     = document.getElementById("UsersList");
		const allusers     = document.getElementById("AllUsersSelected");
		const finishButton = document.getElementById("FinishUserStep");
		const table        = document.getElementById("userList");

		if (this._userSelection.value === "ALL") {
			userList.classList.add("d-none");
			table.classList.add("d-none");
			allusers.classList.remove("d-none");
			finishButton.classList.remove("d-none");
		}

		if (this._userSelection.value === "CUSTOM") {
			allusers.classList.add("d-none");
			finishButton.classList.add("d-none");
			userList.classList.remove("d-none");
			table.classList.remove("d-none");

			parseInt(this._userCount.innerText) > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
		}
	}

	saveDraft() {
		const form          = document.getElementById("CampaignForm");
		const currentAction = form.getAttribute("action");

		if (currentAction.includes("?save_as=draft")) {
			return;
		}

		form.setAttribute("action", currentAction + "?save_as=draft");
	}

	updateContentMonths() {
		Promise.resolve().then(() => {
			// Getting Long Campaign Content Months
			const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			const months     = this.element.querySelectorAll("div[data-controller~=\"longcampaigns--month\"]");
			if (months.length === 0) {
				return;
			}

			Array.from(months).forEach(month => {
				const controller   = month["longcampaigns--month"];
				const selectedDate = new Date(this.fromDurationTarget.value);
				const fom          = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
				const newDate      = new Date(fom.setMonth(selectedDate.getMonth() + controller.monthValue));

				controller.monthTarget.innerHTML = `${monthNames[newDate.getMonth()]} ${newDate.getFullYear()}`;
			});
		});
	}

	toggleTrainingAlert() {
		// Schedule Duration Time
		const timeOptions = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};

		const fromPeriod        = this._fromFPInstance.selectedDates[0].toLocaleDateString("en-US", timeOptions);
		let selectedTrainingDay = this.trainingDayTarget.options[this.trainingDayTarget.selectedIndex];

		let startDay  = new Date(fromPeriod).getUTCDate();
		let ShowAlert = "hidden";

		const elements = document.getElementsByClassName("training-alert");
		Array.from(elements).forEach(element => element.classList.add(ShowAlert));

		if (startDay > selectedTrainingDay.value) {
			Array.from(elements).forEach(element => element.classList.remove(ShowAlert));
		}
	}

	_applyShortCampaignResume() {
		if (this.selectStrategyTarget.value === strategyAsap) {
			this._scheduleResume = `<p>Your campaign has been scheduled to be sent <span class='font-strong'>As soon as possible</span></p>`;
			this._fromFPInstance.setDate(new Date(), true);
			this._untilFPInstance.setDate(new Date(), true);

			return;
		}

		// Schedule Period Time
		let fromHour  = document.getElementById("data-FromHour").innerText;
		let untilHour = document.getElementById("data-UntilHour").innerText;

		// Schedule Duration Time
		const timeOptions = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		};

		if (this.selectStrategyTarget.value === strategyAtOnce) {
			const fromPeriod = this._fromFPInstance.selectedDates[0].toLocaleDateString("en-US", timeOptions);

			this._scheduleResume = `
            <p>- Your campaign has been scheduled to be sent on: <span class='font-strong'>${fromPeriod}</span> at <span class='font-strong'>"${fromHour}"</span></p>
            <p>- Sending Strategy: <span class='font-strong'>${this.selectStrategyTarget.selectedOptions[0].text}</span></p>
            `;

			return;
		}

		const date = new Date(this._fromFPInstance.selectedDates[0]);
		if (this.selectDurationTarget.value === "1-week") {
			this._untilFPInstance.setDate(date.setDate(date.getDate() + 7), true);
		} else if (this.selectDurationTarget.value === "2-weeks") {
			this._untilFPInstance.setDate(date.setDate(date.getDate() + 14), true);
		} else if (this.selectDurationTarget.value === "1-month") {
			this._untilFPInstance.setDate(date.setDate(date.getDate() + 30), true);
		}

		const fromPeriod  = this._fromFPInstance.selectedDates[0].toLocaleDateString("en-US", timeOptions);
		const untilPeriod = this._untilFPInstance.selectedDates[0].toLocaleDateString("en-US", timeOptions);

		if (this._timePeriod.value === "24-hours") {
			fromHour  = "00:00 AM";
			untilHour = "23:59 PM";
		}

		let selectedWeekDays = this._selectedWeekdays.map(input => input.name.slice(0, 3)).join(", ");

		this._scheduleResume = `
        <p>- Your campaign has been scheduled from: <span class='font-strong'>${fromPeriod}</span> to <span class='font-strong'>${untilPeriod}</span></p>
        <p>- Phishing simulation emails will be delivered between <span class='font-strong'>${fromHour}</span> and <span class='font-strong'>${untilHour}</span> during the days: <span class='font-strong'>${selectedWeekDays}</span></p>
        <p>- Sending Strategy: <span class='font-strong'>${this.selectStrategyTarget.selectedOptions[0].text}</span></p>
        `;
	}

	_applyLongCampaignResume() {
		// Schedule Duration Time
		const timeOptions = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};

		const fromPeriod = this._fromFPInstance.selectedDates[0].toLocaleDateString("en-US", timeOptions);

		// Schedule Period Time
		let fromHour  = document.getElementById("campaign-FromHour")[0].innerText;
		let untilHour = document.getElementById("campaign-UntilHour")[0].innerText;

		if (this._timePeriod.value === "24-hours") {
			fromHour  = "00:00 AM";
			untilHour = "23:59 PM";
		}

		// Schedule Weekdays
		let selectedWeekDays    = this._selectedWeekdays.map(input => input.name.slice(0, 3)).join(", ");
		let selectedTrainingDay = this.trainingDayTarget.options[this.trainingDayTarget.selectedIndex];
		let startDay            = new Date(fromPeriod).getUTCDate();

		// Display Alert for Training
		let ShowAlert = "hidden";

		if (startDay > selectedTrainingDay.value) {
			ShowAlert = "";
		}

		this._scheduleResume = `
        <p>- Your campaign has been scheduled to be started on: <span class='font-strong'>${fromPeriod}</span></p>
        <p>- Phishing simulation emails will be delivered between <span class='font-strong'>${fromHour}</span> and <span class='font-strong'>${untilHour}</span> during the days: <span class='font-strong'>${selectedWeekDays}</span></p>
        <p>- Training Assets will be assigned the
            <span class='training-alert ${ShowAlert}' data-controller='components--tooltip' data-components--tooltip-text-value='Your campaign’s start day is greater than this day selected. So if your curriculum includes training on the first month they will go out as soon as the campaign starts.' data-action='mouseover->components--tooltip#show mouseout->components--tooltip#hide'>
                <i class='fas fa-info-circle text-warning'></i>
            </span>

            <span class='font-strong'>${selectedTrainingDay.text}</span>  of each month.
        </p>
        `;
	}

	_checkFulfillment() {
		const steps = document.getElementsByClassName("step");
		Array.from(steps).forEach(step => {
			if (step.classList.contains("completed")) {
				step.querySelector(".step-result").classList.remove("d-none");
				$(step.querySelector(".step-content")).slideUp();
			}
		});
	}

	_initCompanyTableObserver() {
		Promise.resolve().then(() => {
			const targetCompanies = document.getElementById("target-companies");
			const controller      = targetCompanies["target-companies"];
			const finishButton     = document.getElementById("FinishUserStep");

			targetCompanies.addEventListener("targetcompanies.change", _ => {
				this.usersValue     = controller.totalUsersCount;
				this.companiesValue = controller.totalCompaniesCount;
				this.usersValue > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
			});
		});
	}

	_initUserTableObserver() {
		const finishButton = document.getElementById("FinishUserStep");

		const mutationObserver = new MutationObserver(() => {
			parseInt(this._userCount.innerText) > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
		});

		mutationObserver.observe(this._userCount, {
			childList: true,
			subtree: true,
		});
	}

	_initShortCampaignInputs() {
		const $from  = $("#campaign-From");
		const $until = $("#campaign-Until");
		mflatpickr.initRange($from, $until);

		this._fromFPInstance  = document.getElementById("campaign-From")._flatpickr;
		this._untilFPInstance = document.getElementById("campaign-Until")._flatpickr;
	}

	_initLongCampaignInputs() {
		const inputDuration = this.fromDurationTarget;
		const date          = new Date();
		date.setHours(0, 0, 0, 0);

		let minDate = date.setDate(date.getDate());
		if (inputDuration.disabled) {
			minDate = 0;
		}

		this._fromFPInstance = flatpickr("#campaign-From", {
			minDate: minDate,
			enableTime: false,
			dateFormat: "m/d/Y",
		});

		new Choices(this.trainingDayTarget, {
			sortFn: (a, b) => (parseInt(b.value)) < (parseInt(a.value)) ? 1 : -1,
		});
	}

	_toggleElement(element) {
		element.classList.contains("d-none") ? element.classList.remove("d-none") : element.classList.add("d-none");
	}

	_valid() {
		const campaignName = this.validateInputTargets[0];
		const box          = this.weekDaysBoxTarget.firstElementChild;
		let valid          = true;

		if (box.lastElementChild.classList.contains("invalid-feedback")) {
			box.classList.remove("has-error");
			box.lastElementChild.remove();
		}

		if (!campaignName.checkValidity()) {
			campaignName.focus();
			valid = false;
		}

		if (this._weekdays.value === custom && this._selectedWeekdays.length === 0) {
			box.classList.add("has-error");
			this.weekDaysBoxTarget.firstElementChild.insertAdjacentHTML("beforeend", `<div class='invalid-feedback help-block font-normal'>You should select at least one day.</div>`);
			valid = false;
		}

		return valid;
	}
}
