import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import * as htmx from "htmx.org";

export default class TargetUsers extends Controller {
	static targets = ["select", "table", "addButton", "usersCount", "targetUsers", "targetCategories", "savedUsers"];
	static values  = {
		slug: String,
	};

	connect() {
		if (this.selectTarget.previousElementSibling.length !== 0) {
			this.selectTarget.previousElementSibling.remove();
		}

		this._init();
		this._setEvents();

		this.users      = JSON.parse(this.targetUsersTarget.value) || [];
		this.categories = JSON.parse(this.targetCategoriesTarget.value) || [];

		this.targetUsersTarget.remove();
		this.targetCategoriesTarget.remove();

		Promise.resolve().then(() => {
			this.__setUsersChoices();

			if (this._choicesInstance.getValue().length > 0) {
				this.addUsers();
			}
		});
	}

	addUsers() {
		// Create form
		const form = document.createElement("form");

		// Counters for users and categories.
		let uc = 0, cc = 0;

		if (this.hasTableTarget) {
			const data = this.tableTarget["datatable"].instance.data.data;

			data.forEach((row, index) => {
				const input = document.createElement("input");
				input.type  = "hidden";
				input.name  = `Targets.Users[${index}]`;

				row[0].data.forEach((nodes) => {
					if (nodes.nodeName === "INPUT") {
						input.value = nodes.attributes.value;

					}
				});

				form.appendChild(input);
			});
		}

		uc = form.childNodes.length;

		this._choicesInstance.getValue().forEach((choice) => {
			// Create input
			const input = document.createElement("input");
			input.type  = "hidden";
			input.value = choice.value;

			if (choice.customProperties.type === "category") {
				input.name = `Targets.Categories[${cc}]`;
				cc++;
			}

			if (choice.customProperties.type === "user") {
				input.name = `Targets.Users[${uc}]`;
				uc++;
			}

			form.appendChild(input);
		});

		this._choicesInstance.removeActiveItems();
		this.addButtonTarget.disabled = true;
		this._getUsers(form);
	}

	updateCount() {
		const count = this.tableTarget["datatable"].rows;

		if (count === 0) {
			this.element.classList.add("empty-table");
			this.tableTarget.classList.add("hidden");
		}

		this.usersCountTarget.innerText = count;
	}

	complete() {
		const data = this.tableTarget["datatable"].instance.data.data;

		this._choicesInstance.removeActiveItems();

		if (data.length === 0) {
			return;
		}

		let div = document.querySelector("#usersValues");
		if (div) {
			div.remove();
		}

		div = document.createElement("div");

		data.forEach((row, index) => {
			div.id = "usersValues";
			div.classList.add("hidden");

			const input = document.createElement("input");
			input.type  = "hidden";
			input.name  = `Users[${index}].ID`;

			row[0].data.forEach((data) => {
				if (data.nodeName === "INPUT") {
					input.value = data.attributes.value;

				}
			});

			div.appendChild(input);
		});

		document.querySelector("#target-users").appendChild(div);
	}

	toggleButton(event) {
		event.target.length > 0 ? this.addButtonTarget.disabled = false : this.addButtonTarget.disabled = true;
	}

	_init() {
		this._choicesInstance = new Choices(this.selectTarget, {
			removeItemButton: true,
			resetScrollPosition: false,
			maxItemCount: 20,
			searchFields: ["label", "value", "customProperties.preLabel"],
			callbackOnCreateTemplates: template => {
				return {
					choice: (classNames, data) => {
						return template(`
                            <div class='${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}' data-select-text='Press to select' data-choice ${data.disabled ? "data-choice-disabled aria-disabled=\"true\"" : "data-choice-selectable"} data-id='${data.id}' data-value='${data.value}' ${data.groupId > 0 ? "role=\"treeitem\"" : "role=\"option\""}>
                                ${this._getChoiceTemplate(data)}
                            </div>`);
					},
				};
			},
		});
	}

	__setUsersChoices() {
		const choices = [
			{
				label: "Categories",
				id: 1,
				disabled: false,
				choices: this.categories.map(category => {
					return {
						value: category.ID,
						label: category.Name,
						customProperties: {
							preLabel: category.Label,
							type: "category",
						},
					};
				}),
			},
			{
				label: "Users",
				id: 2,
				disabled: false,
				choices: this.users.map(user => {
					return {
						selected: user.Selected,
						value: user.ID,
						label: user.Name,
						customProperties: {
							preLabel: user.Label,
							type: "user",
						},
					};
				}),
			},
		];

		this._choicesInstance.setChoices(choices, "value", "label", false);
	}

	_getChoiceTemplate(data) {
		if (data.groupId === 1) {
			return `<i class='fas fa-tag'></i> ${data.customProperties.preLabel}`;
		}

		return `<i class='fas fa-user mr-1'></i> ${data.customProperties.preLabel}`;
	}

	_setEvents() {
		document.addEventListener("dtRowRemoved", () => {
			this.updateCount();
		});

		const finish = document.querySelector("#FinishUserStep");
		finish.addEventListener("click", () => {
			this.complete();
		});

		const edit = document.querySelector("#UserResult button");
		if (!edit) {
			return;
		}

		edit.addEventListener("click", () => {
			if (this.hasSavedUsersTarget && this.savedUsersTarget.children.length > 0) {
				const values = this.savedUsersTarget.querySelectorAll("input");

				this.savedUsersTarget.remove();

				const form = document.createElement("form");
				values.forEach((input, i) => {
					document.createElement("input");
					input.type = "hidden";
					input.name = `Targets.Users[${i}]`;

					form.appendChild(input);
				});

				this._getUsers(form);
			}
		});
	}

	_getUsers(form) {
		const spinner = document.querySelector("#userListSpinner");
		if (spinner) {
			spinner.classList.remove("hidden");
		}

		htmx.ajax("POST", `/${this.slugValue}/target-users`, {
			target: "#userList",
			values: htmx.values(form),
			swap: "innerHTML",
		}).then(() => {
			spinner.classList.add("hidden");
			this.updateCount();
		});

		this.element.classList.remove("empty-table");
	}
}
