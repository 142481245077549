require("select2/dist/js/select2")

const config = {
    multiple: true,
    placeholder: "Select Categories",
    tags: true,
    width: '100%',
    maximumInputLength: 30,
}

function init($select2Dom) {
    var $select2Instance = $select2Dom.select2(config);

    $select2Instance.one('select2:open', function (e) {
        selectOnTab(e);
    });

    $select2Instance.on('select2:select', function (e) {
        reorderSelection(e);
    });

    return $select2Instance
}

function reorderSelection(evt) {
    var $select2Object = $(evt.target)
    var element = evt.params.data.element;
    var $element = $(element);

    $element.detach();
    $select2Object.append($element);
    $select2Object.trigger("change");
}

function selectOnTab(evt) {
    var $selected_id_field = $(evt.target);
    var $select2Input = $selected_id_field.parent().find('.select2-selection__rendered');

    $select2Input.on('keydown', '.select2-search__field', function (e) {
        if (e.which === 9) {
            var highlighted = $('.select2-results__option--highlighted');

            if ($(this).val().length > 30) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (highlighted.length == 1) {
                var data = highlighted.data('data');
                var vals = $selected_id_field.val();

                if (vals === null) {
                    vals = [];
                }

                vals.push(data.id);
                $selected_id_field.val(vals).trigger("change")
                $selected_id_field.trigger({
                    type: 'select2:select',
                    params: {
                        data: data
                    }
                });
            }
        }
    });
}

module.exports = { init }