import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["content", "selectType", "filename"];

	get selectedType() {
		return this.selectTypeTarget.value;
	}

	initialize() {
		this.update();
		this.element.addEventListener("submit", evt => {
			const button    = document.getElementById("submitForm");
			button.disabled = true;

			if (this.isInvalid()) evt.preventDefault();
		});
	}

	isInvalid() {
		const validFormats = ["application/pdf", "text/html", "video/quicktime", "video/mp4", "video/x-msvideo"];
		const wrongFormat  = !validFormats.includes(this.contentTarget.files[0].type);
		const wrongSize    = this.selectedType === "Video" && this.contentTarget.files[0].size > 26214400;

		return wrongFormat || wrongSize;
	}

	update() {
		switch (this.selectedType) {
			case "File":
				this.contentTarget.accept = ".pdf, .html, application/pdf, text/html";
				break;

			case "Video":
				this.contentTarget.accept = "video/*, video/quicktime, video/mp4, video/x-msvideo";
				break;

			default:
				break;
		}
	}

	validate() {
		const validFormats = ["application/pdf", "text/html", "video/quicktime", "video/mp4", "video/x-msvideo"];
		const fileType     = event.target.files[0].type;
		const fileSize     = event.target.files[0].size;
		const button       = document.getElementById("submitForm");

		if (this.selectedType == "Video" && fileSize > 26214400) {
			$(".dropify-preview").hide();
			$(".dropify-wrapper").removeClass("has-preview").addClass("drop-fail");
			$(".dropify-error").text("The file size is too big, 25MB max");
			button.disabled = true;

			return;
		}

		if (!validFormats.includes(fileType)) {
			$(".dropify-preview").hide();
			$(".dropify-wrapper").get(0).classList.replace("has-preview", "drop-fail");
			$(".dropify-error").text("The file type format is not allowed.");
			button.disabled = true;

			return;
		}

		$(".dropify-preview").show();
		$(".dropify-wrapper").removeClass("drop-fail").addClass("has-preview");
		$(".dropify-error").text("");

		button.disabled           = false;
		this.filenameTarget.value = event.target.files[0].name;
	}
}
