import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["button", "textArea", "userSelection", "companySelection"];

    initialize() {
        Promise.resolve().then(() => {
            if (this.hasUserSelectionTarget) {
                this._initUserObserver();
                this.toggleUserList();
            }

            if (this.hasCompanySelectionTarget) {
                this._initCompanyObserver();
                this.toggleCompanyList();
            }

            this._listenEditorEvents();

            document.querySelectorAll("input[type=text]").forEach(input => {
                input.addEventListener("input", event => {
                    this.buttonTarget.disabled = !this._canSubmit();
                });
            });
        });
    }

    submit(event) {
        if (!this._canSubmit()) {
            event.preventDefault();
            this.buttonTarget.disabled = true;
        }
    }

    toggleCompanyList() {
        const companyList   = document.getElementById("CompaniesList");
        const companyTable  = document.getElementById("CompanyTable");
        const companyBanner = document.getElementById("AllCompaniesSelected");
        const selection     = this.companySelectionTargets.find(input => input.checked).value;

        if (selection === "ALL") {
            companyList.classList.add("hidden");
            companyTable.classList.add("hidden");
            companyBanner.classList.remove("hidden");
        }

        if (selection === "CUSTOM") {
            companyList.classList.remove("hidden");
            companyTable.classList.remove("hidden");
            companyBanner.classList.add("hidden");
        }

        this.buttonTarget.disabled = !this._canSubmit();
    }

    toggleUserList() {
        const userList   = document.getElementById("UsersList");
        const userTable  = document.getElementById("userList");
        const userBanner = document.getElementById("AllUsersSelected");
        const selection  = this.userSelectionTargets.find(input => input.checked).value;

        if (selection === "allUsers") {
            userList.classList.add("hidden");
            userTable.classList.add("hidden");
            userBanner.classList.remove("hidden");
        }

        if (selection === "custom") {
            userList.classList.remove("hidden");
            userTable.classList.remove("hidden");
            userBanner.classList.add("hidden");
        }

        this.buttonTarget.disabled = !this._canSubmit();
    }

    _initCompanyObserver() {
        const table            = document.getElementById("CompanyTable").querySelector("tbody");
        const mutationObserver = new MutationObserver(() => {
            this.buttonTarget.disabled = !this._canSubmit();
        });

        mutationObserver.observe(table, {
            attributes: false,
            childList: true,
        });
    }

    _initUserObserver() {
        const table            = document.getElementById("CompanySelectedUsers");
        const mutationObserver = new MutationObserver(() => {
            this.buttonTarget.disabled = !this._canSubmit();
        });

        mutationObserver.observe(table, {
            attributes: false,
            childList: true,
            subtree: true,
        });
    }

    _listenEditorEvents() {
        addEventListener("trix-initialize", event => {
            if (this.textAreaTarget.classList.contains("is-invalid")) {
                event.target.style.borderColor = "#dc3545";
            }
        });

        addEventListener("trix-change", event => {
            this.buttonTarget.disabled = !this._canSubmit();

            this.textAreaTarget.value = event.target.editor.getDocument().toString();
            if (this.textAreaTarget.classList.contains("is-invalid")) {
                event.target.style.borderColor = "#bbb";
            }
        });
    }

    _validateInputs() {
        const inputs = document.querySelectorAll("#templateSection input[type=text], #templateSection textarea");
        return Array.from(inputs).every(input => input.value !== "");
    }

    _validateUsers() {
        if (!this.hasUserSelectionTarget) {
            return true;
        }

        let usersLength = 0;
        const selection = this.userSelectionTargets.find(input => input.checked).value;

        if (selection === "allUsers") {
            usersLength = document.getElementById("AllUsersCount").innerText;
        }

        if (selection === "custom") {
            usersLength = document.querySelector("#CompanySelectedUsers").innerText;
        }

        return usersLength > 0;
    }

    _validateCompanies() {
        const companySection = document.getElementById("companiesSection");
        if (companySection === null) {
            return true;
        }

        let usersLength = 0;
        const selection = companySection.querySelector("input[type=\"radio\"]").value;

        if (selection === "ALL") {
            const usersCount = document.getElementById("all-companies-selected-box").querySelector("[data-target-companies-target=\"allCompanies\"]");
            usersLength      = parseInt(usersCount.innerText);
        }

        if (selection === "CUSTOM") {
            const table            = document.getElementById("CompanyTable");
            const dataTable        = table["datatable"];
            const companyTableRows = dataTable.rows;

            usersLength = companyTableRows.length;
        }

        return usersLength > 0;
    }

    _canSubmit() {
        const validInputs    = this._validateInputs();
        const validUsers     = this._validateUsers();
        const validCompanies = this._validateCompanies();

        return validInputs && validUsers && validCompanies;
    }
}
