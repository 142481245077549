import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["property"]
    static values = { url: String }

    connect() {
        this.element[this.identifier] = this;
        this.load();
    }

    load() {
        const targets = this.propertyTargets;

        fetch(this.urlValue)
            .then(response => {
                if (!response.ok) {
                    throw response
                }

                return response.json();
            })
            .then(data => {
                targets.forEach(target => target.innerHTML = data[target.dataset.propertyValue]);
            })
            .catch(err => {
                console.error(err);
            });
    }
}