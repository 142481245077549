import { Controller } from "@hotwired/stimulus"

// Strategy Values
const strategyRandom = "scheduled";
const strategyEven = "evenly";
const strategyAsap = "immediately";
const strategyAtOnce = "SPECIFIC";

export default class ShortCampaignScheduleController extends Controller {
    static targets = [
        "strategy", "description", "date", "time",
        "from", "fromHour", "until", "untilHour",
        "scheduledBox", "specificBox",
    ]

    initialize() {
        this.element[this.identifier] = this;
    }

    connect() {
        Promise.resolve()
            .then(() => {
                this.updateUI();
            })
            .catch(() => { })
    }

    /**
        * Update the UI based on the strategy selected.
    */
    updateUI() {
        this.changeDescription();
        this.toggleTimeBox();
    }

    /**
        * Changes the strategy description based on the selected strategy.
    */
    changeDescription() {
        switch (this.strategyTarget.value) {
            case strategyRandom:
                this.descriptionTarget.textContent = "Each user will receive 1 email from each template and it will be randomized based on the time/days period chosen.";
                break;

            case strategyEven:
                this.descriptionTarget.textContent = "Users will be divided by the number of templates and each group will receive a different email.";
                break;

            case strategyAsap:
                this.descriptionTarget.textContent = "Users will receive 1 email per each template added. It will take less than 20 minutes after saving the campaign.";
                break;

            case strategyAtOnce:
                this.descriptionTarget.textContent = "Users will receive 1 email per each template added. And all of them will be sent at the same time on the defined date/hour.";
                break;
        }
    }

    /**
        * Sets the selected date to the original campaign from input.
    */
    setDate() {
        const fromPickr = this.fromTarget._flatpickr;
        const untilPickr = this.untilTarget._flatpickr;

        fromPickr.setDate(this.dateTarget.value, true)
        untilPickr.setDate(this.dateTarget.value, true)
    }

    /**
        * Sets the selected hour to the original campaign from input.
    */
    setTime() {
        const fromChoices = this.fromHourTarget["choices"].instance;
        const untilChoices = this.untilHourTarget["choices"].instance;

        fromChoices.setChoiceByValue(this.timeTarget.value);
        untilChoices.setChoiceByValue(`${parseInt(this.timeTarget.value) + 1}`);
    }

    /**
        * Show or hide the time/date sections based on the selected strategy.
    */
    toggleTimeBox() {
        const asapBox = this.scheduledBoxTarget["components--slide"];
        const specificBox = this.specificBoxTarget["components--slide"];

        switch (this.strategyTarget.value) {
            case strategyAsap:
                asapBox.up();
                specificBox.up();
                break;

            case strategyAtOnce:
                asapBox.up();
                specificBox.down();
                break;

            default:
                asapBox.down();
                specificBox.up();
                break;
        }
    }
}