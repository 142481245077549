import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["item", "selector"];
	static classes = ["hide"];

	/**
	 * returns the item that will be revealed based on the radio button selected.
	 * @returns {string} the item param
	 */
	get selected() {
		return this.selectorTargets.find(input => input.checked).dataset["components-RevealItemParam"];
	}

	connect() {
		this.element[this.identifier] = this;
		this.showItem({params: {item: this.selected}});
	}

	/**
	 * reveals the item based on the value passed.
	 * @param {Object} params - The item that will be revealed.
	 */
	showItem({params: {item}}) {
		this.itemTargets.forEach(target => {
			if (target.id === item) {
				target.classList.remove(this.hideClass);
			} else {
				target.classList.add(this.hideClass);
			}
		});
	}
}
