import { Controller } from "@hotwired/stimulus"
import flatpickr from "../models/flatpickr"
export default class extends Controller {
    static targets = ["filterItem"]

    initialize() {
        this.expand()

        $('.filter-action').on('click', function (e) {
            e.stopPropagation();
        })

        $(document).on('click', '.choices, .flatpickr-calendar', function (e) {
            e.stopPropagation();
        })

        const $from = $('#filter-range-from')
        const $until = $('#filter-range-until')

        if ($from.val() == "") {
            $from.val("01/02/2018")
        }
        if ($until.val() == "") {
            $until.val(`${new Date().toLocaleDateString("fr-FR")}`)
        }

        flatpickr.initRange($from, $until, true)
    }

    expand() {
        const filterRadios = document.querySelectorAll("input[type='radio']")

        filterRadios.forEach((element, index) => {
            element.checked ? this.filterItemTargets[index].classList.add("filter-item--expand") : this.filterItemTargets[index].classList.remove("filter-item--expand")
        });
    }
}