import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  initialize() {
    this.checkCompletion();
    this.updateProgress();
  }

  connect() {}

  toggleConnection(event) {
    const isChecked = event.target.checked;
    const spanElementIcon = event.target.closest('.flex').querySelector('.icon-color');
    const spanElementTitle = event.target.closest('.flex').querySelector('.title-color');


    if (isChecked) {
      spanElementIcon.classList.remove('text-gray-300');
      spanElementIcon.classList.add('text-blue-500');
      spanElementTitle.classList.add('text-blue-500');
    } else {
      spanElementIcon.classList.remove('text-blue-500');
      spanElementTitle.classList.remove('text-blue-500');
      spanElementIcon.classList.add('text-gray-300');
    }

    this.checkCompletion();
    this.updateProgress();
  }

  updateProgress() {
    const completedSteps = this.element.querySelectorAll('input[type="checkbox"]:checked').length;
    const totalSteps = this.element.querySelectorAll('input[type="checkbox"]').length;

    const progressIndicator = document.getElementById('progressIndicator');
    if (progressIndicator) {
        progressIndicator.textContent = `${completedSteps}/${totalSteps} Completed`;
    }
  }
  
  selectAllCheckboxes() {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
    });

    const spanicon = this.element.querySelectorAll('span.icon-color');
    spanicon.forEach((span) => {
        span.classList.add('text-blue-500');
        span.classList.remove('text-gray-300');
    });

    const spantitle = this.element.querySelectorAll('span.title-color');
    spantitle.forEach((span) => {
        span.classList.add('text-blue-500');
    });

    this.checkCompletion();
    this.updateProgress();
  }

  checkCompletion() {
    const completedSteps = this.element.querySelectorAll('input[type="checkbox"]:checked').length;
    const totalSteps = this.element.querySelectorAll('input[type="checkbox"]').length;

    if (completedSteps === totalSteps) {
      finishButton.removeAttribute('disabled');
    } else {
      finishButton.setAttribute('disabled', '');
    }
  }

}   
