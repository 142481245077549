import { Controller } from "@hotwired/stimulus";
import cookieValue from "../../models/cookies";
import Choices from "choices.js/public/assets/scripts/choices.js";
import select2 from "../../models/select2.js";

/** Class representing an user. */
class User {
	/**
	 * Create an user.
	 * @param {string} id - The id value.
	 * @param {string} firstName - The firstName value.
	 * @param {string} lastName - The lastName value.
	 * @param {string} email - The email value.
	 * @param {string} title - The title value.
	 * @param {Object[]} categories - The categories value.
	 * @param {Object[]} errors - The errors value.
	 */
	constructor(id, firstName, lastName, email, title, categories, errors) {
		this.id         = id;
		this.firstName  = firstName;
		this.lastName   = lastName;
		this.email      = email;
		this.title      = title;
		this.categories = categories;
		this.errors     = errors;
	}

	/**
	 * gets an specific error.
	 * @param {string} key - The key of the error.
	 * @return {string[]} The errors for the key.
	 */
	getError(key) {
		if (this.errors == undefined) {
			return [];
		}

		const errors = this.errors.errors[key];
		if (errors == undefined) {
			return [];
		}

		return errors;
	}

	/**
	 * gets a map with a list of errors.
	 * @return {Object} The map of errors.
	 */
	getErrors() {
		if (this.errors == undefined) {
			return {};
		}

		return this.errors.errors;
	}

	/**
	 * verify is user has categories.
	 * @return {boolean} The x value.
	 */
	hasCategories() {
		return this.categories != null;
	}
}

export default class extends Controller {
	static targets = [
		"baseRow", "requiredInput", "addButton", "optInBanner", "usersCount",
		"usersList", "usersRows", "loadingState", "errorState",
	];

	get companyCategories() {
		const baseRow = this.baseRowTarget.content.cloneNode(true);
		const select  = baseRow.querySelector(".role-select");
		return select.options;
	}

	get lastRow() {
		return Array.from(this.usersRowsTarget.querySelectorAll(".form-row")).slice(-1)[0];
	}

	get rowsCount() {
		return this.usersRowsTarget.querySelectorAll(".form-row").length;
	}

	/**
	 * initialize the controller for the current object
	 */
	initialize() {
		select2.init($(".role-select"));
		this.data.index = this.rowsCount;

		if (cookieValue.getCookie("hide-opt-in-banner") == "true" && this.hasOptInBannerTarget) {
			this.optInBannerTarget.remove();
		}

		this._updateUsersCount();
		this.checkValidity();

		if (this.data.has("url")) {
			this.load();
		}

		const form = document.querySelector("form");
		form.addEventListener("submit", () => {
			$(".preloader").show();
		});
	}

	/**
	 * addUserRow adds a new row on the new user list
	 * and applies the necessary libraries for the select inputs.
	 */
	addUserRow() {
		const newRow = this.baseRowTarget.content.cloneNode(true);

		this.data.index++;
		this.usersRowsTarget.append(newRow);
		this.addButtonTarget.disabled = true;

		this._updateUsersCount();
		this._reorderIndexes();

		const selects = this.lastRow.getElementsByTagName("select");

		// Setting id to data-index
		const inputs = this.lastRow.querySelectorAll("input.form-control, select.form-control");
		Array.from(inputs).forEach(input => input.id = input.id.replace(/\[(.*)\]/g, `[${this.data.index - 1}]`));

		const riskLevel  = selects[0];
		const categories = selects[1];

		// Init RiskLevel Choices Instance
		new Choices(riskLevel);

		// Init Categories Select2 Instance
		select2.init($(categories));

		this.checkValidity();
	}

	addUser(user) {
		const newRow  = this.baseRowTarget.content.cloneNode(true);
		const inputs  = newRow.querySelectorAll("input.form-control, select.form-control");
		const selects = newRow.querySelectorAll("select");

		// sets only the first error found
		const setError = (input, errors) => {
			input.classList.add("is-invalid");

			const errorBox = document.createElement("div");
			errorBox.classList.add("invalid-feedback", "help-block");
			errorBox.innerText = errors[0];
			input.parentNode.insertBefore(errorBox, input.nextSibling);
		};

		Array.from(inputs).forEach(input => {
			input.id   = input.id.replace(/\[(.*)\]/g, `[${this.data.index}]`);
			input.name = input.name.replace(/\[(.*)\]/g, `[${this.data.index}]`);

			switch (input.name) {
				case `CSVUsers[${this.data.index}].ID`:
					input.value = user.id;
					break;
				case `Users[${this.data.index}].FirstName`:
				case `CSVUsers[${this.data.index}].FirstName`:
					input.value           = user.firstName;
					const firstNameErrors = user.getError("first_name");
					if (firstNameErrors.length > 0) {
						setError(input, firstNameErrors);
					}

					break;
				case `Users[${this.data.index}].LastName`:
				case `CSVUsers[${this.data.index}].LastName`:
					input.value          = user.lastName;
					const lastNameErrors = user.getError("last_name");
					if (lastNameErrors.length > 0) {
						setError(input, lastNameErrors);
					}

					break;
				case `Users[${this.data.index}].Email`:
				case `CSVUsers[${this.data.index}].Email`:
					input.value       = user.email;
					const emailErrors = user.getError("email");
					if (emailErrors.length > 0) {
						setError(input, emailErrors);
					}

					break;
				case `Users[${this.data.index}].Title`:
				case `CSVUsers[${this.data.index}].Title`:
					input.value = user.title;
					break;
				case `UsersCategories[${this.data.index}]`:
				case `CSVUsersCategories[${this.data.index}]`:
					if (user.hasCategories()) {
						user.categories.forEach(category => {
							const option = Array.from(input.options).find(option => option.value == category.id);
							if (option != undefined) {
								option.selected = true;
							} else {
								const o    = new Option(category.name, category.name);
								o.selected = true;
								input.add(o);
							}
						});
					}
					break;
				default:
					break;
			}
		});

		try {
			new Choices(selects[0]);
			setTimeout(() => {
				select2.init($(selects[1]));
			}, 1);
		} catch (error) {
			console.error("ERROR 2 ->", error);
		}

		this.data.index++;
		return newRow;
	}

	/**
	 * addUsers will add a row for each user and fill the row with user data.
	 * @param {Object[]} data - Array of users.
	 */
	addUsers(data) {
		const submitButton    = document.getElementById("btn-submit");
		const loadingTitle    = this.loadingStateTarget.getElementsByTagName("h4")[0];
		const rows            = document.createDocumentFragment();
		const maxTimePerChunk = 200;
		let index             = 0;

		const now = () => {
			return new Date().getTime();
		};

		const doChunk = () => {
			const startTime = now();
			while (index < data.length && (now() - startTime) <= maxTimePerChunk) {
				loadingTitle.innerText = `Your users are being analyzed (${index}/${data.length})`;
				const row              = this.addUser(data[index]);
				rows.appendChild(row);
				++index;
			}

			if (index < data.length) {
				// set Timeout for async iteration
				setTimeout(doChunk, 1);
			} else {
				loadingTitle.innerText = "We're listing your users";

				// Finish the process
				setTimeout(() => {
					this.usersRowsTarget.append(rows);
					this._updateUsersCount();
					this._reorderIndexes();

					// Show the tables
					this.usersListTarget.classList.remove("d-none");
					this.loadingStateTarget.remove();
					this.errorStateTarget.remove();

					// Enabling submit button
					submitButton.disabled = false;
				}, 100);

			}
		};

		doChunk();
	}

	/**
	 * checkValidity validates if the required fields are not empty and valid and prevents adding new rows
	 */
	checkValidity() {
		if (!this.hasAddButtonTarget) {
			return;
		}

		const submitButton = document.getElementById("btn-submit");
		const invalid      = this.requiredInputTargets.some(element => !element.checkValidity());

		if (invalid) {
			this.addButtonTarget.disabled = true;
			submitButton.disabled         = true;
		} else {
			this.addButtonTarget.disabled = false;
			submitButton.disabled         = false;
		}
	}

	/**
	 * load calls that app to retrieve a list of users and add it to the list
	 */
	load() {
		this.loadingStateTarget.getElementsByTagName("h4")[0].innerText = "Your users are loading…";
		this.loadingStateTarget.classList.remove("d-none");
		this.errorStateTarget.classList.add("d-none");

		fetch(this.data.get("url"))
			.then(response => {
				if (!response.ok) {
					throw response;
				}

				return response.json();
			})
			.then(users => {
				if (users == null) {
					return;
				}

				const usersObject = [];
				users.forEach(user => {
					const u = new User(user.id, user.first_name, user.last_name, user.email, user.title, user.categories, user.errors);
					usersObject.push(u);
				});

				const paths     = this.data.get("url").split("/");
				const withError = usersObject.some(user => Object.getOwnPropertyNames(user.getErrors()).length > 0);

				if (withError) {
					this.data.error = "true";

					if (paths[paths.length - 2] == "list_new") {
						const errorIcon = document.getElementById("newUsersHasErrors");

						if (errorIcon != null) {
							errorIcon.classList.remove("d-none");
						}
					}

					if (paths[paths.length - 2] == "list_updated") {
						document.getElementById("updatableUsersHasErrors").classList.remove("d-none");
					}
				}

				// Sorting users so users with errors appears first.
				usersObject.sort(user => {
					if (user.errors == undefined) {
						return 0;
					}

					return -1;
				});
				this.addUsers(usersObject);
			})
			.catch(err => {
				console.error(err);
				this.loadingStateTarget.classList.add("d-none");
				this.errorStateTarget.classList.remove("d-none");
			});
	}

	/**
	 * toggleUpdatableUsers toggle the list of users that are being going to be updated
	 * @param {object} evt
	 */
	toggleUpdatableUsers(event) {
		const updatedUsersList = document.getElementById("updatableUserList");
		const errorIcon        = document.getElementById("updatableUsersHasErrors");
		const updateUsers      = event.target.value == "true";

		updateUsers ? updatedUsersList.classList.remove("d-none") : updatedUsersList.classList.add("d-none");
		this.data.error == "true" && updateUsers ? errorIcon.classList.remove("d-none") : errorIcon.classList.add("d-none");
	}

	/**
	 * removeUserRow removes a row from the new user list
	 * @param {Object} evt
	 */
	removeUserRow(evt) {
		let userRow = evt.target.parentElement.parentElement;

		if (evt.target.nodeName == "path") {
			userRow = userRow.parentElement;
		}

		userRow.remove();

		this._updateUsersCount();
		this._reorderIndexes();

		const tooltips = document.getElementsByClassName("tooltip");
		Array.from(tooltips).forEach(tooltip => tooltip.remove());
		this.checkValidity();
	}

	/**
	 * removeCSVBlankState removes the blank state from the CSV Confirmation Page
	 * and applies a new user row.
	 * (This only applies for the CSV Upload Functions)
	 */
	removeCSVBlankState() {
		document.getElementById("csvBlankState").remove();
		this.usersListTarget.classList.remove("d-none");
		this.addUserRow();
	}

	/**
	 * dismissOptInInfo sets a cookie value on the client side
	 * in order to disable the Opt In information banner
	 */
	dismissOptInInfo() {
		cookieValue.setCookie("hide-opt-in-banner", true);
		this.optInBannerTarget.remove();
	}

	_updateUsersCount() {
		this.rowsCount > 1 ? this.usersRowsTarget.classList.add("has-multiple") : this.usersRowsTarget.classList.remove("has-multiple");

		if (this.hasUsersCountTarget) {
			this.usersCountTarget.textContent = `(${this.rowsCount})`;
		}
	}

	_reorderIndexes() {
		const rows = this.usersRowsTarget.getElementsByClassName("form-row");

		Array.from(rows).forEach((row, index) => {
			const inputs = row.querySelectorAll("input.form-control, select.form-control");

			Array.from(inputs).forEach(input => {
				input.name = input.name.replace(/\[(.*)\]/g, `[${index}]`);
			});
		});
	}
}
