import { Controller } from "@hotwired/stimulus"
import Papa from 'papaparse'
import Choices from 'choices.js/public/assets/scripts/choices.js'

let $choicesInstances;

export default class extends Controller {
    static targets = ["file", "btnContinue"]

    cancelImport() {
        // Reset Sections
        this.btnContinueTarget.disabled = true;
        $('#upload-section, #matching-section').toggle();
        this.btnContinueTarget.classList.remove('d-none');
        document.getElementById('button-container').classList.add('d-none');

        const CSVInput = this.fileTarget["dropify"]

        // Reset Dropify Status
        CSVInput.resetPreview();
        CSVInput.clearElement();
        this._clearChoicesInstances();
    }

    continue() {
        $('#upload-section, #matching-section').toggle();
        this.btnContinueTarget.classList.add('d-none');
        document.getElementById('button-container').classList.remove('d-none');
        document.querySelector('.dropify-wrapper').classList.remove('drop-fail');

        $choicesInstances = new Choices('.csv-headers')
    }

    parseCSV() {
        const file = this.fileTarget.files[0]

        Papa.parse(file, {
            skipEmptyLines: true,
            header: true,
            complete: this._completeFn,
            error: this._errorFn,
        })
    }

    _completeFn(results) {
        if (results.data && results.errors.length > 0) {
            setDropifyError(results.errors[0].message)
            return
        }

        if (results.data.length < 1) {
            setDropifyError("It seems that your CSV file is empty")
            return
        }

        document.getElementById('btn-continue').disabled = false;
        $("option[value!='None']").remove() //Remove non-None options tag before appending new options

        const originalHeaders = ["First Name", "Last Name", "Email", "Title", "Categories"];

        let headers = results.meta.fields
        originalHeaders.forEach((currentHeader, index) => {
            headers.forEach(csvHeader => {
                $('.csv-headers').eq(index).append($('<option>', {
                    value: csvHeader,
                    text: csvHeader,
                    selected: toNormalText(csvHeader) == toNormalText(currentHeader),
                }));
            });
        })
    }

    _errorFn(err) {
        setDropifyError(err.message)
    }

    _clearChoicesInstances() {
        $choicesInstances.forEach(choice => {
            choice.destroy()
        })
    }
}

function setDropifyError(message) {
    $('.dropify-preview').remove()
    $('.dropify-wrapper').removeClass('has-preview').addClass('drop-fail');
    $('.dropify-error').text(message);
}

function toNormalText(text) {
    const result = text.replace(/[\s\_]/g, "");
    return result.toLowerCase();
}
