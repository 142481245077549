import { Controller } from "@hotwired/stimulus"
import c3 from 'c3'

export default class extends Controller {
    static values = {
        aup: Number,
        pending: Number,
        overdue: Number,
        resolved: Number,
        url: String,
    }

    get totalThreats() {
        return this.pendingValue + this.overdueValue + this.resolvedValue
    }

    connect() {
        this.element[this.identifier] = this;
        this.load();
    }

    load() {
        const element = this.element;

        let options = {
            bindto: element,
            size: {
                width: element.dataset.swidth,
                height: element.dataset.height,
            },
            onresize: function () {
                let width = this.api.element.parentElement.clientWidth - 120;

                if (width > 210) {
                    width = 210
                }

                this.api.resize({
                    width: width,
                    height: element.dataset.height,
                });
            },
            data: {
                columns: [
                    ['empty', 1],
                ],
                order: null,
                type: 'donut',
                onclick: d => {
                    if (!this.hasUrlValue || d.id === 'empty') {
                        return
                    }

                    location.href = this.urlValue;
                }
            },
            donut: {
                title: "",
                width: element.dataset.width,
                expand: false,
                label: {
                    show: false
                }
            },
            legend: {
                hide: element.dataset.hideLegend === "true",
            },
            tooltip: {
                show: false,
            },
            color: {
                pattern: ["#CED9E6"]
            },
        }

        if (this.totalThreats != 0) {
            options.data.columns = [
                ["Resolved", this.resolvedValue],
                ["AUP", this.aupValue],
                ["Overdue", this.overdueValue],
                ["Pending", this.pendingValue],
            ];

            options.color.pattern = ["#56CE63", "#B34AFF", "#FF4A77", "#1185E5"];
        }

        this.chart = c3.generate(options);
        this.element.querySelector(".c3-chart-arcs-title").innerHTML = `
        <tspan class='donut-title donut-title-medium' dy='-20' x='5'>${this.totalThreats}</tspan>
        <tspan class='donut-title donut-title-xs font-light' dy='35' x='0'>Email</tspan>
        <tspan class='donut-title donut-title-xs font-light' dy='20' x='0'>Threats</tspan>
        `
    }
}