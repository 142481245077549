/**
 * Parses the provided categories and returns a formatted string.
 * @param {String} inputName - The name that will receive the input.
 * @param {Array<Object>} categories - The array of category objects to parse.
 * @returns {string} A formatted string representing the parsed categories.
 * @method parseCategories
 * @description If the input categories array is empty, returns a default value.
 * Otherwise, parses each category and creates HTML elements for display.
 */
export function parseCategories(inputName, categories) {
	if (categories.length === 0) {
		return `<span class='text-xs new-pill'>Any New User</span>`;
	}

	const cats = [];
	categories.forEach(cat => {
		cats.push(`<input name='${inputName}' type='hidden' value='${cat.id}'/>`);
		cats.push(`<span class='text-xs new-pill'>${cat.name}</span>`);
	});

	return cats.join("");
}
