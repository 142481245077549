import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["modal", "input", "recipients", "submitBtn"]

    initialize() {
        if (this.hasModalTarget) {
            $(this.modalTarget).on('show.bs.modal', this.update);
            $(this.modalTarget).on('hide.bs.modal', () => this.cleanShareModal());
        }

        this.inputTarget.addEventListener('addItem', () => this.validate());
        this.inputTarget.addEventListener('removeItem', () => this.validate());
    }

    cleanShareModal() {
        const choices = this.inputTarget["choices"].instance;

        // Clearing Choice input
        choices.removeActiveItems();
    }

    update(evt) {
        const summaryID = evt.relatedTarget.dataset.id;
        const form = evt.target.querySelector('form');

        form.action = form.dataset.action + summaryID + "/share/"
    }

    validate() {
        this.inputTarget.value == "" ? this.submitBtnTarget.disabled = true : this.submitBtnTarget.disabled = false;
    }
}