import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { id: String }

    connect() {
        this.element[this.identifier] = this;
        this.load();
    }

    load() {
        let url = `/courses-bucket/${this.idValue}/index_lms_html5.html`;
        const http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();

        if (http.status == 404) {
            url = `/courses-bucket/${this.idValue}/index_lms.html`;
        }

        this.element.src = url;
    }
}