import { Controller } from "@hotwired/stimulus";

export default class SignDocument extends Controller {
	static targets = ["content", "actionButtons", "canvasContainer", "block"];
	static outlets = ["policylocker--signature-modal"];
	static values  = {source: String};

	get signAreas() {
		return this.contentTarget.querySelectorAll("[data-sign-area]");
	}

	connect() {
		// Checking if the source is the editor or upload.
		const loadSignature = this.sourceValue == "EDITOR" ? this.loadSignature : this.loadSignatureUpload;

		// Wait for controllers to connect.
		Promise.resolve().then(() => {
			this.signatureModal = this.policylockerSignatureModalOutlet;
			this.signatureModal.element.addEventListener("close", loadSignature);
		});
	}

	/**
	 * returns the policy template to its initial state.
	 */
	cancel() {
		// Toggling action buttons
		this.actionButtonsTargets[0].classList.remove("hidden");
		this.actionButtonsTargets[1].classList.add("hidden");

		if (this.sourceValue == "EDITOR") {
			this.cancelEditor();
			return;
		}

		this.cancelUpload();
	}

	cancelEditor() {
		// Removing Signatures
		this.canvasContainerTargets.forEach(target => target.remove());

		// Restoring Sign Areas
		this.signAreas.forEach(signArea => signArea.style.display = "block");
	}

	cancelUpload() {
		const blocks = document.querySelectorAll(".signature-block");

		blocks.forEach(block => {
			block.src = this.blockTarget.src;
		});
	}

	/**
	 * Inserts the signature next to the sign areas.
	 * The sign areas will hide from the document
	 * @param {HTMLElement} signature - The signature container.
	 */
	async insertSignature(signature) {
		const appendPromise = new Promise(resolve => {
			const child = this.contentTarget.appendChild(signature);
			setTimeout(() => {
				resolve(child);
			}, 250);
		});

		await appendPromise.then(child => {
			this.signAreas.forEach(signArea => {
				signArea.style.display = "none";

				const clone = child.cloneNode(true);
				this.contentTarget.appendChild(clone);

				clone.classList.remove("hidden");
				signArea.after(clone);
			});

			// Removing original signature
			signature.remove();
		});
	}

	/**
	 * loads the signature generated from the signature modal controller
	 * and appends it to policy content.
	 */
	loadSignature = () => {
		const signBtn = this.actionButtonsTargets[0].querySelector("button");
		const data    = this.signatureModal.signatureData;

		// Validating signature.
		if (data === "" || data === undefined || data === null) {
			signBtn.disabled = false;
			return;
		}

		// Validate if signature already exists.
		if (this.contentTarget.querySelector("canvas") !== null) {
			signBtn.disabled = false;
			return;
		}

		// Getting Signature and appending it to the document.
		const signature = createSignatureContainer(data);
		this.insertSignature(signature).then(() => {
			this.actionButtonsTargets[0].classList.add("hidden");
			this.actionButtonsTargets[1].classList.remove("hidden");
			signBtn.disabled = false;
		});

		// Cleaning signature
		this.signatureModal.signatureData = null;
	};

	loadSignatureUpload = () => {
		const signBtn = this.actionButtonsTargets[0].querySelector("button");
		const data    = this.signatureModal.signatureData;

		// Validating signature.
		if (data === "" || data === undefined || data === null) {
			signBtn.disabled = false;
			return;
		}

		const blocks = document.querySelectorAll(".signature-block");

		blocks.forEach(block => {
			block.src    = data;
			block.width  = 240;
			block.height = 80;
		});

		this.actionButtonsTargets[0].classList.add("hidden");
		this.actionButtonsTargets[1].classList.remove("hidden");
		signBtn.disabled = false;

		// Cleaning signature
		this.signatureModal.signatureData = null;

		this.contentTarget.innerHTML = data;
	};

	/**
	 * opens the signature modal.
	 */
	openModal({target}) {
		target.disabled = true;
		this.signatureModal.element.showModal();
	}

	/**
	 * creates a form and submits it with the policy signed.
	 */
	submit(evt) {
		evt.preventDefault();

		if (this.sourceValue === "EDITOR") {
			// Cleaning Signature container.
			this.canvasContainerTargets.forEach(container => container.classList.remove("hidden"));

			// Removing sign areas
			this.signAreas.forEach(signarea => signarea.remove());
		}

		// Creating Textarea
		const textarea = document.createElement("textarea");
		textarea.classList.add("hidden");
		textarea.name  = "content";
		textarea.value = this.contentTarget.innerHTML;
		evt.target.appendChild(textarea);

		// Submitting Form Data
		evt.currentTarget.submit();
	}
}

function createSignatureContainer(signature) {
	const div          = document.createElement("div");
	const signatureImg = new Image();

	signatureImg.src    = signature;
	signatureImg.onload = function () {
		signatureImg.style.width  = 100 + "%";
		signatureImg.style.height = 100 + "%";

		// Styling div container.
		div.style.height = 87.5 + "px";
		div.style.width  = 280 + "px";
		div.classList.add("hidden");
		div.setAttribute("data-policylocker--sign-document-target", "canvasContainer");

		// Appending canvas to div.
		div.appendChild(signatureImg);
	};

	return div;
}
