import { Controller } from "@hotwired/stimulus";
import noUiSlider from "nouislider";

const defaultFormatting = {
	from: function (value) {
		return value + "%";
	},
	to: function (value) {
		return value + "%";
	},
};

export default class SimpleDataTable extends Controller {
	static targets = ["slider", "minInput", "maxInput"];
	static values  = {
		min: {type: Number, default: 0},
		max: {type: Number, default: 100},
		step: {type: Number, default: 0.01},
	};

	initialize() {
		// Change default classes for all sliders —
		// For example, all sliders will now have the className "noUi-target range-slider"
		noUiSlider.cssClasses.target += " input-range";

		noUiSlider.create(this.sliderTarget, {
			"animate": false,
			"connect": true,
			"pips": {
				"mode": "range",
				"density": 3,
				"format": defaultFormatting,
			},
			"range": {
				"min": 0,
				"max": 100,
			},
			"start": [this.minValue, this.maxValue],
			"step": this.stepValue,
			"tooltips": [defaultFormatting, defaultFormatting],
		});

		this.sliderTarget.noUiSlider.on("update", (values, handle) => {
			// Updating input based on the left handle
			if (handle === 0) {
				this.minInputTarget.value = values[handle];
			}
			// Updating input based on the right handle
			if (handle === 1) {
				this.maxInputTarget.value = values[handle];
			}
		});
	}
}
