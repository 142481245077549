import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["complete", "duedate", "reminder"];

	get selectedRows() {
		let rows = [];
		this._bulkController.itemCheckboxTargets.forEach(item => item.checked && rows.push(item.parentElement.parentElement));

		return rows;
	}

	initialize() {
		this._bulkController = this._getBulkList();
		this.toggleActions();
	}

	toggleActions() {
		const selected     = this.selectedRows;
		const allCompleted = selected.every(row => row.dataset.status === "completed" && row.dataset.active !== "false");
		const allInactive  = selected.every(row => row.dataset.status !== "completed" && row.dataset.active === "false");
		const mixed        = selected.some(row => row.dataset.status === "completed" || row.dataset.active === "false");

		if ((allCompleted || mixed) && !allInactive) {
			this.completeTarget.classList.add("hidden");
			this.duedateTarget.classList.add("hidden");
			this.reminderTarget.classList.add("hidden");
			return;
		}

		if (allInactive) {
			this.duedateTarget.classList.add("hidden");
			this.reminderTarget.classList.add("hidden");
			this.completeTarget.classList.remove("hidden");
			return;
		}

		this.completeTarget.classList.remove("hidden");
		this.duedateTarget.classList.remove("hidden");
		this.reminderTarget.classList.remove("hidden");
	}

	_getBulkList() {
		const list = document.querySelector("[data-controller=\"bulk-selection training--assignments\"]");
		return this.application.getControllerForElementAndIdentifier(list, "bulk-selection");
	}
}
