import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = [];
	static values = {};

	connect() {
		this.element[this.identifier] = this;

		// TODO: remove this jQuery dependency when possible.
		$(this.element).on("shown.bs.modal", appendInputs);
		$(this.element).on("hide.bs.modal", removeInputs);
	}
}

/**
 * append the selected inputs to the form.
 * @param {event} evt - The event fired by the modal.
 */
function appendInputs(evt) {
	let inputs = [];
	const target = evt.relatedTarget.dataset.selection;
	const url = new URLSearchParams(window.location.search);

	if (target === "SINGLE") {
		const input = document.createElement("input");
		input.name = "events";
		input.type = "hidden";
		input.value = evt.relatedTarget.dataset.value;

		inputs.push(input);
	}

	if (target === "BULK") {
		const bulk = document.querySelector("[data-controller='bulk-selection']");
		const bulkController = bulk["bulk-selection"];
		const events = bulk.querySelectorAll("input[type='checkbox']:checked");
		events.forEach(input => {
			if (input.dataset.value === undefined) {
				return
			}

			const i = document.createElement("input");
			i.name = "events";
			i.type = "hidden";
			i.value = input.dataset.value;

			inputs.push(i);
		});
		if (bulkController.allEntriesEnabled) {
			const form = document.getElementById("ignoreEventsModalForm");
			form.action += "?allEntries=true";
		}
		if (url != "" && bulkController.allEntriesEnabled) {
			const form = document.getElementById("ignoreEventsModalForm");
			form.action += "&" + url;
		}
	}

	// appending inputs to form
	const form = evt.target.querySelector("form");
	inputs.forEach(input => form.append(input));
}

/**
 * remove the selected inputs from the form.
 * @param {event} evt - The event fired by the modal.
 */
function removeInputs(evt) {
	const inputs = evt.target.querySelectorAll("input[name='events']");
	inputs.forEach(input => input.remove());
}
