import { Controller } from "@hotwired/stimulus"

export default class Cookies extends Controller {
    static targets = [""]

    connect() {
        if (this.cookie === "true") {
            this.disconnect();
            this.element.remove();
        }
    }

    close() {
        this.cookie = true;
        this.element.remove();
    }

    get cookie() {
        const name = this.data.get("name") + "=";
        const ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }

    set cookie(value) {
        document.cookie = `${this.data.get("name")}=${value}`
    }
}