import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const phishingCurriculum = "phishing";
const trainingCurriculum = "training";

class Lister {
	get list() {
		return this.__prop__;
	}

	set list(choices) {
		this.__prop__ = choices;
	}
}

const originalChoices = new Lister();
const selectChoices   = new Lister();

export default class extends Controller {
	static targets = ["type", "duration", "provider", "select", "owner"];

	get _phishingRows() {
		return document.querySelectorAll("[data-type='phishing']");
	}

	get _trainingRows() {
		return document.querySelectorAll("[data-type='training']");
	}

	get trainingSelectChoices() {
		return selectChoices.list;
	}

	set trainingSelectChoices(selects) {
		selectChoices.list = selects;
	}

	get assetsChoices() {
		return originalChoices.list;
	}

	set assetsChoices(choices) {
		originalChoices.list = choices;
	}

	initialize() {
		this._initChoices();
		this.updateMonths();
		this.updateLibrary();

		if (this.hasSelectTarget) {
			const choices = Array.from(this.selectTarget.children).map((group, index) => {
				const assets = Array.from(group.children).map(asset => {
					return {
						value: asset.value,
						label: asset.label,
						customProperties: {
							provider: asset.dataset.provider,
						},
					};
				});

				return {
					label: group.label,
					id: index + 1,
					disabled: false,
					choices: assets,
				};
			});

			this.assetsChoices = choices;
			this.selectTarget.remove();
		}
	}

	submit(event) {
		if (!this._valid()) {
			event.preventDefault();
			this._displayError();
		}
	}

	submitAndAssign() {
		const form = document.getElementById("CurriculumForm");
		form.action += "?assign=true";

		if (!this._valid()) {
			event.preventDefault();
			this._displayError();
		}
	}

	updateLibrary() {
		const type = this._typeSelected();

		this._phishingRows.forEach(element => element.classList.remove("d-none"));
		this._trainingRows.forEach(element => element.classList.remove("d-none"));

		if (type === trainingCurriculum) {
			this._phishingRows.forEach(element => element.classList.add("d-none"));
			this._trainingRows.forEach(element => element.classList.remove("d-none"));
		}

		if (type === phishingCurriculum) {
			this._phishingRows.forEach(element => element.classList.remove("d-none"));
			this._trainingRows.forEach(element => element.classList.add("d-none"));
		}

		this._toggleSelects();
	}

	updateTrainingAssets() {
		fetch("/admin/curriculums/courses/?company_id=" + this.ownerTarget.value)
			.then(response => response.json())
			.then(data => {
				const newChoices = [];
				this.assetsChoices.forEach(group => {
					const obj = {
						label: group.label,
						id: group.id,
						disabled: group.disabled,
						choices: group.choices.filter(choice => {
							if (choice.customProperties.provider == "CUSTOM") {
								return true;
							}

							return data.some(provider => provider === choice.customProperties.provider);
						}),
					};

					newChoices.push(obj);
				});

				this.trainingSelectChoices.forEach(choice => choice.setChoices(newChoices, "value", "label", true));
			});
	}

	updateMonths() {
		let count       = 0;
		const monthsRow = Array.from(document.getElementById("librarySelection").querySelectorAll(".month-row"));

		if (this.durationTarget.value == "annual") count = 12;
		if (this.durationTarget.value == "semi") count = 6;
		if (this.durationTarget.value == "quarter") count = 3;

		monthsRow.forEach(element => element.classList.add("d-none"));
		monthsRow.slice(0, count).forEach(element => element.classList.remove("d-none"));

		this._toggleSelects();
	}

	_displayError() {
		const alertBox    = document.getElementById("alertBox");
		const alertBanner = `
        <div class='alert alert-fade alert-danger m-t-20' role='alert'>
            <button type='button' class='close' data-dismiss='alert' aria-label='Close'>
                <span aria-hidden='true'>×</span>
            </button>
            At least one phishing template or training asset is required to save the curriculum.
        </div>`;

		alertBox.innerHTML = alertBanner;
	}

	_initChoices() {
		const tmplChoices = document.getElementsByClassName("templates-choices");
		const trngChoices = document.getElementsByClassName("training-choices");

		Array.from(tmplChoices).forEach(choice => {
			new Choices(choice, {
				maxItemCount: 5,
				removeItemButton: true,
				resetScrollPosition: false,
				shouldSortItems: true,
				maxItemText: (maxItemCount) => {
					return `Max ${maxItemCount} templates can be added`;
				},
			});
		});

		let trainingChoices = [];
		Array.from(trngChoices).forEach(choice => {
			const choices = new Choices(choice, {
				maxItemCount: 4,
				removeItemButton: true,
				resetScrollPosition: false,
				shouldSortItems: true,
				maxItemText: (maxItemCount) => {
					return `Max ${maxItemCount} courses can be added`;
				},
			});

			trainingChoices.push(choices);
		});

		this.trainingSelectChoices = trainingChoices;
	}

	_toggleSelects() {
		const selects    = document.getElementById("librarySelection").querySelectorAll(".month-row select");
		const hiddenRows = document.getElementById("librarySelection").querySelectorAll(".d-none");

		selects.forEach(select => select.disabled = false);
		hiddenRows.forEach(row => row.querySelectorAll("select").forEach(select => select.disabled = true));
	}

	_typeSelected() {
		return this.typeTargets.find(input => input.checked).value;
	}

	_valid() {
		const library = document.getElementById("librarySelection");
		const selects = library.querySelectorAll("select:not(:disabled)");
		const invalid = Array.from(selects).every(select => select.value == "");

		return !invalid;
	}

	_parentUntil(elem, selector) {
		// Element.matches() polyfill
		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function (s) {
					var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					    i       = matches.length;
					while (--i >= 0 && matches.item(i) !== this) {
					}
					return i > -1;
				};
		}

		// Get closest match
		for (; elem && elem !== document; elem = elem.parentNode) {
			if (elem.matches(selector)) return elem;
		}

		return null;
	}
}
