import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String }

    connect() {
        this.element[this.identifier] = this;
        this.load();
    }

    load() {
        this.element.classList.add("loading");
        this.element.innerHTML = "";

        fetch(this.urlValue)
            .then(response => {
                if (!response.ok) {
                    throw response
                }

                return response.text();
            })
            .then(html => {
                this.element.classList.remove("loading");
                this.element.innerHTML = html;
            })
            .catch(err => {
                console.error(err);
            });
    }
}