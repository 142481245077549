import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["collapsible", "icon"]
    static values = { open: Boolean }

    connect() {
        this.element[this.identifier] = this;
        this.collapsedClass = this.data.get('class') || 'collapsed';
    }

    collapse() {
        $(this.collapsibleTarget).collapse('hide');
        this.iconTarget.classList.add(this.collapsedClass);
    }

    open() {
        $(this.collapsibleTarget).collapse('show');
        this.iconTarget.classList.remove(this.collapsedClass);
    }

    toggle() {
        if (this.collapsibleTarget.classList.contains('collapsing')) {
            return
        }

        $(this.collapsibleTarget).collapse('toggle');
        this.iconTarget.classList.toggle(this.collapsedClass);
    }
}
