import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

const [OWNER_ROLE, MEMBER_ROLE, REPORTING_ROLE] = ["Owner", "Member", "Reporting"];

class Selects {
    constructor(instance, choices) {
        this._instance = instance;
        this._choices = choices;
    }

    get choices() {
        return this._choices;
    }

    set choices(options) {
        const companyChoices = Array.from(options).map(company => {
            return {
                value: company.value,
                label: company.label,
                customProperties: {
                    isMSP: company.dataset.msp == "true",
                }
            }
        })

        this._choices = companyChoices;
    }

    get instance() {
        return this._instance;
    }

    set instance(newInstance) {
        this._instance = newInstance;
    }
}

const rolesChoices = new Selects();
const companiesChoices = new Selects();

export default class extends Controller {
    static targets = ["role", "company"]

    initialize() {
        this._initRolesChoices();

        if (this.hasCompanyTarget) {
            this._initCompaniesChoices();
        }
    }

    updateCompanies() {
        if (!this.hasCompanyTarget) {
            return
        }

        let choices = companiesChoices.choices;
        if (rolesChoices.instance.getValue().value == REPORTING_ROLE) {
            choices = companiesChoices.choices.filter(choice => !choice.customProperties.isMSP)
        }

        companiesChoices.instance.setChoices(choices, 'value', 'label', true);
    }

    updateRoles() {
        let choices = rolesChoices.choices;
        const isMSP = this._companyIsMSP();

        if (isMSP) {
            choices = rolesChoices.choices.slice(0, rolesChoices.choices.length - 1)
        }

        rolesChoices.instance.setChoices(choices, 'value', 'label', true);
        rolesChoices.instance.setChoiceByValue("Owner");
    }

    _companyIsMSP() {
        const value = companiesChoices.instance.getValue()
        return value.customProperties.isMSP
    }

    _initCompaniesChoices() {
        companiesChoices.choices = this.companyTarget.children;
        companiesChoices.instance = new Choices(this.companyTarget);
        companiesChoices.instance.setChoices(companiesChoices.choices, 'value', 'label', true);

        const firstChoice = companiesChoices.choices[0];
        companiesChoices.instance.setChoiceByValue(firstChoice.value);
        this.updateRoles();
    }

    _initRolesChoices() {
        rolesChoices.choices = this.roleTarget.children;
        rolesChoices.instance = new Choices(this.roleTarget, {
            shouldSort: false,
        })
    }
}