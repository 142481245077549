import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["submitButton"]

    initialize() { }

    toggleSSO() {
        const infoBox = document.getElementById("ssoInformation")
        const formBox = document.getElementById("ssoForm")
        infoBox.classList.toggle("hidden")
        formBox.classList.toggle("hidden")
    }

    refresh(event) {
        const target = event.target
        target.nextElementSibling.textContent = target.files[0].name

        this._removeErrorMessage(target)
    }

    validateInput() {
        const metaInput = document.getElementById("metadataFile")
        const isEmpty = metaInput.files.length == 0

        if (isEmpty) {
            this._addErrorMessage(metaInput, "This field is required to activate SSO.")
            return
        }

        this._submitForm()
    }

    _addErrorMessage(elem, msg) {
        const groupBox = elem.closest(".form-group")
        const errBox = `<div class="invalid-feedback help-block">${msg}</div>`

        // removing possible previous error boxes
        this._removeErrorMessage(elem)

        elem.classList.add("is-invalid");
        groupBox.classList.add("has-error");
        groupBox.insertAdjacentHTML('beforeend', errBox);
        this.submitButtonTarget.disabled = true
    }

    _removeErrorMessage(elem) {
        const groupBox = elem.closest(".form-group")
        const prevErrBox = groupBox.querySelector(".invalid-feedback")

        if (prevErrBox != null) {
            elem.classList.remove("is-invalid");
            groupBox.classList.remove("has-error")

            prevErrBox.remove()
            this.submitButtonTarget.disabled = false
        }
    }

    _submitForm() {
        const form = document.getElementById("metadataUpload")
        form.submit()
    }
}