import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["price", "interval", "checked"]

    initialize() {
        this.checkedTarget.checked ? this.displayPlans("yearly") : this.displayPlans("monthly")
        this.setPlanStyle()
    }

    toggle(event) {
        this.checkedTarget.checked ? this.displayPlans("yearly") : this.displayPlans("monthly")

        // Sets active status to the span switch
        event.target.parentNode.firstElementChild.classList.toggle("active");
        event.target.parentNode.lastElementChild.classList.toggle("active");

        // Sets the first plan to selected
        const selectedPlan = document.querySelector("div.plan:not(.d-none) input")
        selectedPlan.checked = true
        this.setPlanStyle()
    }

    setPlanStyle() {
        document.querySelectorAll("div.plan input").forEach(element => {
            element.checked ? element.parentNode.classList.add("checked") : element.parentNode.classList.remove("checked")
        });
    }

    // Display Plans by Interval
    displayPlans(interval) {
        const plans = document.getElementsByClassName("plan")

        for (const plan of plans) {
            const planInterval = plan.dataset.interval
            planInterval == interval ? plan.classList.remove("d-none") : plan.classList.add("d-none")
        }
    }
}