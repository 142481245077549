import { Controller } from "@hotwired/stimulus";

export default class BoardroomController extends Controller {

	connect() {
		this.element[this.identifier] = this;
	}

	initialize() {
		Promise.resolve().then(() => {
			this.refineCharts();
		});
	}

	/**
	 * modifies the original charts in order to be compatible for the editor.
	 */
	refineCharts() {
		//  Refining Events chart
		this._refineEventsChart();

		// Refining Training Chart
		this._refineTrainingChart();

		// Refining Variant Chart
		this._refineDomainThreatsChart();

		// Refining Email Threats Chart
		this._refineEmailThreatsChart();
	}

	_refineEventsChart() {
		const eventsElement = this.element.querySelector("[data-controller=\"charts--phishing-events-stack\"]");
		const eventsChart   = eventsElement["charts--phishing-events-stack"].chart;

		eventsChart.resize({
			width: 700,
			height: 400,
		});

		Array.from(eventsChart.element.querySelectorAll(".c3-axis.c3-axis-x")).forEach(element => {
			Array.from(element.querySelectorAll(".tick text tspan")).forEach(text => {
				text.setAttribute("style", "font-size: 10px;");
			});
		});

		Array.from(eventsChart.element.querySelectorAll(".c3-legend-item")).forEach(element => {
			element.querySelector("text").setAttribute("style", "font-size: 12px;");

		});

		Array.from(eventsChart.element.querySelectorAll(".c3-xgrids, .c3-ygrids")).forEach(element => {
			Array.from(element.querySelectorAll("line")).forEach(line => {
				line.setAttribute("style", "stroke: #aaa; stroke-dasharray: 3 3;");

			});
		});

		Array.from(eventsChart.element.querySelectorAll(".c3-axis-x path, .c3-axis-y path")).forEach(path => {
			path.setAttribute("style", "stroke-width: 1; fill: none; stroke: #000;");
		});
	}

	_refineTrainingChart() {
		const trainingElement    = this.element.querySelector("[data-controller=\"charts--assignments-overview\"]");
		const trainingController = trainingElement["charts--assignments-overview"];
		const trainingChart      = trainingController.chart;

		Array.from(trainingChart.element.querySelectorAll(".c3-legend-item")).forEach(element => {
			element.querySelector("text").setAttribute("style", "font-size: 12px;");

		});

		trainingChart.element.querySelector(".c3-chart-arcs-title").innerHTML = `
            <tspan class='donut-title donut-title-big' dy='-5' x='5' style='font-size: 46px; font-weight: 400;'>${trainingController.dataValue.assignments}</tspan>
            <tspan class='donut-title donut-title-small font-light' dy='35' x='0'>Assignments</tspan>`;
	}

	_refineDomainThreatsChart() {
		const variantElement = this.element.querySelector("[data-controller=\"charts--domain-threats\"]");

		if (variantElement == null) {
			return;
		}

		const variantController = variantElement["charts--domain-threats"];
		const variantChart      = variantController.chart;

		Array.from(variantChart.element.querySelectorAll(".c3-legend-item")).forEach(element => {
			element.querySelector("text").setAttribute("style", "font-size: 12px;");

		});

		variantChart.element.querySelector(".c3-chart-arcs-title").innerHTML = `
        <tspan class='donut-title donut-title-big' dy='-20' x='5' style='font-size: 46px; font-weight: 400;'>${variantController.totalThreats}</tspan>
        <tspan class='donut-title donut-title-small font-light' dy='35' x='0' style='font-size: 18px;'>Domain</tspan>
        <tspan class='donut-title donut-title-small font-light' dy='20' x='0' style='font-size: 18px;'>Threats</tspan>`;
	}

	_refineEmailThreatsChart() {
		const emailThreatElement = this.element.querySelector("[data-controller=\"charts--email-threats\"]");

		if (emailThreatElement == null) {
			return;
		}

		const emailThreatController = emailThreatElement["charts--email-threats"];
		const emailThreatChart      = emailThreatController.chart;

		Array.from(emailThreatChart.element.querySelectorAll(".c3-legend-item")).forEach(element => {
			element.querySelector("text").setAttribute("style", "font-size: 12px;");

		});

		emailThreatChart.element.querySelector(".c3-chart-arcs-title").innerHTML = `
        <tspan class='donut-title donut-title-big' dy='-20' x='5' style='font-size: 46px; font-weight: 400;'>${emailThreatController.totalThreats}</tspan>
        <tspan class='donut-title donut-title-small font-light' dy='35' x='0' style='font-size: 18px;'>Domain</tspan>
        <tspan class='donut-title donut-title-small font-light' dy='20' x='0' style='font-size: 18px;'>Threats</tspan>`;
	}
}
