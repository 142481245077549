import { Controller } from "@hotwired/stimulus";
import { DataTable } from "simple-datatables";

export default class SimpleDataTable extends Controller {
	static targets = ["table"];

	get rows() {
		return this.instance.data.data.length;
	}

	set rows(values) {
		this.instance.insert(values);
	}

	connect() {
		this.element[this.identifier] = this;
	}

	initialize() {
		const table = this.tableTarget;

		let tableName = table.dataset.name;
		this.instance = new DataTable(table, {
			prevText: "«",
			nextText: "»",
			labels: {
				placeholder: `Search ${tableName}`,
			},
			perPage: parseInt(table.dataset.perPage),
			perPageSelect: false,
			searchable: true,
			sortable: true,
			type: "html",
		});
	}

	remove(evt) {
		const row = evt.target.closest("tr[data-index]");
		this.instance.rows.remove(parseInt(row.dataset.index));

		const query = this.instance._searchQueries
		if (query.length > 0) {
			this.instance.search(query[0].term);
		}
	
		// dispatch event
		const event = new Event("dtRowRemoved");
		document.dispatchEvent(event);
	}

	removeAll() {
		const indexes = this.instance.data.data.map((_, i) => i);
		this.instance.rows.remove(indexes);
	}
}
