require("flatpickr/dist/flatpickr.min.js");

module.exports = {
    initRange($fromDate, $toDate, reverse = false) {
        var minDate, maxDate;

        if (reverse) {
            minDate = null
            maxDate = new Date().setHours(0, 0, 0, 0)
        } else {
            minDate = new Date().setHours(0, 0, 0, 0)
            maxDate = null
        }

        let options = {
            minDate: minDate,
            maxDate: maxDate,
            enableTime: false,
            dateFormat: "m/d/Y",
        }

        const $fromInstance = $fromDate.flatpickr({
            ...options,
            onChange: function (selectedDate) {
                const rMin = new Date(selectedDate)
                $toInstance.set("minDate", rMin);

                if ($toInstance.selectedDates.length == 0) {
                    $toInstance.setDate(rMin)
                }
            }
        })
        const $toInstance = $toDate.flatpickr({
            ...options,
            onReady: function (selectedDate, dateStr, instance) {
                const fromDate = new Date($fromInstance.input.value)
                const toDate = new Date(selectedDate)

                instance.set("minDate", fromDate);
                if (toDate < fromDate) {
                    instance.setDate(fromDate)
                }
            }
        })
    },
}