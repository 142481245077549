import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = [
		"checkbox", "checkall", "count", "search", "select", "list", "blankstate",
	];
	static values  = {};

	connect() {
		this.element[this.identifier] = this;

		// Disabling search box enter event
		this.searchTarget.addEventListener("keydown", function (e) {
			if (e.keyIdentifier === "U+000A" || e.keyIdentifier === "Enter" || e.keyCode === 13) {
				e.preventDefault();
				return false;
			}
		}, true);

		// Selecting checkbox from select tag.
		const values = Array.from(this.selectTarget.querySelectorAll("option:checked")).map(el => el.value);
		this.checkboxTargets.forEach(checkbox => {
			values.forEach(value => {
				if (checkbox.value === value) {
					checkbox.checked = true;
				}
			});
		});

		this.update();
	}

	/*
	 Show or hides the checkboxes based on the input.
	 */
	filter(evt) {
		if (evt.keyCode === 13) {
			evt.preventDefault();
			return;
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			// Loop through all list items, and hide those who don't match the search query
			for (let i = 0; i < this.checkboxTargets.length; i++) {
				const box      = this.checkboxTargets[i].parentElement;
				const label    = box.getElementsByTagName("label")[0];
				const txtValue = label.textContent || label.innerText;

				if (txtValue.toUpperCase().indexOf(evt.target.value.toUpperCase()) > -1) {
					box.classList.remove("hidden");
				} else {
					box.classList.add("hidden");
				}
			}

			const allHidden = this.checkboxTargets.every(target => target.parentElement.classList.contains("hidden"));
			if (allHidden) {
				this.listTarget.classList.add("hidden");
				this.blankstateTarget.classList.remove("hidden");
			} else {
				this.listTarget.classList.remove("hidden");
				this.blankstateTarget.classList.add("hidden");
			}

		}, 500);
	}

	/*
	 Toggle the selection for the select tag based on the checkbox selection.
	 */
	toggleSelect(evt) {
		const options      = Array.from(this.selectTarget.querySelectorAll("option"));
		const option       = options.filter(o => o.value === evt.target.value);
		option[0].selected = evt.target.checked;
	}

	/*
	 Marks or unmark all the checkbox targets in the controller.
	 */
	toggleAll() {
		const event = new Event("change");

		for (let i = 0; i < this.checkboxTargets.length; i++) {
			this.checkboxTargets[i].checked = this.checkallTarget.checked;
			this.checkboxTargets[i].dispatchEvent(event);
		}
	}

	/*
	 Updates the selection count target marked
	 on the number of checkboxes checked.
	 */
	update() {
		const checked = this.checkboxTargets.filter(checkbox => checkbox.checked);
		checked.length === this.checkboxTargets.length ? this.checkallTarget.checked = true : this.checkallTarget.checked = false;

		this.countTarget.innerText = checked.length;
	}
}
