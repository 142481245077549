import {Controller} from "@hotwired/stimulus";
import Fuse from "fuse.js";

const ICONS = {
    "COMPANY": `<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='size-6'>
    <path stroke-linecap='round' stroke-linejoin='round' d='M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21' />
</svg>`,
    "GROUP": `<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='size-6'>
    <path stroke-linecap='round' stroke-linejoin='round' d='M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z' />
</svg>
`,
};

export default class extends Controller {
    static targets = ["dialog", "list", "search", "menu", "itemTemplate", "menuTemplate", "noResultsTemplate", "shortcut", "container"];

    get currentElement() {
        return this._currentElement;
    }

    set currentElement(element) {
        this._currentElement = element;
    }

    get fuse() {
        return this._fuse;
    }

    set fuse(fuse) {
        this._fuse = fuse;
    }

    get host() {
        return this._host;
    }

    set host(host) {
        this._host = host;
    }

    connect() {
        if (!this.hasDialogTarget) {
            return;
        }

        this._initMenu();
        this._updateShortcut();
        this.host = this.dialogTarget.dataset.host;
        this.dialogTarget.removeAttribute("data-host");

        fetch("/admin/search")
            .then(response => response.json())
            .then(data => {
                const fd = JSON.parse(data);

                this.fuse = new Fuse(fd, {
                    location: 0,
                    distance: 100,
                    threshold: 0.4,
                    keys: ["name"],
                });
            })
            .catch(error => {
                console.error("Error:", error);
            });

        this.containerTargets.forEach(container => {
            container.addEventListener("click", (event) => {
                event.stopPropagation();
            });
        });
    }

    toggle(e) {
        e.preventDefault();
        this.dialogTarget.open ? this.dialogTarget.close() : this._handleOpen();
    }

    search() {
        this.listTarget.innerHTML = "";
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            const search = this.searchTarget.value;
            if (!search) {
                this._initMenu();
                this._highlightFirstElement();
                return;
            }

            const results = this.fuse.search(search);
            this.render(results);
        }, 250);
    }

    render(results) {
        if (!results.length) {
            const noResultsTemplate = this.noResultsTemplateTarget.cloneNode(true).content;
            this.listTarget.appendChild(noResultsTemplate);
            return;
        }

        results.slice(0, 10).forEach(item => {
            const itemTemplate = this.itemTemplateTarget.cloneNode(true).content;

            const itemLink = itemTemplate.querySelector("a");
            itemLink.href  = this.host + "/" + item.item.link;

            const span                      = itemTemplate.querySelector("span");
            span.lastElementChild.innerHTML = item.item.name;

            if (item.item.link.includes("admin/partners/list")) {
                const badge = document.createElement("span");
                badge.classList.add("badge", "badge-inverse", "text-xxs", "bg-gray-300", "text-slate-600");
                badge.innerText = "MSP";

                span.appendChild(badge);
            }

            const icon     = ICONS[item.item.kind];
            span.innerHTML = icon + span.innerHTML;

            this.listTarget.appendChild(itemTemplate);
        });

        this._highlightFirstElement();
    }

    navigateDown() {
        this._navigate((current, first, last) => current === last ? first : current.nextElementSibling);
    }

    navigateUp() {
        this._navigate((current, first, last) => current === first ? last : current.previousElementSibling);
    }

    navigateTo() {
        const href = this.currentElement.href;

        if (!href) {
            return;
        }

        if (!href.includes(this.host)) {
            return window.open(href, "_blank");
        }

        this.dialogTarget.close();
        window.location.href = href;
    }

    close() {
        this.dialogTarget.close();
    }

    _initMenu() {
        const menuTemplate = this.menuTemplateTarget.cloneNode(true).content;
        this.listTarget.appendChild(menuTemplate);
    }

    _handleOpen() {
        const previousActive = document.querySelector(".gs-active");
        if (previousActive) {
            previousActive.classList.remove("gs-active");
        }

        this._highlightFirstElement();
        this.dialogTarget.showModal();
    }

    _highlightFirstElement() {
        const firstElement = this.listTarget.firstElementChild;
        if (firstElement) {
            this.currentElement = firstElement;
            firstElement.classList.add("gs-active");
        }
    }

    _navigate(getNextElement) {
        const {firstElementChild: first, lastElementChild: last} = this.listTarget;
        const current                                            = this.currentElement;

        if (current) {
            current.classList.remove("gs-active");
        }

        const nextElement = getNextElement(current, first, last);
        if (nextElement) {
            nextElement.classList.add("gs-active");
            this.currentElement = nextElement;
        }
    }

    _updateShortcut() {
        const isMac = navigator.userAgent.indexOf("Mac OS") !== -1;
        if (!isMac) {
            return;
        }

        this.shortcutTarget.lastElementChild.innerText = "⌘+K";
    }
}
