const IMAGE_UPLOAD_PATH = "/communications_template/image_upload/";
const IMAGE_MAX_WIDTH = 700;

module.exports = {
    fileAttachment(attachment) {
        this._uploadFile(attachment.file, setProgress, setAttributes)

        function setProgress(progress) {
            attachment.setUploadProgress(progress)
        }

        function setAttributes(attributes) {
            attachment.setAttributes(attributes)
        }
    },

    _uploadFile(file, progressCallback, successCallback) {
        const formData = this._createFormData(file)
        const xhr = new XMLHttpRequest()

        xhr.open("POST", IMAGE_UPLOAD_PATH, true)

        xhr.upload.addEventListener("progress", function (event) {
            const progress = event.loaded / event.total * 100
            progressCallback(progress)
        })

        xhr.addEventListener("load", () => {
            if (xhr.status == 204) {
                const url =  `${location.origin}/storage/tmp/${file.name}`;
                const img = this._createImgObject(url);

                const attributes = {
                    url: img.src,
                    width: img.width,
                }

                successCallback(attributes)
            }
        })

        xhr.send(formData)
    },

    _createFormData(file) {
        const data = new FormData();
        const token = document.querySelector('meta[name=csrf-token]').attributes.content.textContent;
        const param = document.querySelector('meta[name=csrf-param]').attributes.content.textContent;

        data.append(param, token)
        data.append("Content-Type", "multipart/form-data")
        data.append("file", file)

        return data
    },

    _createImgObject(imgURL) {
        let img = document.createElement("img");
        img.src = imgURL

        if (img.width > IMAGE_MAX_WIDTH) {
            img.width = IMAGE_MAX_WIDTH
        }

        return img
    },
}
