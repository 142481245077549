import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["actionsList", "counter", "itemList", "itemCheckbox", "selectAllCheckbox", "selectionBar"]
    static values = {
        allEntriesEnabled: Boolean,
    }

    initialize() {
        this.element[this.identifier] = this;

        if (this._hasSelectedEntries()) {
            this.itemCheckboxTargets.forEach(element => this.setSelection(element.checked, element))
            this.toggleSelectionBar()
        }
    }

    selectItem(event) {
        this._deactiveAllEntriesBanner()
        this.setSelection(event.target.checked, event.target)

        this.listCount == this.selectedItems ? this.selectAllCheckboxTarget.checked = true : this.selectAllCheckboxTarget.checked = false;
    }

    selectAll() {
        const isChecked = this.selectAllCheckboxTarget.checked;

        this.itemCheckboxTargets.forEach(element => {
            this.setSelection(isChecked, element)
        });

        (isChecked && this.selectedItems < this.totalEntriesSize) ? this._activeAllEntriesBanner() : this._deactiveAllEntriesBanner();
    }

    clearSelection() {
        this._deactiveAllEntriesBanner()
        this.selectAllCheckboxTarget.checked = false;
        this.itemCheckboxTargets.forEach(element => this.setSelection(false, element));
    }

    setSelection(isChecked, target) {
        const liItem = target.parentElement.parentElement;

        target.checked = isChecked;
        isChecked ? liItem.classList.add("bulk-item-selected") : liItem.classList.remove("bulk-item-selected");

        this.updateCounter();
    }

    selectAllEntries() {
        this._deactiveAllEntriesBanner();
        const bulkActions = this.actionsListTarget.getElementsByTagName("a");

        for (let i = 0; i < bulkActions.length; i++) {
            const linkTag = bulkActions[i];

            if (linkTag.href == "") {
                continue
            }

            linkTag.href = this._getUrlWithParams(linkTag.href);
        }

        this.selectedItems = this.totalEntriesSize
        this.allEntriesEnabled = true
    }

    updateCounter() {
        this.counterTarget.innerHTML = this.selectedItems
    }

    toggleSelectionBar() {
        this.selectionBarTarget.classList.remove("has-selections")
        $('.spread-dropdown, .hide-button').show()
        $('.spread-button, .hide-button').show()

        if (this.selectedItems > 0) {
            this.selectionBarTarget.classList.add("has-selections")
            $('.spread-dropdown, .hide-button').hide()
            $('.spread-button, .hide-button').hide()
        }
    }

    get listCount() {
        return this.itemListTarget.children.length
    }

    set selectedItems(value) {
        this.counterTarget.innerHTML = value
    }

    get selectedItems() {
        return this.itemListTarget.querySelectorAll('input:checked').length
    }

    get totalEntriesSize() {
        return parseInt(document.getElementById('total-entry-size').textContent.replace(/\,/g, ""))
    }

    _getUrlWithParams(path) {
        const searchParams = new URLSearchParams(window.location.search);
        const url = new URL(path);

        // Appending allEntries Param
        url.searchParams.append('allEntries', true);

        // appending search params
        for (const [key, value] of searchParams) {
            url.searchParams.append(key, value);
        }

        return url.href
    }

    _hasSelectedEntries() {
        return this.itemCheckboxTargets.some(target => {
            return target.checked
        })
    }

    _deactiveAllEntriesBanner() {
        const banner = document.getElementById("select-all-banner")
        banner.classList.add("d-none")

        while (banner.firstChild) {
            banner.removeChild(banner.firstChild)
        }

        const bulkActions = this.actionsListTarget.getElementsByTagName("a")
        for (let i = 0; i < bulkActions.length; i++) {
            const linkTag = bulkActions[i];

            if (linkTag.href == "") {
                continue
            }

            let linkURL = new URL(linkTag.href);
            let url = new URL(window.location);

            // Removing manually allEntries Param
            linkURL.searchParams.delete("allEntries");

            // Removing all other params on the current URL
            for (var key of url.searchParams.keys()) {
                linkURL.searchParams.delete(key);
            }
            // Update the URL
            linkTag.href = linkURL.href

        }
    }

    _activeAllEntriesBanner() {
        const banner = document.getElementById("select-all-banner")
        banner.classList.remove("d-none")

        const selectedSpan = document.createElement("span")
        selectedSpan.innerHTML = `All <strong class="font-strong">${this.selectedItems}</strong> ${banner.dataset.name} on this page are selected.`

        const allEntriesSpan = document.createElement("span")
        allEntriesSpan.classList.add("mx-2", "text-info", "pointer-cursor")
        allEntriesSpan.dataset.action = "click->bulk-selection#selectAllEntries"
        allEntriesSpan.innerHTML = `<u>Select all ${this.totalEntriesSize} in the list</u>`

        banner.appendChild(selectedSpan)
        banner.appendChild(allEntriesSpan)
    }
}
