import { Controller } from "@hotwired/stimulus";

export default class PreviewTemplate extends Controller {
	static targets = ["content"];

	connect() {
		this.element[this.identifier] = this;
	}

	initialize() {
		this.highlight();
	}

	// highlight all the words that are
	// contained between the <em> tag.
	highlight() {
		const regexes = [
			/(\{\{user\.FirstName}})/g,
			/(\{\{user\.LastName}})/g,
			/(\{\{user\.Email}})/g,
			/(\{\{user\.Title}})/g,
			/(\{\{company\.Name}})/g,
		];

		let innerHTML = this.element.innerHTML;

		regexes.forEach(regex => {
			innerHTML = innerHTML.replaceAll(regex, `<mark class='bg-orange-100'>$1</mark>`);
		});

		this.element.innerHTML = innerHTML;
	}
}
