import { Controller } from "@hotwired/stimulus";
import reorderIndexFor from "../../reorder_input_index";

export default class extends Controller {
	static targets = ["template", "list", "removeBtn"];
	static classes = ["removeBtn"];

	get childCount() {
		return this.listTarget.childElementCount;
	}

	connect() {
		this.element[this.identifier] = this;

		// Adding new row if empty.
		if (this.childCount === 0) {
			this.addRow();
		}

		updateRemoveIcon(this.removeBtnTargets, this.childCount, this.removeBtnClass);
	}

	/**
	 * adds a new row to the list target.
	 */
	addRow() {
		const tmpl = this.templateTarget.content.cloneNode(true);
		this.listTarget.append(tmpl);
		reorderIndexFor(this.listTarget);
		updateRemoveIcon(this.removeBtnTargets, this.childCount, this.removeBtnClass);
	}

	/**
	 * removes a row from the list target.
	 * @param event {Event} the event received.
	 */
	removeRow({target: target}) {
		// Not removing if only 1 child
		if (this.childCount === 1) {
			return;
		}

		const row = target.parentElement;
		row.remove();
		reorderIndexFor(this.listTarget);
		updateRemoveIcon(this.removeBtnTargets, this.childCount, this.removeBtnClass);
	}
}

/**
 * removes a row from the list target.
 * @param targets {NodeListOf} the event received.
 * @param count {Number} the current items count.
 * @param hideClass {String} the class used for hiding the remove button.
 */
function updateRemoveIcon(targets, count, hideClass) {
	targets.forEach(target => {
		if (count === 1) {
			targets[0].classList.add(hideClass);
			return;
		}

		target.classList.remove(hideClass);
	});
}
