import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
	static targets = ["menu"];

	connect() {
		useClickOutside(this);
	}

	toggle() {
		this.menuTarget.classList.toggle("hidden");
	}

	clickOutside(event) {
		if (!this.element.contains(event.target)) {
			this.menuTarget.classList.add("hidden");
		}
	}
}
