import { Controller } from "@hotwired/stimulus"

const custom = "custom"
export default class extends Controller {
    static targets = [
        "weekdays", "selectWeekdays", "weekDaysBox",
        "selectPeriod", "timePeriodBox", "period"
    ]

    initialize() {
        if (this._timePeriod.value == custom) {
            this.togglePeriod()
        }

        if (this._weekdays.value == custom) {
            this.toggleWeekdays()
        }
    }

    togglePeriod() {
        this._toggleElement(this.timePeriodBoxTarget)
    }

    toggleWeekdays() {
        this._toggleElement(this.weekDaysBoxTarget)
    }

    _toggleElement(element) {
        element.classList.contains('d-none') ? element.classList.remove("d-none") : element.classList.add("d-none")
    }

    get _timePeriod() {
        return this.selectPeriodTargets.filter(input => input.checked)[0]
    }

    get _weekdays() {
        return this.selectWeekdaysTargets.filter(input => input.checked)[0]
    }
}