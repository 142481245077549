import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["allCompaniesBox", "specificCompaniesBox", "deactivateButton", "cancelButton", "addSave"];
  static values = { selectedOption: String };

  connect() {
    const specificCompaniesRadio = document.getElementById('specific-companies');
    const allCompaniesRadio = document.getElementById('all-companies');
    const allCompanies = document.getElementById('all-categories-message');
    if (specificCompaniesRadio) {
        specificCompaniesRadio.checked = true;
    }

    if (allCompanies) {
      allCompaniesRadio.checked = true;
    }

    this.toggleContainers();

    allCompaniesRadio.addEventListener("change", () => {
      this.toggleMethodField();
  });
  }

  toggleContainers() {
    const allCompaniesBox = this.allCompaniesBoxTarget;
    const specificCompaniesBox = this.specificCompaniesBoxTarget;
    const allCompaniesRadio = document.getElementById('all-companies');
    const specificCompaniesRadio = document.getElementById('specific-companies');

    if (allCompaniesRadio.checked) {
        allCompaniesBox.classList.remove('hidden');
        specificCompaniesBox.classList.add('hidden');
    } else if (specificCompaniesRadio.checked) {
        allCompaniesBox.classList.add('hidden');
        specificCompaniesBox.classList.remove('hidden');
    }

  }
  deactivateButton() {
    const a = this.element.parentElement.parentElement.querySelector("#deactivateButton")
    const b = this.element.parentElement.parentElement.querySelector("#fullSize")

    a.classList.add("hidden")
    b.classList.add("w-full")
  }

  cancelButton(){
    const a = this.element.parentElement.parentElement.querySelector("#deactivateButton")
    a.classList.remove("hidden")
  }

  toggleMethodField() {
    const methodField = document.querySelector('input[name="CyberChampsCategoriesMethod"]');
    const allCompaniesRadio = document.getElementById('all-companies');

    if (allCompaniesRadio.checked) {
        methodField.value = "ALL";
    } else {
        methodField.value = "CUSTOM";
    }
}
}