import { Controller } from "@hotwired/stimulus"

export default class TargetCurriculum extends Controller {
    static targets = ["list", "select", "summary", "customizable"];
    static values = { id: String }

    initialize() {
        this.element[this.identifier] = this;

        if (this.hasSelectTarget) {
            Promise.resolve().then(() => {
                this._setCurriculumChoices();
            })
        }
    }

    /**
     * inserts the curriculum content into the Curriculum Content box.
    */
    addCurriculum() {
        if (this.contentIsBeingEdited) {
            this.toggleUI();
        }

        // Removing Previous HTML
        this.summaryTarget.innerHTML = "";
        this.customizableTarget.classList.add("d-none");

        const curriculumID = this.choice.getValue(true)
        if (curriculumID == "") {
            return
        }

        const cid = this.idValue;
        fetch(`/curriculums/${curriculumID}/monthly_summary_form/?company_id=${cid}`)
            .then(response => {
                if (response.ok) {
                    return response.text()
                }

                return `<div class="alert alert-danger" role="alert">An error was encountered while loading curriculum program</div>`
            })
            .then(response => {
                this.summaryTarget.insertAdjacentHTML('beforeend', response);
                this.customizableTarget.classList.remove("d-none");
                this.campaignScheduler.updateContentMonths();

                const button = document.getElementById("FinishCurriculumStep");
                button.disabled = false;
            })
    }

    /**
     * discard all the changes done when for all months.
    */
    cancel() {
        this.months.forEach(month => month.cancel());
    }

    /**
     * saves all months month content selection.
    */
    customize() {
        // Opening first available accordion.
        const accordion = this.element.querySelector('[data-controller~=components--accordion][data-editable=true]')["components--accordion"];
        accordion.open();

        this.months.forEach(month => month.customize());
    }

    /**
     * saves the selection for each month.
    */
    saveChanges() {
        this.months.forEach(month => month.saveChanges());
    }

    /**
     * changes the UI when the user click customize content
     * by disabling the done button if is being edited.
    */
    toggleUI() {
        this.element.classList.toggle("editing");

        if (this.contentIsBeingEdited) {
            this.symbolStepper.lockStep(2);
            return
        }

        this.symbolStepper.unlockStep(2);
    }

    _setCurriculumChoices() {
        const choices = Array.from(this.listTarget.children).map((group, index) => {
            if (index == 0) {
                return {
                    id: index + 1,
                    disabled: true,
                    choices: [{
                        selected: true,
                        value: "",
                        label: "Select a Curriculum from the list"
                    }]
                }
            }

            const curriculums = Array.from(group.children).map(curriculum => {
                return {
                    selected: curriculum.dataset.selected == "true",
                    value: curriculum.value,
                    label: curriculum.label,
                }
            })

            return {
                label: group.label,
                id: index + 1,
                disabled: false,
                choices: curriculums
            }
        })

        this.listTarget.remove();
        this.choice.setChoices(choices, 'value', 'label', false);

        if (this.choice.getValue(true) != "") {
            this.addCurriculum()
        }
    }

    get choice() {
        if (!this.hasSelectTarget) {
            return
        }

        return this.selectTarget.choices.instance
    }

    get contentIsBeingEdited() {
        return this.element.classList.contains('editing');
    }

    get symbolStepper() {
        const parent = this.element.closest('[data-controller~=symbol-stepper]')["symbol-stepper"];
        return parent
    }

    get campaignScheduler() {
        const parent = this.element.closest('[data-controller~=campaign-scheduler]')["campaign-scheduler"];
        return parent
    }

    get months() {
        const children = this.element.querySelectorAll('[data-controller~=longcampaigns--month]');
        return Array.from(children).map(child => child["longcampaigns--month"])
    }

    get valid() {
        let courses = 0;
        let templates = 0;

        this.months.forEach(month => {
            courses += month.assetsCount;
            templates += month.templatesCount;
        })

        return courses > 0 || templates > 0
    }
}