import { Controller } from "@hotwired/stimulus"
import c3 from 'c3'

export default class extends Controller {
    static values = {
        data: Object,
        url: String,
    }

    connect() {
        this.element[this.identifier] = this;
        this.load();
    }

    load() {
        const element = this.element;

        let showTooltip = true;
        let color = ['#17396D', '#56CE61', '#794CF8', '#1980EA']
        let columns = [
            ["Mobile", this.dataValue.mobile],
            ["Desktop", this.dataValue.desktop],
            ["Ipad", this.dataValue.ipad],
            ["Others", this.dataValue.others]
        ]

        if (this.dataValue.mobile == 0 && this.dataValue.desktop == 0 && this.dataValue.ipad == 0 && this.dataValue.others == 0) {
            color = ['#a6b7bf']
            columns = [
                ["Empty", 100]
            ]
            showTooltip = false
        }

        let options = {
            bindto: element,
            size: {
                height: element.dataset.height,
            },
            data: {
                columns: columns,
                type: 'donut',
            },
            donut: {
                title: "Visits",
                width: element.dataset.width,
                label: {
                    show: false
                }
            },
            legend: {
                hide: true,
            },
            color: {
                pattern: color,
            },
            tooltip: {
                show: showTooltip,
            }
        };

        this.chart = c3.generate(options);
    }
}
