import { Controller } from "@hotwired/stimulus";
import validateInput from "../../validate_input";
import reorderIndexFor from "../../reorder_input_index";

export default class extends Controller {
	static targets = ["list", "template", "count", "addRowBtn", "required"];
	static values  = {
		count: {type: Number, default: 0},
	};

	get rowCount() {
		return this.listTarget.children.length;
	}

	/**
	 * initialize the controller for the current object
	 */
	initialize() {
		if (this.rowCount === 0) {
			this.addRow();
		}

		this.validateForm();
	}

	/**
	 * adds a new row to the user form list.
	 */
	addRow() {
		const newRow = this.templateTarget.content.cloneNode(true);

		this.listTarget.append(newRow);
		this.countValue++;
	}

	/**
	 *
	 * @param {Number} value - The current number of users on the list
	 * @param {Number} previousValue - The previous number of users on the list
	 */
	countValueChanged(value, previousValue) {
		if (this.hasCountTarget) {
			this.countTarget.innerText = value;
		}

		value > 1 ? this.listTarget.classList.add("has-multiple") : this.listTarget.classList.remove("has-multiple");

		this.validateForm();
		reorderIndexFor(this.listTarget);
	}

	/**
	 * removes the selected row from the user form list
	 * @param {Object} evt
	 */
	removeRow({target}) {
		if (this.rowCount === 1) {
			return;
		}

		let userRow = target.parentElement.parentElement;
		userRow.remove();

		// Removing lingering tooltips
		const tooltips = document.getElementsByClassName("tooltip");
		Array.from(tooltips).forEach(tooltip => tooltip.remove());

		this.countValue--;
	}

	/**
	 * Validates if the form is valid to be submitted and/or to add a new row.
	 */
	validateForm() {
		const invalid   = this.requiredTargets.some(element => !element.checkValidity());
		const submitBtn = document.getElementById("btn-submit");

		if (invalid) {
			if (this.hasAddRowBtnTarget) {
				this.addRowBtnTarget.disabled = true;
			}

			submitBtn.disabled = true;
		} else {
			if (this.hasAddRowBtnTarget) {
				this.addRowBtnTarget.disabled = false;
			}

			submitBtn.disabled = false;
		}
	}

	/**
	 * Validates the current input.
	 * @param target - the input that is being validated on event.
	 */
	validateInput({target}) {
		validateInput(target);
		this.validateForm();
	}
}
