import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr/dist/flatpickr";

export default class DueDate extends Controller {
    static targets = ["input"]

    connect() {
        const datepicker = this.inputTarget;

        flatpickr(datepicker, {
            minDate: new Date(),
            onChange: function (selectedDates) {
                datepicker.value = selectedDates[0].toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                });
            },
        })
    }
}