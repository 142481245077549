import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["list", "bar"];
	static values  = {
		maxItems: Number,
	};

	initialize() {
		const mutationObserver = new MutationObserver(mutations => {
			mutations.some(_ => this._toggleVisibility());
		});

		mutationObserver.observe(this.listTarget, {attributes: false, childList: true});
	}

	toggle() {
		const bar        = this.barTarget.firstElementChild;
		const barClasses = this.listTarget.classList;
		const icon       = bar.firstElementChild;

		barClasses.toggle("show-content");
		barClasses.contains("show-content") ? (
			icon.classList.replace("fa-plus-square", "fa-minus-square"),
				bar.lastChild.nodeValue = "Less"
		) : (
			icon.classList.replace("fa-minus-square", "fa-plus-square"),
				bar.lastChild.nodeValue = "More"
		);
	}

	_toggleVisibility() {
		this.listTarget.childElementCount > this.maxItemsValue ? (this.barTarget.classList.remove("d-none"), this._updateBarValues()) : this.barTarget.classList.add("d-none");
	}

	_updateBarValues() {
		const invisibleItems     = this.barTarget.getElementsByTagName("span")[0];
		invisibleItems.innerText = this.listTarget.childElementCount - this.maxItemsValue;
	}
}