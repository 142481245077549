import { Controller } from "@hotwired/stimulus";
import { parseCategories } from "./categories";
import Choices from "choices.js";

export default class extends Controller {
	static targets = ["policy"];
	static outlets = ["onboarding--modal"];
	static values  = {
		data: Object,
	};

	connect() {
		// Initializing choices instance
		const choiceTarget = document.querySelector("select[name=\"Policies\"]");
		const values       = this.dataValue.selectedPolicies.map(policy => policy.id);
		this.policyChoices = initChoices(choiceTarget, values);

		// Selecting policies with its categories if any
		this.dataValue.selectedPolicies.forEach(policy => {
			this.select({detail: {value: policy.id}});

			const target     = this.policyTargets.find(target => policy.id === target.dataset.value);
			const categories = policy.categories;
			this.updateCategories({target: target, detail: {categories: categories}});
		});

		// Updating the target selection UI based on the current value.
		this.updateTargetSelection();
		// Removing dataset from HTML
		this.element.removeAttribute("data-onboarding--policies-data-value");
	}

	/**
	 * Deselects a policy from the associated list.
	 * @param {HTMLElement} target - The event target that triggered the removal of the list item.
	 * @method deselect
	 * @description Removes the active policy items with the same value as the removed list item and then removes the item itself from the policy list.
	 */
	deselect({target: target}) {
		const item = target.closest("[data-onboarding--policies-target]");
		this.policyChoices.removeActiveItemsByValue(item.dataset.value);
		item.remove();
		// Hidding selections
		Array.from(this.policyChoices.itemList.element.children).forEach(child => child.style.display = "none");
	}

	/**
	 * Selects the given target and adds it to the current selection.
	 * @param {String} value - The value from the selected item.
	 * @method select
	 * @description Triggers the selection of the given target.
	 * Extract the data from choicesJS and then call 'addPolicyToList()' to add it to the current selection.
	 */
	select({detail: {value: value}}) {
		const selected = this.policyChoices.getValue().find(choice => choice.value === value);
		addPolicyToList(selected.label, selected.value);
		// Hidding selections
		Array.from(this.policyChoices.itemList.element.children).forEach(child => child.style.display = "none");
	}

	/**
	 * Opens the category selection modal.
	 * @param {HTMLElement} opener - The target element that triggered the opening of the modal.
	 * @method openModal
	 * @description Opens the categories modal outlet with the provided opener,
        allowing it to be displayed and interacted with by the user.
	 */
	openModal({target: opener}) {
		const target = opener.closest("[data-onboarding--policies-target]");
		this.onboardingModalOutlet.open(target);
	}

	/**
	 * Updates the policy assignment categories based on the data submited by the categories modal.
	 * @param {Object} - The event object containing the target and category data.
	 * @method updateCategories
	 * @description Parses the provided category values and updates the policy assignment categories list with the parsed results.
	 */
	updateCategories({target: target, detail: {categories: values}}) {
		let inputName = `PolicyAssignations[${target.dataset.value}]`;
		if (this.targetValue === "ALL") {
			inputName = "AllPoliciesCategories";
		}

		const categories = parseCategories(inputName, values);
		const list       = target.querySelector("[data-name=\"policy-assignment-categories\"]");
		list.innerHTML   = categories;
	}

	/**
	 * @param {Event} evt - The value of target that determines which policies to display.
	 * @method updateTargetSelection
	 * @description Updates the target selection based on the value of the target selector.
	 * If the value is "CUSTOM", shows the custom policies and hides all policies. If the value is "ALL", shows all policies and hides custom policies.
	 */
	updateTargetSelection(evt) {
		this.targetValue = this.dataValue.target;
		if (evt !== undefined) {
			this.targetValue = evt.target.value;
		}

		const customPoliciesTarget = document.getElementById("custom-policies-target");
		const allPoliciesTarget    = document.getElementById("all-policies-target");

		if (this.targetValue === "CUSTOM") {
			customPoliciesTarget.classList.remove("hidden");
			allPoliciesTarget.classList.add("hidden");
		}

		if (this.targetValue === "ALL") {
			customPoliciesTarget.classList.add("hidden");
			allPoliciesTarget.classList.remove("hidden");
		}
	}
}

/**
 * Adds a new policy to the policy list.
 * @param {string} name - The name of the policy.
 * @param {string} value - The value associated with the policy.
 * @method addPolicyToList
 * @description Creates a new policy item based on the given name and value, and appends it to the policy list.
 * Populates the template with the provided values.
 */
function addPolicyToList(name, value) {
	const list = document.getElementById("policies-list");
	const tmpl = document.getElementById("policy-item-template");
	const item = tmpl.content.cloneNode(true);

	item.firstElementChild.dataset.value                  = value;
	item.querySelector("[data-name=\"title\"]").innerText = name;
	list.append(item);
}

/**
 * Initializes the Choices component with the given target and values.
 * This function sets up the Choices component with a default configuration
 * and populates it with the provided values.
 * The resulting "Choices" instance is then assigned to the target object.
 *
 * @param {HTMLElement} target - The target object that will receive the initialized Choices instance.
 * @param {Array<String>} values - The array of values to be used to populate the Choices component.
 * @returns {Choices} The initialized Choices instance.
 */
function initChoices(target, values) {
	if (target === null) return;

	const config = {
		noChoicesText: `No more policies to filter by`,
		placeholder: true,
		placeholderValue: "Search Policies",
		removeItemButton: true,
		resetScrollPosition: false,
		maxItemCount: 20,
		maxItemText: (maxItemCount) => {
			return `Max ${maxItemCount} policies can be selected.`;
		},
	};

	const choices = new Choices(target, config);
	choices.setChoiceByValue(values);
	target["choices"] = choices;

	return choices;
}
