import { Controller } from "@hotwired/stimulus"

export default class BasicBulkSelection extends Controller {
    static targets = ["table", "itemCheckbox", "selectAllCheckbox"]

    connect() { }

    selectAll(event) {
        this.selectAllEntries(event.target.checked, this.itemCheckboxTargets)
    }

    selectAllEntries(checked, entries) {
        entries.forEach(entry => {
            entry.checked = checked
        });
    }
}