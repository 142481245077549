import { Controller } from "@hotwired/stimulus";
import { computePosition } from "@floating-ui/dom";

export default class extends Controller {
    static targets = ["input"];
    timer = null;

    connect() {
        this._initTooltip();
    }

    copy(e) {
        navigator.clipboard.writeText(this.inputTarget.value)
        this.inputTarget.select()

        document.body.appendChild(this.tooltip);
        computePosition(e.currentTarget, this.tooltip, {
            placement: "top",
        }).then(({x, y}) => {
            Object.assign(this.tooltip.style, {
                left: `${x}px`,
                top: `${y}px`,
                position: "absolute",
            });
        });

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.tooltip.remove();
        }, 1000);
    }

    _initTooltip() {
        const tooltip = document.createElement("div");
		tooltip.classList.add("tooltip", "fade", "show", "bs-tooltip-top", "absolute");

		const inner = document.createElement("div");
		inner.classList.add("tooltip-inner");
		inner.innerHTML = "Copied";

		const arrow = document.createElement("div");
		arrow.classList.add("arrow", "left-1/2");
		arrow.style.transform     = "translate(-50%, 0%)";
		arrow.dataset.popperArrow = "";

		tooltip.append(arrow, inner);
		this.tooltip = tooltip;
    }
}