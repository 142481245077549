import {Controller} from "@hotwired/stimulus";
import Choices from "choices.js";

export default class DateRangeController extends Controller {
    static targets = ["from", "to"];

    connect() {
        // Removing the option 11PM from the "From" Target
        Array.from(this.fromTarget.options).forEach(option => parseInt(option.value) === 23 ? option.remove() : null);
        // Removing the option 12AM from the "To" Target
        Array.from(this.toTarget.options).forEach(option => parseInt(option.value) === 0 ? option.remove() : null);

        [this.fromChoices, this.toChoices] = initializeTargets(this.fromTarget, this.toTarget);
        this.update();
    }
    
    update() {
        let fromValue = parseInt(this.fromChoices.getValue(true));
        let toValue = parseInt(this.toChoices.getValue(true));
        const initialChoices = this.toChoices._initialState.choices;

        const newToChoices = initialChoices.map(choice => {
            choice.disabled = false;
            choice.selected = false;

            if (parseInt(choice.value) <= fromValue) {
                choice.disabled = true;
            }

            if (fromValue >= toValue && parseInt(choice.value) - 1 === fromValue) {
                choice.selected = true;
            }

            if (parseInt(choice.value) === toValue) {
                choice.selected = true;
            }

            return choice;
        });

        this.toChoices.setChoices(newToChoices, "value", "label", true);
    }
}

/**
 * Initializes the Choices component with the given target and values.
 * This function sets up the Choices component with a default configuration
 * and populates it with the provided values.
 * The resulting "Choices" instance is then assigned to the target objects.
 *
 * @param {HTMLElement} from - The "from" target object that will receive the initialized Choices instance.
 * @param {HTMLElement} to - The "to" target object that will receive the initialized Choices instance.
 * @param {Array<String>} values - The array of values to be used to populate the Choices component.
 * @returns {Choices, Choices} The initialized Choices instances.
 */
function initializeTargets(from, to) {
    const opts = {
        searchEnabled: false,
        shouldSort: true,
        sortFn: (a, b) => (parseInt(b.value)) < (parseInt(a.value)) ? 1 : -1,
    };

    const fromChoices = new Choices(from, opts);
    const toChoices = new Choices(to, opts);

    return [fromChoices, toChoices];
}
