/**
 * sets the correct index for each of the inputs.
 * @param {HTMLElement} list - The form where course will be submitted.
 */
function reorderIndexFor(list) {
    Array.from(list.children).forEach((item, index) => {
        item.id            = `row-${index}`;
        item.dataset.index = `${index}`;

        if (item.nodeName === "INPUT") {
            replace(item, index);
            return;
        }

        const inputs = item.querySelectorAll("input:not(.choices__input), select");
        Array.from(inputs).forEach(input => {
            replace(input, index);
        });
    });
}

export default reorderIndexFor;

function replace(input, index) {
    input.id   = input.id.replace(/\[(.*)]/g, `[${index}]`);
    input.name = input.name.replace(/\[(.*)]/g, `[${index}]`);
}
