import { Controller } from "@hotwired/stimulus"

export default class DashboardController extends Controller {
    static targets = ["period"]

    initialize() {
        this.element[this.identifier] = this;
    }

    update() {
        const controllers = this.element.querySelectorAll('[data-controller="content-loader"]');
        controllers.forEach(controller => {
            const loader = controller["content-loader"];
            const url = new URL(loader.urlValue, window.location.origin);
            const param = new URLSearchParams();

            param.set("period", this.periodTarget.value)
            url.search = param;

            loader.urlValue = url.toString();
            loader.load();
        })
    }
}