import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

/**
 * Holds the descriptions that will be displayed based on the signature type
 * @type {Map}
 * @const
 */
const descriptions = new Map([
	["text", "Use your name as a signature:"],
	["hand", "Draw your signature below using your mouse or trackpad:"],
]);

/**
 * Holds the texts that will be displayed based on the signature type
 * @type {Map}
 * @const
 */
const tooglerTexts = new Map([
	["text", "Or draw your signature"],
	["hand", "Use your name as a signature"],
]);

export default class SignatureModal extends Controller {
	static targets = ["font", "description", "canvas", "toggler", "cancelBtn", "clearBtn", "submitBtn", "nameInput", "wrapper"];
	static values  = {
		type: String,
	};

	initialize() {
		// Loading custom fonts for the signature
		this.allisonFont    = new FontFace("Allison", "url(https://fonts.gstatic.com/s/allison/v9/X7nl4b88AP2nkbvZCCGa4ebjEgg.woff2)");
		this.caveatFont     = new FontFace("Caveat", "url(https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIWpZz5Kmgq3sw.woff)");
		this.monteCarloFont = new FontFace("MonteCarlo", "url(https://fonts.gstatic.com/s/montecarlo/v7/buEzpo6-f9X01GadLA0G4C0f_f5Iai0.woff2)");

		document.fonts.add(this.allisonFont);
		document.fonts.add(this.caveatFont);
		document.fonts.add(this.monteCarloFont);

		this.allisonFont.load();
		this.caveatFont.load();
	}

	/**
	 * Connects performs the following action after connecting the controller:
	 * - Initializes SignaturePad
	 * - Listens when drawing on the pad.
	 * - Unbinds all event handlers
	 * - Cleans up the user's name
	 */
	connect() {
		this.pad = new SignaturePad(this.canvasTarget);
		this.pad.addEventListener("endStroke", this.validatePad);
		this.pad.off();
		this.nameInputTarget.value = cleanTextInputField(this.nameInputTarget.value);
		this.originalName          = this.nameInputTarget.value;

		// Waiting for the main font to load to draw on canvas
		this.monteCarloFont.load().then(() => {
			// Generating text canvas by default
			generateCanvasText(this.canvasTarget, this.nameInputTarget.value, this.fontTarget.value);
		});
	}

	/**
	 * prevents the user to paste text with emojis or not allowed characters.
	 */
	cleanInputOnPaste = evt => {
		// Stop data actually being pasted into div
		evt.stopPropagation();
		evt.preventDefault();

		const clipboardData = evt.clipboardData;
		const pastedData    = clipboardData.getData("Text");

		this.nameInputTarget.value = cleanTextInputField(pastedData);
	};

	/**
	 * resets the signature pad as empty.
	 */
	clearPad() {
		this.pad.clear();

		if (this.typeValue === "text") {
			this.validateCanvas();
		}

		if (this.typeValue === "hand") {
			this.validatePad();
		}
	}

	/**
	 * saves the signature and disconnects the signature modal controller.
	 */
	submit() {
		if (this.nameInputTarget.value === "") {
			this.nameInputTarget.value = this.originalName;
		}

		this.signatureData = cropSignatureCanvas(this.canvasTarget, this.wrapperTarget).toDataURL();

		this.canvasTarget.width  = 720;
		this.canvasTarget.height = 180;

		isText = true;

		this.toTextSignature();
	}

	/**
	 * changes the signature pad to Draw Mode.
	 */
	toDrawSignature() {
		// Toggling value
		this.typeValue = "hand";
		// Showing Clear Button.
		this.clearBtnTarget.classList.remove("hidden");
		// Hidding Cancel Button.
		this.cancelBtnTarget.classList.add("hidden");
		// Hidding Name input.
		this.nameInputTarget.classList.add("hidden");
		// Hidding Font dropdown.
		this.fontTarget.parentElement.parentElement.parentElement.classList.add("hidden");
		// Adding cursor to canvas.
		this.canvasTarget.classList.add("hover:cursor-pencil");
		// Cleaning text generated canvas.
		this.clearPad();
		// Activating bind for signature pad.
		this.pad.on();
		// Toggling description
		this.descriptionTarget.innerText = descriptions.get(this.typeValue);
		this.togglerTarget.innerText     = tooglerTexts.get(this.typeValue);
	}

	/**
	 * changes the signature pad to Text Mode.
	 * The user's name will be set as default.
	 */
	toTextSignature() {
		// Toggling value
		this.typeValue = "text";
		// Hiding Clear Button.
		this.clearBtnTarget.classList.add("hidden");
		// Showing Cancel Button.
		this.cancelBtnTarget.classList.remove("hidden");
		// Showing Name input.
		this.nameInputTarget.classList.remove("hidden");
		// Showing Font dropdown.
		this.fontTarget.parentElement.parentElement.parentElement.classList.remove("hidden");
		// Removing cursor to canvas.
		this.canvasTarget.classList.remove("hover:cursor-pencil");
		// Cleaning text generated canvas.
		this.pad.clear();
		// Unbinding all event handlers
		this.pad.off();
		// Validating submit button.
		this.validateCanvas();
		// Adding text generated canvas.
		generateCanvasText(this.canvasTarget, this.nameInputTarget.value, this.fontTarget.value);
		// Toggling description
		this.descriptionTarget.innerText = descriptions.get(this.typeValue);
		this.togglerTarget.innerText     = tooglerTexts.get(this.typeValue);
	}

	/**
	 * toggles the signature pad UI between a text signature and hand signture,
	 * while also updating the text descriptions.
	 */
	toggleSignatureType() {
		const value = this.typeValue;

		if (value === "text") {
			this.canvasTarget.width  = 480;
			this.canvasTarget.height = 120;
			isText                   = false;

			this.toDrawSignature();
		}

		if (value === "hand") {
			this.canvasTarget.width  = 720;
			this.canvasTarget.height = 180;
			isText                   = true;

			this.toTextSignature();
		}
	}

	/**
	 * draws on the canvas the name typed on the input.
	 */
	updateNameSignature() {
		if (this.typeValue !== "text") {
			return;
		}

		this.nameInputTarget.value = cleanTextInputField(this.nameInputTarget.value);

		this.validateCanvas();
		clearTimeout(this.timeout);

		this.timeout = setTimeout(() => {
			this.clearPad();
			generateCanvasText(this.canvasTarget, this.nameInputTarget.value, this.fontTarget.value);
		}, 50);
	}

	/**
	 * validates if the signature text is empty or not.
	 */
	validateCanvas = () => {
		if (this.typeValue !== "text") {
			return;
		}

		if (this.nameInputTarget.value === "") {
			this.nameInputTarget.classList.add("border-[#dc3545]", "focus:border-[#dc3545]", "focus:shadow-[0_0_0_0.2rem_rgba(220,_53,_69,_0.25)]");
			this.submitBtnTarget.disabled = true;
			return;
		}

		this.nameInputTarget.classList.remove("border-[#dc3545]", "focus:border-[#dc3545]", "focus:shadow-[0_0_0_0.2rem_rgba(220,_53,_69,_0.25)]");
		this.submitBtnTarget.disabled = false;
	};

	/**
	 * validates the name input when pressing a key.
	 */
	validateInput = evt => {
		if (!(evt.keyCode >= 48 && evt.keyCode <= 120) && (evt.keyCode != 32 && evt.keyCode != 0) && evt.keyCode != 241) {
			evt.preventDefault();
			evt.stopPropagation();
		}
	};

	/**
	 * validates if the signature pad is empty or not.
	 */
	validatePad = () => {
		if (this.typeValue !== "hand") {
			return;
		}

		if (this.pad.isEmpty()) {
			this.submitBtnTarget.disabled = true;
			return;
		}

		this.submitBtnTarget.disabled = false;
	};
}

function cleanTextInputField(inputValue) {
	return inputValue.replace(/[^a-zA-Z0-9\s]/gm, "");
}

let isText = true;

function generateCanvasText(canvas, text, font) {
	let fontSize = 88;
	let ctx      = canvas.getContext("2d");

	switch (true) {
		case text.length >= 20 && text.length < 30:
			fontSize = 68;
			break;
		case text.length >= 30 && text.length < 40:
			fontSize = 58;
			break;
		case text.length >= 40 && text.length < 60:
			fontSize = 50;
	}

	ctx.font      = `${fontSize}px ${font}`;
	ctx.textAlign = "left";
	ctx.fillText(text, 10, canvas.height / 1.5);
}

function cropSignatureCanvas(canvas, wrapper) {
	// First duplicate the canvas to not alter the original
	const croppedCanvas = document.createElement("canvas"),
	      croppedCtx    = croppedCanvas.getContext("2d", {willReadFrequently: true});

	croppedCanvas.width  = 720;
	croppedCanvas.height = 240;

	croppedCanvas.style.width  = "240px";
	croppedCanvas.style.height = "80px";

	croppedCtx.drawImage(wrapper, 0, 0, 720, 240);

	if (isText) {
		croppedCtx.drawImage(canvas, 5, 0, 720, 240);
	} else {
		croppedCtx.drawImage(canvas, 25, 40, 700, 160);
	}

	addSignedDate(croppedCtx);

	return croppedCanvas;
}

function addSignedDate(ctx) {
	const today = new Date();
	let hours   = today.getHours();
	let minutes = today.getMinutes();

	if (minutes < 10) {
		minutes = "0" + minutes;
	}

	const signedOn = `Signed on: ${today.toLocaleDateString("fr-FR")} - ${hours}:${minutes}`;

	ctx.font = "30px Montserrat";
	ctx.fillText(signedOn, 110, 230);
}
