import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    casUrl: String
  };

  connect() {
    this.getCSRFToken = () => {
      const tokenInput = document.querySelector('input[name="authenticity_token"]');
      if (tokenInput && tokenInput.value) {
        return tokenInput.value;
      }
      const metaToken = document.querySelector('meta[name="csrf-token"]');
      if (metaToken && metaToken.content) {
        return metaToken.content;
      }
      return null;
    };

    this.initializeActivityMonitoring();
    this.startSessionCheck();
  }

  disconnect() {
    if (this.sessionInterval) {
      clearInterval(this.sessionInterval);
    }
    this.removeActivityMonitoring();
  }

  startSessionCheck() {
    this.sessionInterval = setInterval(() => this.checkSession(), 60 * 60 * 1000);
  }

  resetSessionTimer() {
    clearInterval(this.sessionInterval);
    this.startSessionCheck();
  }

  async checkSession() {
    const token = this.getCSRFToken();
    try {
      const response = await fetch(this.urlValue, {
        method: "GET",
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token || '',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      if (response.status === 401) {
        window.location.href = `${this.casUrlValue}`;
      }
    } catch (err) {
      console.error("Session check failed", err);
    }
  }

  initializeActivityMonitoring() {
    document.addEventListener('click', this.handleLinkClick.bind(this));
  }

  removeActivityMonitoring() {
    document.removeEventListener('click', this.handleLinkClick.bind(this));
  }

  handleLinkClick(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      this.resetSessionTimer();
    }
  }
}
