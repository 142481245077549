import { Controller } from "@hotwired/stimulus";

const pdfjsLib                         = require("pdfjs-dist/legacy/build/pdf.js");
pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

const SIGNATURE_BLOCK_WIDTH  = 240;
const SIGNATURE_BLOCK_HEIGHT = 80;

const dropzoneContainerClasses = ["mx-auto", "shadow-md", "bg-white", "mb-6", "border", "border-solid", "border-slate-200", "relative"]
const dropzoneContainerActions = "dragover->policylocker--docs#_:prevent drop->policylocker--docs#handleDrop"

export default class extends Controller {
	static targets = [
		"file",
		"canvas",
		"preview",
		"filename",
		"signatureBlock",
		"clone",
		"dropzone",
		"positions",
		"form",
		"dragImage"
	];

	static values = {
		url: String,
		scale: Number,
		signatures: Object
	}

	connect() {
		const hasFileTarget = this.hasFileTarget;

		if (this.hasUrlValue) {
			if (hasFileTarget) {
				this.fileTarget.parentElement.classList.add("hidden");
				this.previewTarget.classList.remove("hidden");
			}

			this.fetchPDF();
		}

		this.setEventListeners();
	}

	fetchPDF() {
		this.loadPDF(this.urlValue);

		this.loadSignatures();
	}

	loadPDF(file) {
		const loadingTask = pdfjsLib.getDocument(file);

		loadingTask.promise.then((pdfDocument) => {
			this.PDF = pdfDocument;
			this.pagesCount = pdfDocument.numPages;
			this.currentPage = 1;

			this.renderPage(this.currentPage);
		})
		.catch((reason) => {
			console.error("Error: " + reason);
		});
	}

	renderPage(pageIndex) {
		if (pageIndex > this.pagesCount) {
			if (this.hasSignaturesValue) {
				this.loadSignatures();
			}

			return;
		}

		const scale = this.scaleValue || 1.5;

		this.PDF.getPage(pageIndex).then(page => {
			const viewport = page.getViewport({scale: scale});

			// Creating page box
			const box = document.createElement("div");
			box.classList.add(`w-[${viewport.width}px]`, `h-[${viewport.height / 2}px]`, ...dropzoneContainerClasses);
			box.dataset["page"] = this.currentPage;
			box.dataset["action"] = dropzoneContainerActions;
			box.setAttribute("data-policylocker--docs-target", "dropzone")

			// Prepare canvas using PDF page dimensions
			const canvas  = document.createElement("canvas");
			const context = canvas.getContext("2d");
			canvas.width = viewport.width;
			canvas.height = viewport.height;
			
			// Set the canvas style to be the same size as the viewport
			// This is done to prevent the canvas from being stretched
			// when the page is rendered. This is only done if the PDF is
			// viewed other than signing mode. (show, preview, etc.)
			if (this.hasScaleValue) {
				canvas.style.width = "100%";
				canvas.style.height = "100%";
			}

			// Render PDF page into canvas context
			const renderContext = {
				canvasContext: context,
				viewport: viewport,
			};

			const renderTask = page.render(renderContext);
			renderTask.promise.then(() => {
				box.appendChild(canvas);
				this.canvasTarget.appendChild(box);

				this.renderPage(++this.currentPage);
			});
		});
	}

	preview() {
		const drErros = this.fileTarget.dropify.errorsEvent.errors;

		if (drErros.length > 0) {
			return;
		}

		const doc    = this.fileTarget.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			this.loadPDF(e.target.result);
			this.filenameTarget.textContent = doc.name;
			this.fileTarget.parentElement.classList.add("hidden");
			this.previewTarget.classList.remove("hidden");
		};

		reader.readAsArrayBuffer(doc);
	}

	reset() {
		this.fileTarget.value       = "";
		this.canvasTarget.innerHTML = "";
		this.fileTarget["dropify"].resetPreview();
		this.filenameTarget.textContent = "";
		this.fileTarget.parentElement.classList.remove("hidden");
		this.previewTarget.classList.add("hidden");
	}

	// Set drag image to be the signature block.
	// Drag image is the image that follows the cursor when dragging.
	setDragImage(ev) {
		const block = this.dragImageTarget.cloneNode()
		block.classList.remove("hidden")

        ev.dataTransfer.setDragImage(block, SIGNATURE_BLOCK_WIDTH / 2, SIGNATURE_BLOCK_HEIGHT / 2);

		this.forFirefox(ev);
	}

	// https://bugzilla.mozilla.org/show_bug.cgi?id=505521#c80
	forFirefoxMove(ev) {
		if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
			this.move(ev);
		}
	}

	forFirefox(ev) {
		if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
			ev.dataTransfer.setData("text", "");
		}
	}

	// Prevent default dragover event to allow drop.
	_() {}

	handleDrop(ev) {
		const currPage = ev.currentTarget.dataset["page"];
		const targetPage = this.dragging?.parentElement?.dataset["page"];

		if (this.dragging && currPage == targetPage) {
			this.dragging = null;
			return
		}

		this.dragging?.remove();

		// Get the position of the cursor relative to the dropzone.
		// (SIGNATURE_BLOCK_WIDTH / 2)px and (SIGNATURE_BLOCK_HEIGHT / 2)px 
		// are the offsets of the drag image, this is needed to center the block on the cursor.
		const rect = ev.currentTarget.getBoundingClientRect();

		const clientX = ev.clientX - rect.left - SIGNATURE_BLOCK_WIDTH / 2;
		const clientY = ev.clientY - rect.top - SIGNATURE_BLOCK_HEIGHT / 2;

		const block = this.cloneTarget.cloneNode(true)

		block.classList.remove("hidden")
		block.removeAttribute("data-policylocker--docs-target")
		block.classList.add("absolute")
		block.classList.add("signature-block")

		ev.currentTarget.appendChild(block);

		block.style.top = `${clientY}px`;
		block.style.left = `${clientX}px`;
	}


	move(ev) {
		ev.preventDefault();

		const block = ev.target
		block.classList.add("hidden")

		const dropzone = ev.target.parentElement
		const rect = dropzone.getBoundingClientRect();

		const clientX = ev.clientX - rect.left - SIGNATURE_BLOCK_WIDTH / 2;
		const clientY = ev.clientY - rect.top - SIGNATURE_BLOCK_HEIGHT / 2;

		block.style.top = `${clientY}px`;
		block.style.left = `${clientX}px`;

		this.dragging = block
	}

	moveEnd(ev) {
		if (ev.dataTransfer.dropEffect === "none") {
			ev.target.remove()
		}

		this.forFirefoxMove(ev)

		ev.target.classList.remove("hidden")
	}

	getPositions() {
		if (this.dropzoneTargets.length === 0) {
			return
		}

		const rect = this.dropzoneTargets[0].getBoundingClientRect();

		const pdf = {
			resolution: {
				width: rect.width,
				height: rect.height,
			},
			positions: [],
		}

		this.dropzoneTargets.forEach(dropzone => {
			const dropzoneRect = dropzone.getBoundingClientRect();
			const signatureBlocks = dropzone.querySelectorAll(".signature-block");

			if (signatureBlocks.length === 0) {
				return
			}

			const position = {
				page: parseInt(dropzone.dataset.page),
				coords: [],
			}

			signatureBlocks.forEach(block => {
				if (!block.draggable) {
					return
				}

				const blockRect = block.getBoundingClientRect();

				position.coords.push({
					x: blockRect.x - dropzoneRect.x,
					y: blockRect.y - dropzoneRect.y,
				})
			})

			pdf.positions.push(position)
		})

		this.positionsTarget.value = JSON.stringify(pdf)
	}

	loadSignatures() {
		const signatures = this.signaturesValue

		this.dropzoneTargets.forEach(dropzone => {
			const pageSignatures = signatures.positions.find(page => {
				return page.page == dropzone.dataset.page
			})

			if (!pageSignatures) {
				return
			}

			const coords = pageSignatures.coords

			coords.forEach(signature => {
				const block = this.cloneTarget.cloneNode(true)

				block.classList.remove("hidden")
				block.removeAttribute("data-policylocker--docs-target")
				block.classList.add("absolute")
				block.classList.add("signature-block")

				dropzone.appendChild(block);

				block.style.top = `${signature.y}px`;
				block.style.left = `${signature.x}px`;
			})
		})
	}

	setEventListeners() {
		const create = document.querySelector(".create-policy")

		if (!create) {
			return
		}

		create.addEventListener("click", async (e) => {
			e.preventDefault()
			await this.getPositions()
			this.formTarget.submit()
		})
	}

	remove(e) {
		e.preventDefault()
		e.target.parentElement.remove()
	}
}
