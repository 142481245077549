import { Controller } from "@hotwired/stimulus";
import Pluralize from "pluralize";

export default class MonthTarget extends Controller {
	static targets = [
		"month", "templates", "courses", "summary", "count",
		"phishingTemplate", "phishingListTemplate",
		"courseTemplate", "courseListTemplate",
	];

	static values = {
		duedates: Object,
		month: Number,
		courses: Array,
		templates: Array,
	};

	get assetsChoice() {
		return this.coursesTarget.choices.instance;
	}

	get templateChoice() {
		return this.templatesTarget.choices.instance;
	}

	get assetsCount() {
		return this.assetsChoice.getValue().length;
	}

	get templatesCount() {
		return this.templateChoice.getValue().length;
	}

	connect() {
		this.element[this.identifier] = this;

		Promise.resolve().then(() => {
			this.saveChanges();
		});
	}

	/**
	 * discard all the changes done when customizing the month.
	 */
	cancel() {
		// Clearing Selected
		this.assetsChoice.removeActiveItems();
		this.templateChoice.removeActiveItems();

		// Adding previously selected
		this.assetsChoice.setChoiceByValue(this.coursesValue);
		this.templateChoice.setChoiceByValue(this.templatesValue);

		// Empty value
		this.coursesValue   = [];
		this.templatesValue = [];
	}

	/**
	 * saves the current month content selection.
	 */
	customize() {
		this.coursesValue   = this.assetsChoice.getValue().map(choice => choice.value);
		this.templatesValue = this.templateChoice.getValue().map(choice => choice.value);
	}

	/**
	 * saves the selection.
	 */
	saveChanges() {
		this.summaryTarget.innerHTML = "";

		const courses   = this.assetsChoice.getValue();
		const templates = this.templateChoice.getValue();

		this._appendPhishingSummary(templates);
		this._appendTrainingSummary(courses);

		this._updateCount(templates, courses);

		this.coursesValue   = [];
		this.templatesValue = [];
	}

	/**
	 * appends each template to the month content.
	 * @param {Object[]} templates - The selected templates value.
	 */
	_appendPhishingSummary(templates) {
		if (templates.length === 0) {
			return;
		}

		const template = this.phishingTemplateTarget.content.cloneNode(true);
		const list     = document.createElement("ul");
		templates.forEach(template => {
			const item                           = this.phishingListTemplateTarget.content.cloneNode(true);
			item.querySelector("span").innerText = template.label;
			item.querySelector("a").href         = `/${this.phishingTemplateTarget.dataset.slug}/templates/${template.value}/show/`;
			item.querySelector("a").target       = "_blank";

			// Appending list child
			list.appendChild(item);
		});

		// Appending to summary box
		this.summaryTarget.appendChild(template);
		this.summaryTarget.appendChild(list);
	}

	/**
	 * appends each course to the month content.
	 * @param {Object[]} courses - The selected courses value.
	 */
	_appendTrainingSummary(courses) {
		if (courses.length === 0) {
			return;
		}

		const template = this.courseTemplateTarget.content.cloneNode(true);
		const list     = document.createElement("ul");
		courses.forEach(course => {
			const item                           = this.courseListTemplateTarget.content.cloneNode(true);
			item.querySelector("span").innerText = course.label;
			item.querySelector("a").href         = `/${this.courseTemplateTarget.dataset.slug}/training/assets/${course.value}/`;
			item.querySelector("a").target       = "_blank";
			item.querySelector("select").name    = `MonthlyContent[${this.monthValue}].DueDates[${course.value}]`;
			item.querySelector("select").value   = this.duedatesValue[course.value] || "last-month";

			// Appending list child
			list.appendChild(item);
		});

		// // Appending to summary box
		this.summaryTarget.appendChild(template);
		this.summaryTarget.appendChild(list);
	}

	/**
	 * updates the count summary.
	 */
	_updateCount() {
		const plen = this.templatesCount;
		const clen = this.assetsCount;

		if (plen === 0 && clen === 0) {
			this.countTarget.innerText = "No content for this month.";

			return;
		}

		const phishingCount = `${Pluralize("Phishing Template", plen, true)}`;
		const trainingCount = `${Pluralize("Training Asset", clen, true)}`;

		if (clen === 0 && plen !== 0) {
			this.countTarget.innerText = phishingCount;

			return;
		}

		if (plen === 0 && clen !== 0) {
			this.countTarget.innerText = trainingCount;

			return;
		}

		this.countTarget.innerText = phishingCount + ", " + trainingCount;
	}
}
