import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["step", "finishButton", "doneButton"]

    initialize() {
        this.element[this.identifier] = this;

        if (this.hasFinishButtonTarget) {
            this.validate();
            const mutationObserver = new MutationObserver(() => this.validate());

            this.stepTargets.forEach(element => {
                mutationObserver.observe(element, {
                    attributes: true,
                });
            });
        }
    }

    editStep(event) {
        const stepIndex = parseInt(event.target.dataset.stepIndex) - 1;
        const stepSection = this.stepTargets[stepIndex];

        stepSection.querySelector(".step-result").classList.add("d-none")
        $(stepSection.querySelector(".step-content")).slideDown()
        stepSection.classList.remove("completed", "incomplete")
    }

    finishStep(event) {
        const stepIndex = parseInt(event.target.dataset.stepIndex) - 1;
        const stepSection = this.stepTargets[stepIndex];
        const icon = stepSection.querySelector(".icon-container").firstElementChild

        if (icon.classList.contains("fa-times-circle")) {
            icon.classList.remove("fa-times-circle")
            icon.classList.add("fa-check-circle")
        }

        stepSection.querySelector(".step-result").classList.remove("d-none")
        stepSection.classList.add("completed")
        $(stepSection.querySelector(".step-content")).slideUp()

        this._nextStep(stepIndex + 1)
    }

    finishForm(e) {
        $(e.target.dataset.modal).modal('show');
        $('#newCampaign').modal('show')
    }

    lockStep(index) {
        const step = this.stepTargets[index];
        step.querySelector('.btn-info').disabled = true;
    }

    unlockStep(index) {
        const step = this.stepTargets[index];
        step.querySelector('.btn-info').disabled = false;
    }

    validate() {
        this.completedSteps == this.stepTargets.length ? this.finishButtonTarget.disabled = false : this.finishButtonTarget.disabled = true;
    }

    _nextStep(index) {
        if (index < this.stepTargets.length) {
            const step = this.stepTargets[index];
            if (step.classList.contains("incomplete")) {
                step.classList.remove("incomplete");
                step.querySelector(".step-incomplete-message").remove();
                step.querySelector(".step-result").classList.add("d-none");
            }
        }
    }

    get completedSteps() {
        return this.stepTargets.filter(step => step.classList.contains("completed")).length
    }
}