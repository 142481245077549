import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class SmartTraining extends Controller {
	static targets = ["addButton", "required", "selectedTraining"];

	connect() {
		const choicesConfig = {
			searchChoices: false,
			searchEnabled: false,
			shouldSort: false,
			shouldSortItems: false,
		};

		this._choicesInstances = [];
		this._choicesInstances.push(new Choices(document.querySelector(".template-choices"), choicesConfig));

		this._updateSelectedTemplates();
		this._initTemplateObserver();
		this._initRowObserver();

		if (this.hasSelectedTrainingTarget) {
			const list       = document.getElementById("ConditionRows");
			list.innerHTML   = "";
			const conditions = [];

			Array.from(this.selectedTrainingTarget.children).forEach(condition => {
				conditions.push({
					"course": condition.dataset.courses,
					"event": condition.dataset.event,
					"template": condition.dataset.templateid,
					"duedate": condition.dataset.duedate,
				});
			});

			const conditionGroups = this._groupBy(conditions, item => [item.event, item.template, item.duedate]);
			conditionGroups.forEach(groups => {
				const row    = document.getElementById("SmartTrainingRow").content.cloneNode(true);
				const values = groups.map(group => Object.values(group));

				row.querySelectorAll("select").forEach((select, index) => {
					const templatesChoices = select.classList.contains("template-choices");
					const options          = Array.from(select.querySelectorAll("option"));

					values.forEach(v => {
						if (select.dataset.elementsName === "SmartCourses") {
							const ids = v[0].split(",");
							ids.forEach(id => options.find(c => c.value === id).selected = true);
						} else {
							options.find(c => c.value === v[index]).selected = true;
						}
					});

					if (templatesChoices) {
						this._choicesInstances.push(new Choices(select, choicesConfig));
						this._updateSelectedTemplates();
					}
				});

				list.append(row);
			});

			// Remove the target after everything has been done
			this.selectedTrainingTarget.remove();
			this.addButtonTargets.forEach(button => button.disabled = false);
		}
	}

	/**
	 * Adds an empty condition row
	 */
	addRow() {
		const row  = document.getElementById("SmartTrainingRow").content.cloneNode(true);
		const list = document.getElementById("ConditionRows");

		row.querySelectorAll("select").forEach(select => {
			let removeItem = false;
			if (select.multiple) removeItem = true;

			const choices = new Choices(select, {
				removeItemButton: removeItem,
				searchChoices: false,
				searchEnabled: false,
				shouldSort: false,
				shouldSortItems: false,
			});

			if (select.classList.contains("template-choices")) this._choicesInstances.push(choices);
		});

		list.append(row);
		this.addButtonTargets.forEach(button => button.disabled = true);
		this._updateSelectedTemplates();
	}

	/**
	 * Remove the selected condition row
	 */
	removeRow(evt) {
		let row     = evt.target.parentElement.parentElement;
		const index = [...row.parentElement.children].indexOf(row);

		if (index === 0) {
			row.nextElementSibling.remove();
		}

		if (row.previousElementSibling)
			row.previousElementSibling.remove();

		row.remove();
		this.checkValidity();
	}

	/**
	 * Validates if the required fields are valid
	 * before submitting or adding a new row
	 */
	checkValidity() {
		let invalid = this.requiredTargets.some(input => input.value === "");
		invalid ? this.addButtonTargets.forEach(button => button.disabled = true) : this.addButtonTargets.forEach(button => button.disabled = false);

		this.requiredTargets.forEach(input => {
			input.value === "" ? input.parentElement.classList.add("has-error") : input.parentElement.classList.remove("has-error");
		});
	}

	/**
	 * Remove all the condition rows
	 */
	skip() {
		const rows = document.getElementById("ConditionRows").children;
		while (rows.length > 0) {
			rows.item(0).remove();
		}

		this.addRow();
		this.requiredTargets.forEach(input => input.parentElement.classList.remove("has-error"));
	}

	/**
	 * Watches any changes on the template selection table
	 * and immediately set the templates available on the select input
	 */
	_initTemplateObserver() {
		const mutationObserver = new MutationObserver(() => this._updateSelectedTemplates());
		const templateBox      = document.getElementById("TemplatesSelected");
		mutationObserver.observe(templateBox, {
			attributes: false,
			childList: true,
		});
	}

	/**
	 * Watches any changes on the condition rows when adding or removing
	 */
	_initRowObserver() {
		const conditionList    = document.getElementById("ConditionRows");
		const mutationObserver = new MutationObserver(() => {
			const rows = Array.from(conditionList.children).filter(element => element.nodeName !== "HR");
			rows.length > 1 ? conditionList.classList.add("has-multiple") : conditionList.classList.remove("has-multiple");
			rows.forEach((row, index) => row.querySelectorAll("select").forEach(select => {
				select.id   = select.id.replace(/\[(.+?)]/g, `[${index}]`);
				select.name = select.name.replace(/\[(.+?)]/g, `[${index}]`);
			}));
		});

		mutationObserver.observe(conditionList, {
			attributes: false,
			childList: true,
		});
	}

	/**
	 * Extracts the templates selected on the previous table
	 * and show the templates on the choice list
	 */
	_updateSelectedTemplates() {
		const any       = "ANY_TEMPLATE";
		const selected  = document.querySelectorAll("#TemplatesSelected .template-box");
		const templates = Array.from(selected).map(template => template.nextElementSibling.value);

		this._choicesInstances.forEach(instance => {
			const anyChoices = instance._currentState.choices.find(choice => choice.value === any);
			instance._currentState.choices.forEach(choice => {
				if (choice.value !== any) choice.disabled = !templates.some(t => t === choice.value);
				if (choice.selected && choice.disabled || templates.length === 0) instance.setChoiceByValue("");
				templates.length > 0 ? anyChoices.disabled = false : anyChoices.disabled = true;
			});

			instance.unhighlightAll();
		});

		this.checkValidity();
	}

	/**
	 * Returns an array of the objects based on the group specifications
	 */
	_groupBy(array, f) {
		let groups = {};
		array.forEach(o => {
			let group     = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});

		return Object.keys(groups).map(group => groups[group]);
	}
}
