import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["clientSelect", "clientName"];

  connect() {
    this.submitButton = this.element.closest('.tw-modal-content').querySelector('.tw-btn-primary');
    this.initializeChoices();
    this.clientSelectTarget.addEventListener('change', this.updateClientName.bind(this));
    this.element.closest('form').addEventListener('submit', this.updateClientName.bind(this));
  }

  initializeChoices() {
    if (this._choicesInstance) {
      this._choicesInstance.destroy();
    }

    this._choicesInstance = new Choices(this.clientSelectTarget, {
      searchEnabled: true,
      placeholder: true,
      placeholderValue: 'Select a Compliance Scorecard company',
      shouldSort: false,
      itemSelectText: '',
    });
  }

  updateClientName(event) {
    const selectedOption = this.clientSelectTarget.options[this.clientSelectTarget.selectedIndex];
    this.clientNameTarget.value = selectedOption.textContent || '';
    
    if (selectedOption.value) {
      this.submitButton.disabled = false;
    } else {
      this.submitButton.disabled = true;
    }
  }
}


