import { Controller } from "@hotwired/stimulus";

/* Import TinyMCE */
import tinymce from "tinymce";

/* Default icons are required. After that, import custom icons if applicable */
import "tinymce/icons/default";

/* Required TinyMCE components */
import "tinymce/themes/silver";
import "tinymce/models/dom";

/* Custom Toolbar Functions */
import SignArea from "./policylocker/add_sign_area_to_editor";

const customToolBarFunctions = {
	"signarea": SignArea,
};

export default class extends Controller {
	static values = {
		height: {type: Number, default: 650},
		customToolbar: Array,
		indentation: Array,
		tools: Array,
		pasteImages: {type: Boolean, default: true},
	};

	connect() {
		initializeTinyMCEEditor(this);
	}
}

function initializeTinyMCEEditor(controller) {
	// Initializing Library
	tinymce.init({
		selector: `textarea#${controller.element.id}`,
		height: controller.heightValue,
		paste_data_images: controller.pasteImagesValue,
		paste_as_text: true,
		menubar: false,
		toolbar: [
			{
				name: "formatting", items: ["bold", "italic", "underline"],
			},
			{
				name: "alignment", items: ["alignleft", "aligncenter", "alignright", "alignjustify"],
			},
			{
				name: "indentation", items: controller.indentationValue,
			},
			{
				name: "tools", items: controller.toolsValue,
			},
			{
				name: "custom", items: ["signarea"],
			},
		],
		setup: function (editor) {
			// Adding Custom Toolbar
			controller.customToolbarValue.forEach(value => {
				const fn = customToolBarFunctions[value];
				if (fn === undefined) {
					return;
				}

				fn(editor);
			});
		},
		// Image Plugin Specific
		plugins: ["image", "lists", "pagebreak", "table", "paste"],
		relative_urls: false,
		image_uploadtab: true,
		images_upload_handler: uploadImg,
		pagebreak_split_block: true,
		pagebreak_separator: "<div class='h-0.5 -mx-5 my-14 bg-gray-200'><!-- pagebreak --></div>",
	}).then(editor => {
		// Saving instance on DOM
		controller.element[controller.identifier] = editor[0];
	});
}

function uploadImg(blobInfo, progress) {
	return new Promise(function (resolve, reject) {
		const xhr           = new XMLHttpRequest();
		xhr.withCredentials = false;
		xhr.open("POST", "/storage/images/upload/");

		xhr.upload.onprogress = (e) => {
			progress(e.loaded / e.total * 100);
		};

		xhr.onload = () => {
			const json = JSON.parse(xhr.response);

			if (!json) {
				reject("Invalid JSON: " + xhr.responseText);
				return;
			}

			if (xhr.status < 200 || xhr.status >= 300) {
				reject({message: "HTTP Error: " + json.error, remove: true});
				return;
			}

			resolve(json.path);
		};

		xhr.onerror = () => {
			reject("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
		};

		const formData = new FormData();
		const token    = document.querySelector("meta[name=csrf-token]").attributes.content.textContent;
		const param    = document.querySelector("meta[name=csrf-param]").attributes.content.textContent;

		formData.append(param, token);
		formData.append("file", blobInfo.blob(), blobInfo.filename());

		xhr.send(formData);
	});
}

function tinyMCEToolbarSetting() {

}
