/**
 * Variable that contains the essential classes for displaying the input as error.
 * @type {string[]}
 */
const ErrorClasses = ["has-error", "border-[#dc3545]", "focus:border-[#dc3545]", "focus:shadow-[0_0_0_0.2rem_rgba(220,_53,_69,_0.25)]"];

/**
 * Validates the input passed and adds the classes if the input is not valid.
 * Otherwise, it will be removed from the element.
 * @param {HTMLElement} target - the input that is being validated on event.
 */
function validateInput(input) {
	const valid = input.checkValidity();

	if (valid && input.classList.contains("has-error")) {
		input.classList.remove(...ErrorClasses);
	}

	if (!valid) {
		input.classList.add(...ErrorClasses);
	}
}

export default validateInput;
