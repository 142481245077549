import { Controller } from "@hotwired/stimulus"
import c3 from 'c3'

export default class extends Controller {
    static values = {
        data: Object,
        url: String,
    }

    connect() {
        this.element[this.identifier] = this;
        this.load();
    }

    load() {
        const trainingURL = this.urlValue;
        const element = this.element;

        let options = {
            bindto: element,
            size: {
                height: element.dataset.height,
            },
            data: {
                columns: [
                    ['empty', 1],
                ],
                order: null,
                type: 'donut',
                onclick: d => {
                    if (this.dataValue.assignments == 0 || trainingURL == "") {
                        return
                    }

                    location.href = `${trainingURL}?Status=${d.name}`
                }
            },
            donut: {
                title: "",
                width: element.dataset.width,
                expand: false,
                label: {
                    show: false
                }
            },
            legend: {
                hide: element.dataset.hideLegend === "true",
            },
            tooltip: {
                show: false,
            },
            color: {
                pattern: ["#CED9E6"]
            },
        };

        if (this.dataValue.assignments != 0) {
            options.data.columns = [
                ["Pending", this.dataValue.pending],
                ["Completed", this.dataValue.completed],
                ["Overdue", this.dataValue.overdue],
            ];

            options.color.pattern = ["#0A85EC", "#56CE63", "#FCBA4D"];
        }

        this.chart = c3.generate(options);
        this.element.querySelector(".c3-chart-arcs-title").innerHTML = `
        <tspan class="donut-title donut-title-small" dy="-13" x="5">${this.dataValue.assignments}</tspan>
        <tspan class="donut-title donut-title-xs font-light" dy="25" x="0">Assignments</tspan>
        `
    }
}
