import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        source: String,
        fallback: String,
    };

    initialize() {
        const originalImg = this.sourceValue;
        const fallbackImg = this.fallbackValue;

        this.element.src = originalImg;
        this.element.addEventListener("error", () => {
            this.element.src = fallbackImg;
        });

        this.element.addEventListener("load", () => {
            this.element.classList.remove("animate-pulse");
        });

        // Removing data values from element after initialize.
        this.clean();
    }

    clean() {
        delete this.element.dataset.controller;
        for (const key in this.element.dataset) {
            const camelizedIdentifier = this.identifier.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
            if (key.startsWith(camelizedIdentifier)) {
                delete this.element.dataset[key];
            }
        }
    }
}
